import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Tooltip} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import EventIcon from '@material-ui/icons/Event';
import DisplaySpeakers from '../DetailedTimeslot/DisplaySpeakers';
import {isMobile} from 'react-device-detect';
import LiveLabel from '../../ProgramComponents/LiveLabel';
import {ReactComponent as Discard} from '../Agenda/helpers/svgs/discard.svg';
import {ReactComponent as AddToMyAgenda} from '../Agenda/helpers/svgs/add-to-my-agenda.svg';
import {ReactComponent as PlayIcon} from '../Agenda/helpers/svgs/session-icon.svg';

const TimeslotAccordionMobile = ({
    timeslot,
    handleSetActiveTimeslot,
    stopPropagation,
    createExternalCalendarData,
    speakersArray,
    expand,
    toggleAccordion,
    refTimeslot,
    addDeleteAgenda,
    isElementAddedToTimeslot,
    activeTimeslot,
}) => {
    const eventSlug = useSelector((state) => state?.event?.data?.slug);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const displayExpandIcon = () => {
        return speakersArray.length > 0 || timeslot.description;
    };

    return (
        <Accordion
            elevation={0}
            onClick={handleSetActiveTimeslot(timeslot)}
            id={timeslot._id}
            expanded={expand}
            ref={refTimeslot}
        >
            <AccordionSummary className={'accordion-summary accordion-summary-mobile'}>
                <div className={'timeslot-time-sm timeslot-time-mobile'}>
                    <div className={'time-container'}>
                        <div
                            className={`live-label-container-mobile ${
                                !displayExpandIcon() ? 'live-label-margin-helper' : ''
                            }`}
                        >
                            <p className={`timearea ${isMobile && 'mobile-support'}`}>
                                <PlayIcon className={'play-icon'} />
                                {timeslot.start} - {timeslot.end}
                            </p>
                            {timeslot.isLive && <LiveLabel />}
                        </div>
                        <div
                            className={`live-label-container-mobile ${
                                !displayExpandIcon() && 'live-label-margin-helper'
                            }`}
                        >
                            {displayExpandIcon() && (
                                <p className={'svg-container-mobile'} onClick={toggleAccordion}>
                                    {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </p>
                            )}
                        </div>
                    </div>

                    <p className={'auditorium-title auditorium-title-mobile'}>
                        <a
                            href={`/event/${eventSlug}/auditorium/${timeslot.auditoriumIndex}`}
                            onClick={stopPropagation}
                        >
                            {timeslot.auditoriumName}
                        </a>
                    </p>
                    <div className={'timeslot-title-container'}>
                        <h5 className={'timeslot-title-sm timeslot-title-mobile'}>{timeslot.title}</h5>
                        {!expand ? (
                            <Tooltip
                                arrow
                                title={`${
                                    isElementAddedToTimeslot(timeslot)
                                        ? translation?.agendaTranslations?.addedToYourAgenda ||
                                          defaultTranslation.agendaTranslations?.addedToYourAgenda
                                        : translation?.agendaTranslations?.addToMyAgenda ||
                                          defaultTranslation.agendaTranslations?.addToMyAgenda
                                }`}
                                placement="bottom-end"
                            >
                                <button className="add-to-my-agenda-btn" onClick={addDeleteAgenda(timeslot)}>
                                    {isElementAddedToTimeslot(timeslot) ? (
                                        <Discard className={'discard'} />
                                    ) : (
                                        <AddToMyAgenda />
                                    )}
                                </button>
                            </Tooltip>
                        ) : null}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={`accordion-details`}>
                <div className="scroll-left-container d-flex">
                    <div className={'column-mobile'}>
                        <p className="timeslot-description" dangerouslySetInnerHTML={{__html: timeslot.description}} />
                        <div className="add-to-calendar-button-container add-to-calendar-button-container-sm">
                            <Button
                                variant="outlined"
                                size={'small'}
                                color="primary"
                                onClick={createExternalCalendarData}
                                startIcon={<EventIcon />}
                            >
                                {translation?.programDropdown.addSlotToCalendar ||
                                    defaultTranslation?.programDropdown.addSlotToCalendar}
                            </Button>
                        </div>
                        <DisplaySpeakers
                            speakersArray={speakersArray}
                            translation={translation}
                            defaultTranslation={defaultTranslation}
                        />
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default TimeslotAccordionMobile;
