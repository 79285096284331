import {
    CLOSE_VIDEO_CONFERENCE,
    VIDEO_CONFERENCE_READY,
    VIDEO_CONFERENCE_LOADING,
    VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED,
    VIDEO_CONFERENCE_CHANGE_PLAYBACKS,
    VIDEO_CONFERENCE_START_CALLING,
    VIDEO_CONFERENCE_STOP_CALLING,
} from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    isActive: false,
    isLoading: false,
    isCalling: false,
    isScreenShared: false,
    capture: null,
    selfPlayback: null,
    playbacks: [],
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case VIDEO_CONFERENCE_CHANGE_PLAYBACKS:
                Object.entries(action.payload).forEach(([k, v]) => {
                    draft[k] = v;
                });
                break;

            case VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED:
                Object.entries(action.payload).forEach(([k, v]) => {
                    draft[k] = v;
                });
                break;

            case VIDEO_CONFERENCE_LOADING:
                draft.isLoading = true;
                draft.isActive = true;
                break;

            case VIDEO_CONFERENCE_READY:
                Object.entries(action.payload).forEach(([k, v]) => {
                    draft[k] = v;
                });
                draft.isActive = true;
                draft.isLoading = false;
                break;

            case VIDEO_CONFERENCE_START_CALLING:
                draft.isCalling = true;
                break;

            case VIDEO_CONFERENCE_STOP_CALLING:
                draft.isCalling = false;
                break;

            case CLOSE_VIDEO_CONFERENCE:
                return initialState;

            default:
                break;
        }
    });

export default reducer;
