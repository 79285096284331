import axios from '../axios-instance';
import * as actionTypes from './actionTypes';

export const getUserEventProfile = (eventId, userId) => {
    return (dispatch) => {
        dispatch(getUserEventProfileStart());
        return axios({method: 'get', url: `event/${eventId}/profile/${userId}`})
            .then((response) => {
                let data = response.data.data;
                // from the backend we can receive either a participantProfile or an exhibitorProfile
                const userEventProfile = data.participantProfile || data.exhibitorProfile;
                dispatch(getUserEventProfileSuccess(userEventProfile));
            })
            .catch((error) => {
                let errorMessage = '';
                if (error && error.response && error.response.data) {
                    errorMessage = error.response.data.error;
                }
                dispatch(getUserEventProfileFail(errorMessage));
            });
    };
};

export const getUserEventProfileStart = () => {
    return {
        type: actionTypes.GET_USER_EVENT_PROFILE_START,
    };
};

export const getUserEventProfileSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_EVENT_PROFILE_SUCCESS,
        payload: data,
    };
};

export const getUserEventProfileFail = (err) => {
    return {
        type: actionTypes.GET_USER_EVENT_PROFILE_FAIL,
        payload: err,
    };
};

export const triggerReloadUserProfile = () => {
    return {
        type: actionTypes.TRIGGER_RELOAD_USER_PROFILE,
    };
};