import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import LinksModule from './components/LinksModule/LinksModule';

import './ZoomSessionDashboard.scss';
import VideoContainer from '../../Components/VideoStream/VideoContainer/VideoContainer';
import {useDispatch, useSelector} from 'react-redux';
import {getSessionData, setEventProtectedMenu} from '../../store/actions';
import ZoomStreamControls from './components/ZoomStreamControls/ZoomStreamControls';
import ThumbnailImage from './components/ThumbnailImage/ThumbnailImage';
import DownloadRecords from './components/DownloadRecords/DownloadRecords';
import NotFound from '../NotFound';

function ZoomSessionDashboard() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const session = useSelector((state) => state.sessions.zoomSessionDashboard.session);
    const eventData = useSelector((state) => state.event.data);
    const routeParams = useParams();
    const {sessionId} = routeParams;

    const hasSessionsEnabled = eventData?.sessionsStreaming;

    useEffect(() => {}, []);

    useEffect(() => {
        dispatch(
            getSessionData({
                sessionId,
                callback: () => {},
            })
        );
        dispatch(setEventProtectedMenu());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    const sessionHasRecords = session?.records.length > 0;

    // return not found page if the current event
    // doesn't have access to sessions functionality from master admin dashboard
    if (!hasSessionsEnabled) {
        return <NotFound />;
    }

    return (
        <div className="zoom_session_dashboard_page">
            <div className="zoom_session_dashboard_page-container preventConfirmSaveChangesPopup">
                <div className="zoom_session_dashboard_page-column">
                    {!!session && (
                        <>
                            <div className="videoWrapper">
                                <p className={'session-name module-header'}>{session.name}</p>
                                {sessionHasRecords && !session.isActive ? (
                                    // <RecordedVideoPlayer session={session} />
                                    <DownloadRecords />
                                ) : (
                                    <VideoContainer session={session} className={'video-container'} />
                                )}
                            </div>
                            <ZoomStreamControls user={user._id} />
                        </>
                    )}
                </div>
                <div className="zoom_session_dashboard_page-column">
                    <LinksModule routeParams={routeParams} />
                    {!!session && <ThumbnailImage />}
                </div>
            </div>
        </div>
    );
}

export default ZoomSessionDashboard;
