import React from 'react';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as ChatsIcon} from '../../../Images/svg/chat.svg';
import {preventDefaultDrag} from '../../../Utils/utils';

class NoChatsOverlay extends React.Component {
    render() {
        const {translation} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="no-chats-info-container">
                <div onDragStart={preventDefaultDrag} className="page-title">
                    {/* <p>{translation?.chatsDropdown.newChatStart}</p> */}
                </div>
                <div>
                    <p>{translation?.chatsDropdown.noChats}</p>
                    <ChatsIcon fill={colors.primary} />
                </div>
            </div>
        );
    }
}

export default NoChatsOverlay;
