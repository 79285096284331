import React, {Component} from 'react';
import {UUID} from '../../Utils/utils';

export default class OpenChat extends Component {
    render() {
        const idOne = `${UUID()}0_d`;
        const idTwo = `${UUID()}1_d`;
        return (
            <svg width="80" height="44" viewBox="0 0 80 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter={`url(#${idOne})`}>
                    <path
                        d="M47.1213 33.5C42.9572 29.5 36.5375 31.1666 34.1084 32.5L37.2315 13.5L47.1213 33.5Z"
                        fill={this.props.primary}
                    />
                    <path
                        d="M39.313 21C39.313 25.7787 37.132 30.0682 33.674 33C30.7378 35.4894 26.8808 37 22.6565 37C13.4574 37 6 29.8365 6 21C6 12.1634 13.4574 5 22.6565 5C29.0342 5 34.5748 8.4432 37.3734 13.5C38.6115 15.737 39.313 18.2898 39.313 21Z"
                        fill={this.props.primary}
                    />
                </g>
                <g filter={`url(#${idTwo})`}>
                    <path
                        d="M32.8787 8.49996C37.0428 12.5 43.4625 10.8334 45.8916 9.50003L42.7685 28.5L32.8787 8.49996Z"
                        fill={this.props.secondary}
                    />
                    <path
                        d="M40.687 21C40.687 16.2213 42.868 11.9318 46.326 9.00004C49.2622 6.51059 53.1192 5.00005 57.3435 5.00005C66.5426 5.00004 74 12.1635 74 21C74 29.8366 66.5426 37 57.3435 37C50.9658 37 45.4252 33.5568 42.6266 28.5C41.3885 26.263 40.687 23.7102 40.687 21Z"
                        fill={this.props.secondary}
                    />
                </g>
                <defs>
                    <filter
                        id={idOne}
                        x="0"
                        y="0"
                        width="54.6823"
                        height="44"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                    <filter
                        id={idTwo}
                        x="25.3174"
                        y="0"
                        width="54.6823"
                        height="44"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="1" />
                        <feGaussianBlur stdDeviation="3" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>
            </svg>
        );
    }
}
