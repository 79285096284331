import {useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

import {sessionUserCreated} from '../../../Api/socketApi';
import {PARTICIPANT_TYPES} from '../../../Utils/constants/organizer/sessions';

export const useNotificationOnSpeakerConnect = (isModerType) => {
    // commented out by GDM because we have an error
    // TODO: fix ring error
    // const {play} = useRing();

    useEffect(() => {
        sessionUserCreated(({session}) => {
            const {role, name} = [...session.users][session.users.length - 1];
            const isSpeaker = role === PARTICIPANT_TYPES.speaker;

            if (isSpeaker && isModerType) {
                NotificationManager.info(`Speaker ${name} connected`);
                // play();
            }
        });
    }, [
        isModerType,
        // play
    ]);
};
