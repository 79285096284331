import React, {Component} from 'react';

export default class RejectRequest extends Component {
    render() {
        return (
            <svg
                draggable="false"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="12"
                    cy="12"
                    r="11.65"
                    fill={this.props.fillErrorLight}
                    stroke={this.props.strokeErrorLight}
                    strokeWidth="0.7"
                />
                <path
                    d="M16.2745 9.18668C16.254 9.18668 6.0577 9.18668 6.0577 9.18668L8.20592 6.66181C8.34522 6.49806 8.33157 6.24692 8.17542 6.10084C8.01933 5.95479 7.77977 5.96905 7.64046 6.13282L5.22107 8.97636C4.92637 9.32283 4.9264 9.84522 5.2211 10.1916L7.64049 13.0352C7.71537 13.1232 7.81911 13.168 7.92332 13.168C8.01309 13.168 8.10317 13.1347 8.17545 13.0671C8.3316 12.9211 8.34522 12.6699 8.20595 12.5062L6.05773 9.98128C6.05773 9.98128 16.254 9.98128 16.2745 9.98128C17.911 9.98128 19.2423 11.3774 19.2423 13.0933C19.2423 14.8093 17.9109 16.2054 16.2745 16.2054H14.4704C14.2611 16.2054 14.0915 16.3833 14.0915 16.6027C14.0915 16.8221 14.2611 17 14.4704 17H16.2745C18.3288 17 20.0001 15.2475 20.0001 13.0933C20 10.9392 18.3288 9.18668 16.2745 9.18668Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.51697 5.73574C8.15055 5.39293 7.58215 5.42972 7.25962 5.80884L8.51697 5.73574ZM7.25962 5.80884C7.25964 5.80883 7.25961 5.80886 7.25962 5.80884V5.80884ZM7.25962 5.80884L4.84023 8.65239C4.38669 9.18561 4.38665 9.98247 4.84025 10.5156L7.25964 13.3592C7.25964 13.3592 7.25964 13.3592 7.25964 13.3592C7.4306 13.5601 7.67415 13.668 7.9233 13.668C8.13813 13.668 8.35072 13.5877 8.51685 13.4325C8.86975 13.1023 8.89743 12.5475 8.58678 12.1823L7.13959 10.4813H16.2738C17.612 10.4813 18.7422 11.6309 18.7422 13.0934C18.7422 14.5558 17.6127 15.7054 16.2745 15.7054H14.4704C13.9628 15.7054 13.5915 16.1298 13.5915 16.6027C13.5915 17.0756 13.9628 17.5 14.4704 17.5H16.2745C18.627 17.5 20.5 15.501 20.5 13.0933C20.5 10.6857 18.627 8.68671 16.2744 8.68671H7.13957L8.58671 6.98584C8.89746 6.62058 8.8698 6.06583 8.51697 5.73574M8.02125 6.45686C8.02126 6.45684 8.02123 6.45688 8.02125 6.45686V6.45686ZM8.02128 12.7112C8.02124 12.7112 8.02131 12.7112 8.02128 12.7112V12.7112ZM6.05771 9.65116C6.05771 9.72474 6.0577 9.83018 6.0577 9.98131V9.62483C6.05771 9.63527 6.05771 9.64395 6.05771 9.65116ZM6.05767 9.18671C6.05767 9.31502 6.05768 9.41041 6.05768 9.48131L6.05767 9.18671Z"
                    fill="white"
                />
            </svg>
        );
    }
}
