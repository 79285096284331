import {createSelector} from 'reselect';

const sessionDashboard = (state) => state.sessions.sessionDashboard;

export const getVideoConference = createSelector(sessionDashboard, (state) => state.videoConference);

export const getUserProfile = createSelector(sessionDashboard, (state) => state.profile);

export const getUsers = createSelector(sessionDashboard, (state) => state.session.users);

export const getUpdatedUser = createSelector(sessionDashboard, (state) => state.updatedUser);

export const getSessionData = createSelector(sessionDashboard, (state) => state.session);

export const getFullScreen = createSelector(sessionDashboard, (state) => state.videoConference.isFullScreen);

export const getUserProfileStatus = createSelector(sessionDashboard, (state) => state.userStatus);
