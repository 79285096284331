/* eslint-disable import/no-anonymous-default-export */
import uniqBy from 'lodash/uniqBy';
import * as actionTypes from '../../../actions/actionTypes';
import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';

export default (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.sessionDashboard;
    }

    switch (type) {
        case actionTypes.GET_PROFILE_DATA_START:
        case actionTypes.REGISTER_SESSION_MEMBER_START:
            return updateObject(state.sessionDashboard, {
                isLoading: true,
            });
        case actionTypes.GET_PROFILE_DATA_SUCCESS:
        case actionTypes.REGISTER_SESSION_MEMBER_SUCCESS:
            return updateObject(state.sessionDashboard, {
                isLoading: false,
                profile: payload,
                userStatus: payload.status,
            });
        case actionTypes.GET_PROFILE_DATA_FAIL:
        case actionTypes.REGISTER_SESSION_MEMBER_FAIL:
            return updateObject(state.sessionDashboard, {
                isLoading: false,
            });
        case actionTypes.GET_SESSION_DATA_SUCCESS: {
            const {session, userId} = payload;
            const getSession = session || payload;

            // ToDo: changed how the ETX implemented the getSession
            // if (getSession.type && getSession.type !== 'live') return state.sessionDashboard;
            if (getSession.type && getSession.type === 'zoom') return state.sessionDashboard;

            return updateObject(state.sessionDashboard, {
                session: getSession,
                updatedUser: userId,
            });
        }
        case actionTypes.SESSION_STREAM_START_SUCCESS:
        case actionTypes.SESSION_STREAM_STOP_SUCCESS:
        case actionTypes.SESSION_STREAM_START_RECORD_SUCCESS:
        case actionTypes.SESSION_STREAM_STOP_RECORD_SUCCESS:
        case actionTypes.CHANGE_SESSION_PERMISSION_SUCCESS: {
            return updateObject(state.sessionDashboard, {
                session: {
                    ...state.sessionDashboard.session,
                    ...payload,
                },
            });
        }
        case actionTypes.SEND_CHAT_MESSAGE_SUCCESS: {
            return updateObject(state.sessionDashboard, {
                session: {...state.sessionDashboard.session, chat: payload},
            });
        }
        case actionTypes.RECIEVE_CHAT_MESSAGE: {
            return updateObject(state.sessionDashboard, {
                session: {
                    ...state.sessionDashboard.session,
                    chat: updateObject(state.sessionDashboard.session.chat, {
                        messages: uniqBy([...state.sessionDashboard.session.chat.messages, payload], '_id'),
                    }),
                },
            });
        }
        case actionTypes.VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED:
            return updateObject(state.sessionDashboard, {
                videoConference: {...state.sessionDashboard.videoConference, ...payload},
            });
        case actionTypes.VIDEO_CONFERENCE_LOADING:
            return updateObject(state.sessionDashboard, {
                videoConference: {...state.sessionDashboard.videoConference, isActive: true, isLoading: true},
            });
        case actionTypes.VIDEO_CONFERENCE_READY:
            return updateObject(state.sessionDashboard, {
                videoConference: {
                    ...state.sessionDashboard.videoConference,
                    ...payload,
                    isActive: true,
                    isLoading: false,
                },
            });
        case actionTypes.VIDEO_CONFERENCE_CHANGE_PLAYBACKS:
            return updateObject(state.sessionDashboard, {
                videoConference: {
                    ...state.sessionDashboard.videoConference,
                    ...payload,
                    isActive: true,
                    isLoading: false,
                },
            });
        case actionTypes.CLOSE_VIDEO_CONFERENCE: {
            return initialState.sessionDashboard;
        }
        case actionTypes.VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED: {
            return updateObject(state.sessionDashboard, {
                videoConference: {
                    ...state.sessionDashboard.videoConference,
                    isFullScreen: !state.sessionDashboard.videoConference.isFullScreen,
                },
            });
        }
        default:
            return state.sessionDashboard;
    }
};
