import * as actionTypes from './actionTypes';
import axios from '../axios-instance';

// Not used, probably we will use it sometime in the future to get additional building details
export const getBuilding = (eventId, buildingId) => {
    return (dispatch) => {
        dispatch(getBuildingStart());
        axios({method: 'get', url: `/event/${eventId}/building/${buildingId}`})
            .then((response) => {
                let building = response.data.building;
                dispatch(getBuildingSuccess(building));
            })
            .catch((error) => {
                dispatch(getBuildingFail());
            });
    };
};

export const getBuildingStart = () => {
    return {
        type: actionTypes.GET_BUILDING_START,
    };
};
export const getBuildingSuccess = (building) => {
    return {
        type: actionTypes.GET_BUILDING_SUCCESS,
        payload: building,
    };
};
export const getBuildingFail = () => {
    return {
        type: actionTypes.GET_BUILDING_FAIL,
    };
};

export const setBuildingId = (buildingId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_BUILDING_ID,
            payload: buildingId,
        });
    };
};
