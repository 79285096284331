import React, {Fragment, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import compact from 'lodash/compact';
import {preventDefaultDrag} from '../../../../Utils/utils';

const useStyles = makeStyles({
    avatar: {
        border: '2px solid #fff',
        borderRadius: '50%',
        maxWidth: 100,
        maxHeight: 100,
        minWidth: 30,
        minHeight: 30,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#fff',
        overflow: 'hidden',
    },
    minified: {
        border: 0,
        borderRadius: 0,
    },
    image: {
        width: '100%',
        height: '100%',
    },
    hidden: {
        display: 'none',
    },
});

const VideoAvatar = ({member, isMinified}) => {
    const classes = useStyles();
    const [imgLoaded, setImgLoaded] = useState(false);

    const getAvatarSrc = () => {
        if (member.avatarSmall && member.avatarSmall !== 'default_avatar.png')
            return process.env.REACT_APP_AVATAR_FOLDER + member.avatarSmall;
        return member.avatarUrl;
    };

    return (
        <div
            onDragStart={preventDefaultDrag}
            className={compact([classes.avatar, isMinified ? classes.minified : null]).join(' ')}
        >
            {!!member && (
                <Fragment>
                    {!imgLoaded &&
                        `${member.first && !!member.first.length ? member.first[0] : ''}${
                            member.last && !!member.last.length ? member.last[0] : ''
                        }`.toUpperCase()}

                    <img
                        alt=""
                        className={compact([classes.image, imgLoaded ? null : classes.hidden]).join(' ')}
                        onLoad={() => setImgLoaded(true)}
                        src={getAvatarSrc()}
                    />
                </Fragment>
            )}
        </div>
    );
};

export default VideoAvatar;
