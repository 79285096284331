import React from 'react';

const AuditoriumBackgroundImage = (props) => {
    let EventAuditoriumImage;
    let heightResolution = window.screen.height * window.devicePixelRatio;
    const {brandingData} = props;

    if (brandingData.auditoriumRenderType === 'custom') {
        // Custom uploaded
        EventAuditoriumImage = brandingData.filesUrl;
        switch (true) {
            case heightResolution < 769:
                EventAuditoriumImage += brandingData.auditoriumRender.resize768;
                break;
            case heightResolution < 901:
                EventAuditoriumImage += brandingData.auditoriumRender.resize900;
                break;
            case heightResolution < 1081:
                EventAuditoriumImage += brandingData.auditoriumRender.resize1080;
                break;
            case heightResolution < 1201:
                EventAuditoriumImage += brandingData.auditoriumRender.resize1200;
                break;
            case heightResolution < 1441:
                EventAuditoriumImage += brandingData.auditoriumRender.resize1440;
                break;
            case heightResolution < 2161:
                EventAuditoriumImage += brandingData.auditoriumRender.resize2160;
                break;
            default:
                EventAuditoriumImage += brandingData.auditoriumRender.original;
        }
    } else if (brandingData.auditoriumRenderType === 'v2') {
        // Academic
        switch (true) {
            case heightResolution < 769:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/academic_auditorium_768.jpg`;
                break;
            case heightResolution < 901:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/academic_auditorium_1080.jpg`;
                break;
            case heightResolution < 1081:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/academic_auditorium_1080.jpg`;
                break;
            case heightResolution < 1201:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/academic_auditorium_1200.jpg`;
                break;
            case heightResolution < 1441:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/academic_auditorium_1440.jpg`;
                break;
            default:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/academic_auditorium.jpg`;
        }
    } else if (brandingData.auditoriumRenderType === 'v3') {
        // Green
        switch (true) {
            case heightResolution < 769:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/green_auditorium_768.jpg`;
                break;
            case heightResolution < 901:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/green_auditorium_1080.jpg`;
                break;
            case heightResolution < 1081:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/green_auditorium_1080.jpg`;
                break;
            case heightResolution < 1201:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/green_auditorium_1200.jpg`;
                break;
            case heightResolution < 1441:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/green_auditorium_1440.jpg`;
                break;
            default:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/green_auditorium.jpg`;
        }
    } else {
        // Default
        switch (true) {
            case heightResolution < 769:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/auditorium_768.jpg`;
                break;
            case heightResolution < 901:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/auditorium_900.jpg`;
                break;
            case heightResolution < 1081:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/auditorium_1080.jpg`;
                break;
            case heightResolution < 1201:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/auditorium_1200.jpg`;
                break;
            case heightResolution < 1441:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/auditorium_1440.jpg`;
                break;
            default:
                EventAuditoriumImage = `${process.env.REACT_APP_RENDERS_FOLDER}auditorium/auditorium.jpg`;
        }
    }

    return (
        <img
            draggable="false"
            className="background-event"
            src={EventAuditoriumImage}
            width="6000"
            height="3000"
            alt="Auditorium"
        />
    );
};

export default AuditoriumBackgroundImage;
