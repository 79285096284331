import React from 'react';

import VideoStreamButtonsMute from './components/VideoStreamButtonsMute/VideoStreamButtonsMute';
import VideoStreamButtonsVideo from './components/VideoStreamButtonsVideo/VideoStreamButtonsVideo';
import VideoStreamButtonsShare from './components/VideoStreamButtonsShare/VideoStreamButtonsShare';

import './VideoStreamButtons.scss';

const VideoStreamButtons = ({capture, setCapture, streamName, user, session}) => {
    return (
        <div className={'video-stream-buttons'}>
            <VideoStreamButtonsMute session={session} capture={capture} user={user} />
            <VideoStreamButtonsVideo session={session} capture={capture} user={user} />
            <VideoStreamButtonsShare
                session={session}
                capture={capture}
                setCapture={setCapture}
                streamName={streamName}
                user={user}
            />
        </div>
    );
};

export default VideoStreamButtons;
