import {useEffect, useState} from 'react';

import {ReactComponent as FacebookActive} from '../Helpers/Icons/svg/facebook.svg';
import {ReactComponent as TwitterActive} from '../Helpers/Icons/svg/tw.svg';
import {ReactComponent as LinkedinActive} from '../Helpers/Icons/svg/linkedin.svg';
import {ReactComponent as WorldActive} from '../Helpers/Icons/svg/link.svg';
import {ReactComponent as CloseIconUp} from '../Helpers/Icons/svg/close-icon-up.svg';
import {ReactComponent as BackActive} from '../Helpers/Icons/svg/arrow_image.svg';
import {ReactComponent as DefaultUserMobile} from '../Helpers/Icons/svg/default_user.svg';
const ActiveSpeakerInfo = ({user, closeAllDialogs, status, eventId, visited, keyDownCloseAllDialogs}) => {
    //Auto height for the absolute div.
    const [height, setHeight] = useState('');

    const getHeight = (_) => {
        const height = document.getElementById('autoHeight').clientHeight;
        return setHeight(height + 40);
    };

    //--------end

    const socialKeyDown = (link) => (e) => {
        if (e.key === 'Enter') {
            window.open(link, '_blank');
        }
    };

    const {innerWidth} = window;
    useEffect(() => {
        innerWidth > 1024 && getHeight();
    }, [height, innerWidth]);

    return (
        <>
            <div className="person-long" id={`${status ? 'autoHeight' : ''}`}>
                <div className="go-back-bar" onClick={closeAllDialogs(user._id)}>
                    <button className={`mobile-close-button`}>
                        <BackActive className="mobile-go-back-arrow" />
                        <h4>BACK</h4>
                    </button>
                </div>
                <div className="person-introduce">
                    {user && user.image ? (
                        <div
                            className={`isMobile person-img ${status ? 'active-person-img' : ''}`}
                            style={{background: `url(${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${user.image})`}}
                        ></div>
                    ) : (
                        <DefaultUserMobile
                            className="default-user active-person-svg mobile-prop-speaker-pic"
                            tabIndex="1"
                        />
                    )}

                    <h2>{user.name}</h2>
                    <h3>{user.title}</h3>
                    <ul className="social-icon-container active-speaker-social">
                        {user.linkedIn && (
                            <li>
                                <a
                                    href={user.linkedIn}
                                    target="_blank"
                                    rel="noreferrer"
                                    onKeyDown={socialKeyDown(user.linkedIn)}
                                >
                                    <LinkedinActive className="linkedin" tabIndex="1" />
                                </a>
                            </li>
                        )}
                        {user.twitter && (
                            <li>
                                <a
                                    href={user.twitter}
                                    target="_blank"
                                    onKeyDown={socialKeyDown(user.twitter)}
                                    rel="noreferrer"
                                >
                                    <TwitterActive className="twitter" tabIndex="1" />
                                </a>
                            </li>
                        )}
                        {user.facebook && (
                            <li>
                                <a
                                    href={user.facebook}
                                    target="_blank"
                                    rel="noreferrer"
                                    onKeyDown={socialKeyDown(user.facebook)}
                                >
                                    <FacebookActive className="facebook" tabIndex="1" />
                                </a>
                            </li>
                        )}
                        {user.webpage && (
                            <li>
                                <a
                                    href={user.webpage}
                                    target="_blank"
                                    rel="noreferrer"
                                    onKeyDown={socialKeyDown(user.webpage)}
                                >
                                    <WorldActive className="webpage" tabIndex="1" />
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <div
                    className="description-text speaker-add-prop"
                    dangerouslySetInnerHTML={{
                        __html: user.description,
                    }}
                />

                <div
                    className={`mobile-hide-close close-dialog-speaker`}
                    onClick={closeAllDialogs(user._id)}
                    onKeyDown={keyDownCloseAllDialogs(user._id)}
                    tabIndex="1"
                >
                    <CloseIconUp className="close-dialog-up" tabIndex="1" />
                    <span className="close-dialog-text">HIDE</span>
                </div>
            </div>
            <div style={{height: height, width: '100%'}}></div>
        </>
    );
};

export default ActiveSpeakerInfo;
