import * as actionTypes from '../actions/actionTypes';
import produce from "immer";
// import {GET_WELCOME_SCREEN_SUCCESS, WELCOME_SCREEN_ERROR} from "../actions/actionTypes";

const initialState = {
    loading: false,
    data: null,
    error: null
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.WELCOME_SCREEN_API_CALL_START:
                draft.loading = true;
                break;
            case actionTypes.WELCOME_SCREEN_ERROR:
                draft.loading = false;
                draft.error = action.payload;
                break;
            case actionTypes.GET_WELCOME_SCREEN_SUCCESS:
                draft.loading = false;
                draft.data = action.payload;
                break;

            default:
                break;
        }
    });


export default reducer;