import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Spinner from '../../SmallLayoutComponents/Spinner';
import * as actions from '../../store/actions/index';
import {preventDefaultDrag} from '../../Utils/utils';
import './EditUserInformationStyles.scss';

const EditUserInformation = ({opened, closeDialog}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
    });
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Synchronize state with user props
        const updateUserState = () => {
            setUserData({
                firstName: user.first,
                lastName: user.last,
            });
        };

        // Custom name validation logic
        const isValidName = (value) => /^[a-zA-ZæøåÆØÅ][a-zA-Z0-9æøåÆØÅ.' -]*$/.test(value);

        // Add custom validation rule for name field
        const addNameValidationRule = () => {
            ValidatorForm.addValidationRule('isValidName', (value) => {
                const valid = isValidName(value);
                setDisabled(!valid);
                return valid;
            });
        };

        // Execute the above-defined functions
        updateUserState();
        addNameValidationRule();

        // Cleanup
        return () => {
            ValidatorForm.removeValidationRule('isValidName');
        };
    }, [user]);

    const handleChange = (e) => {
        const newUser = {
            ...userData,
            [e.target.name]: e.target.value,
        };
        setUserData(newUser);
        setDisabled(!(newUser.firstName && newUser.lastName));
    };

    const handleUpdateUserInformation = () => {
        setLoading(true);
        dispatch(actions.updateUserInformation({first: userData.firstName, last: userData.lastName}))
            .then(() => {
                dispatch(actions.refreshUserData());
                closeDialog();
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog
            className="edit-user-information"
            open={opened}
            onClose={() => closeDialog('')}
            aria-labelledby="form-dialog-title"
        >
            {loading && <Spinner />}
            <DialogContent>
                <DialogTitle id="form-dialog-title">{translation?.myAccountPage.editUserDialogTitle}</DialogTitle>
                <ValidatorForm className={'user-edit-container-form'} onSubmit={handleUpdateUserInformation}>
                    <div onDragStart={preventDefaultDrag} className="validator-input">
                        <TextValidator
                            label={translation?.myAccountPage.firstName}
                            onChange={handleChange}
                            name="firstName"
                            value={userData.firstName}
                            variant="outlined"
                            fullWidth
                            required
                            validators={['required', 'minStringLength: 1', 'maxStringLength: 50', 'isValidName']}
                            errorMessages={[
                                `${translation?.errors.required}`,
                                `${translation?.errors.required}`,
                                `${translation?.errors?.genericTooLongErrorFirst} 50 ${translation?.errors.genericTooLongErrorSecond}`,
                                `${
                                    translation.newLoginRegistrations.invalidInput ||
                                    defaultTranslation.newLoginRegistrations.invalidInput
                                }`,
                            ]}
                        />
                        <TextValidator
                            label={translation?.myAccountPage.lastName}
                            onChange={handleChange}
                            name="lastName"
                            value={userData.lastName}
                            variant="outlined"
                            fullWidth
                            required
                            validators={['required', 'minStringLength: 1', 'maxStringLength: 50', 'isValidName']}
                            errorMessages={[
                                `${translation?.errors.required}`,
                                `${translation?.errors.required}`,
                                `${translation?.errors?.genericTooLongErrorFirst} 50 ${translation?.errors.genericTooLongErrorSecond}`,
                                `${
                                    translation.newLoginRegistrations.invalidInput ||
                                    defaultTranslation.newLoginRegistrations.invalidInput
                                }`,
                            ]}
                        />
                    </div>
                </ValidatorForm>
            </DialogContent>
            <DialogActions className="buttons-actions">
                <Button type="button" onClick={() => closeDialog('')}>
                    {translation?.generalText.close}
                </Button>
                <Button type="button" color="secondary" disabled={disabled} onClick={handleUpdateUserInformation}>
                    {translation?.generalText.update}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserInformation;
