import React, {Component} from 'react';
import {connect} from 'react-redux';
import './AuditoriumCaptureControlsBar.scss'
import {
    sessionUserApproved,
    sessionUserKicked,
    sessionUserLeft,
    sessionUserMuted,
    sessionUserPermissionUpdated,
    sessionUserReady,
    sessionUserSharingDisabled,
    sessionUserSharingEnabled,
    sessionUserUnmuted,
    sessionUserVideoDisabled,
    sessionUserVideoEnabled,
    invitationAccepted,
    invitationDeclined,
    deregisterSessionUserCreated,
    deregisterSessionUserApproved,
    deregisterSessionUserReady,
    deregisterSessionUserKicked,
    deregisterSessionUserLeft,
} from "../../../Api/socketApi";
import * as actions from "../../../store/actions";
import PersonalStreamControls from "../../LiveSession/PersonalStreamControls";
import ClearIcon from '@material-ui/icons/Clear';
import axios from '../../../store/axios-instance';
import {ReactComponent as CancelIcon} from '../../../Images/svg/close-red.svg';
import colors from '../../../CSS/_variables.module.scss';
import {ReactComponent as LogoutIcon} from '../../../Images/svg/log-out.svg';
import {ReactComponent as MinimizeControls} from '../../../Images/svg/minimize_controls.svg';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import Confirm from '../../../Dialogs/Confirm';
import MicVolume from "../SessionSelfPreview/MicVolume";
import {isMobile} from 'react-device-detect';


class AuditoriumCaptureControlsBar extends Component {

    state = {
        deletingSession: false,
        openCancelRequest: false,
        action: '',
    };

    componentDidMount() {
        // if the invited user is on mobile, automatically decline the invitation and inform the moderator that the user is from a mobile
        if(isMobile){
            this.setState({
                action: "decline"
            }, this.leaveSession())
        }


        sessionUserApproved((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserReady((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        invitationAccepted((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        invitationDeclined((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserKicked((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserLeft((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        // triggered whenever a moderator toggles a speaker permission to stream audio/video/screenShare
        sessionUserPermissionUpdated((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserMuted((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserUnmuted((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserVideoEnabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserVideoDisabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        sessionUserSharingEnabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });
        sessionUserSharingDisabled((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentWillUnmount() {
        if(!this.state.deletingSession){
            this.leaveSession();
        }
        deregisterSessionUserCreated();
        deregisterSessionUserApproved();
        deregisterSessionUserReady();
        deregisterSessionUserKicked();
        deregisterSessionUserLeft();
    }

    getNameLetters = () => {
        const {liveSessionUser} = this.props;

        let firstTwoLetters = '';

        let userName = `${liveSessionUser.name}`;
        userName = userName.split(' ');
        userName = userName.slice(0,2);

        userName.map(namePart => firstTwoLetters += namePart.charAt(0));

        return firstTwoLetters;
    };

    leaveSession = () => {
        const {action} = this.state;
        const {liveSession, handleIsLeavingAuditorium, liveSessionUser} = this.props;

        // when we are on mobile, we automatically decline the invitation although we don't have access to the session in redux
        // because of this I also send the sessionId from the auditorium component
        const sessionId = liveSession?._id;
        if(sessionId){
            this.setState({
                deletingSession: true,
            });
            handleIsLeavingAuditorium();
            if (action === 'decline') {
                axios.post(`/sessions/${sessionId}/decline-invitation/${liveSessionUser.userId}`)
                    .then((data) => {
                        // we listen for the success message using sockets
                        this.setState({
                            deletingSession: false,
                            openCancelRequest: false,
                        });
                    });
            } else {
                axios.delete(`/sessions/${sessionId}/leave-session`)
                    .then((data) => {
                        // we listen for the success message using sockets
                        this.setState({
                            deletingSession: false,
                            openCancelRequest: false,
                        });
                    });
            }
        } else{
            console.warn('no sessionId for leaving session')
        }
    };

    isReady = () => {
        const {liveSession, liveSessionUser} = this.props;
        const sessionId = liveSession._id;
        axios.post(`/sessions/${sessionId}/set-ready/${liveSessionUser.userId}`)
            .then((data) => {
                // we listen for the success message using sockets
            })
    };

    displayAcceptInvitationBanner = () => {
        const {liveSessionUser} = this.props;

        return !liveSessionUser.invitationAccepted && liveSessionUser.isInvited;
    };

    acceptInvitation = () => {
        const {liveSession, liveSessionUser} = this.props;
        this.props.onInvitationAccepted(liveSession._id, liveSessionUser.userId);
        this.setState({
            invitationAccepted: true
        })
    };

    handleCancelRequestPopup = (action) => () => {
        this.setState({
            openCancelRequest: true,
            action: action,
        });
    };

    closeCancelRequest = () => {
        this.setState({
            openCancelRequest: false,
            action: '',
        });
    };

    render() {
        const {openCancelRequest} = this.state;
        const {liveSessionUser, translation, defaultTranslation, sessionSelfPreview} = this.props;
        const displayAcceptInvitationBanner = this.displayAcceptInvitationBanner();

        // don't display the invitation banner if is mobile
        // check on componentDidMount and see that we also automatically make the user decline the invitation
        if(isMobile){
            return null
        }
        return (
            <div className='capture-controls-bar-wrapper'>
                {displayAcceptInvitationBanner
                    ?<div className="one-row-banner">
                        <div className="info-text">
                            <p className={"title"}>{translation?.sessions.acceptInvitationTitle || defaultTranslation?.sessions.acceptInvitationTitle}</p>
                            <p>{translation?.sessions.acceptInvitationSubTitle || defaultTranslation?.sessions.acceptInvitationSubTitle}</p>
                        </div>
                        <div className="buttons-container">
                            <button type='button'
                                    onClick={this.handleCancelRequestPopup('decline')}
                            >
                                <ClearIcon fontSize='inherit'/>
                                <span>{translation?.generalText.decline || defaultTranslation?.generalText.decline}</span>
                            </button>
                            {!liveSessionUser.isReady &&
                            <button type='button'
                                    onClick={this.acceptInvitation}
                            >
                                <span>{translation?.sessions.acceptInvitationButton || defaultTranslation?.sessions.acceptInvitationButton}</span>
                            </button>
                            }
                        </div>
                    </div>
                    :
                    <>
                        {liveSessionUser.status === 'new' &&
                        <div className="one-row-banner">
                            <div className="info-text">
                                <p className={"title"}>{translation?.sessions.requestPendingTitle || defaultTranslation?.sessions.requestPendingTitle}</p>
                                <p>{translation?.sessions.requestPendingSubTitle || defaultTranslation?.sessions.requestPendingSubTitle}</p>
                            </div>
                            <div className="buttons-container">
                                <button type='button'
                                        onClick={this.handleCancelRequestPopup('cancel')}
                                >
                                    <CancelIcon fill={colors.dark}/>
                                    <span>{translation?.sessions.cancelRequest || defaultTranslation?.sessions.cancelRequest}</span>
                                </button>
                            </div>
                        </div>
                        }
                        {liveSessionUser.status === 'approved' && !liveSessionUser.isReady && !liveSessionUser.kicked &&
                            <div className='info-container'>
                                <p>{translation?.sessions.approvedTitle || defaultTranslation?.sessions.approvedTitle}</p>
                                <p>{translation?.sessions.approvedSubTitle || defaultTranslation?.sessions.approvedSubTitle}</p>
                            </div>
                        }
                        {liveSessionUser.isReady && !liveSessionUser.kicked &&
                            <div className='info-container'>
                                <p>{translation?.sessions.connectedToTheBackstage || defaultTranslation?.sessions.connectedToTheBackstage}</p>
                            </div>
                            }
                        {liveSessionUser.kicked &&
                            <div className='info-container'>
                                <p>{translation?.sessions.removedFromTheSession || defaultTranslation?.sessions.removedFromTheSession}</p>
                            </div>
                        }
                    </>
                }

                {liveSessionUser.status === 'approved' && !liveSessionUser.kicked && !displayAcceptInvitationBanner &&
                    <div className="actions-container">

                        <PersonalStreamControls controlsLocation={'auditorium'}/>

                        <div className="personal-details-container">
                            <div className="session-video-avatar">
                                <span>{this.getNameLetters()}</span>
                            </div>
                            <div className="personal-details">
                                <div className="name">
                                    <span>{liveSessionUser.name}</span>
                                </div>
                                <MicVolume />
                            </div>
                        </div>

                        <div className="buttons-container">
                            <button type='button'
                                    className={'button-settings'}
                                    onClick={this.props.handleToggleSessionSelfPreview}
                            >
                                {sessionSelfPreview
                                    ? <MinimizeControls fill={colors.white} width="24" height="24"/>
                                    : <SettingsIcon fill={colors.white} width="24" height="24"/>
                                }
                                {sessionSelfPreview
                                    ?
                                    <span>{translation?.auditorium.minimize || defaultTranslation?.auditorium.minimize}</span>
                                    :
                                    <span>{translation?.auditorium.settings || defaultTranslation?.auditorium.settings}</span>
                                }
                            </button>
                            <button type='button'
                                    className={`button-leave-session ${liveSessionUser.isReady ? '' : 'not-ready'}`}
                                    onClick={this.handleCancelRequestPopup('leave')}
                            >
                                {liveSessionUser.isReady
                                    ?<>
                                        <LogoutIcon fill={colors.white} width="24" height="24" />
                                        <span>{translation?.auditorium.leaveSession || defaultTranslation?.auditorium.leaveSession}</span>
                                    </>
                                    :<>
                                        <CloseIcon color={'inherit'} width="24" height="24" />
                                        <span>{translation?.generalText.cancel || defaultTranslation?.generalText.cancel}</span>
                                    </>
                                }
                            </button>
                            {!liveSessionUser.isReady &&
                                <button type='button'
                                        className={'button-ready'}
                                        onClick={this.isReady}
                                >
                                    <span>{translation?.sessions.imReady || defaultTranslation?.sessions.imReady}</span>
                                </button>
                            }
                        </div>
                    </div>
                }

                {openCancelRequest &&
                    <Confirm
                        open={openCancelRequest}
                        closeConfirm={this.closeCancelRequest}
                        dialogTitle={translation?.auditorium.leavePopupTitle || defaultTranslation?.auditorium.leavePopupTitle}
                        dialogDescription={translation?.auditorium.cancelDescription || defaultTranslation?.auditorium.cancelDescription}
                        dialogConfirmButtonLabel={translation?.generalText.confirm || defaultTranslation?.generalText.confirm}
                        dialogCancelButtonLabel={translation?.generalText.cancel || defaultTranslation?.generalText.cancel}
                        handleConfirm={this.leaveSession}/>
                }
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        liveSessionActiveUsers: state.liveSession.liveSessionActiveUsers,
        userData: state.user.data,
        eventRoles: state.user.eventRoles,
        userIsLoggedIn: state.user.loggedIn,
        eventData: state.event.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveSession: (sessionId) => dispatch(actions.getLiveSession(sessionId)),
        getLiveSessionSuccess: (session) => dispatch(actions.getLiveSessionSuccess(session)),
        getLiveSessionUser: (sessionId) => dispatch(actions.getLiveSessionUser(sessionId)),
        getLiveSessionUserSuccess: (user) => dispatch(actions.getLiveSessionUserSuccess(user)),
        onInvitationAccepted: (sessionId, userId) => dispatch(actions.invitationAccepted(sessionId, userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditoriumCaptureControlsBar);
