import React from 'react';
import {preventDefaultDrag} from '../../Utils/utils';

class TimeslotSpeakers extends React.Component {
    render() {
        const {speakers} = this.props;
        if (speakers.length === 0) {
            return null;
        }
        return (
            <div onDragStart={preventDefaultDrag} className="speakers-container">
                {speakers.map((speaker, index) => {
                    return (
                        <span onDragStart={preventDefaultDrag} draggable="false" className="speaker" key={speaker._id}>
                            {speaker.fullName}
                            {index !== speakers.length - 1 ? ', ' : null}
                        </span>
                    );
                })}
            </div>
        );
    }
}

export default TimeslotSpeakers;
