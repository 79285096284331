import React, {useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {Dialog, DialogContent, Button, DialogActions} from '@material-ui/core';
import {Web as WebIcon} from '@material-ui/icons';

import {registerSessionMember} from '../../../../store/actions';

const RegisterSessionMember = ({sessionId, invitationType, routeParams}) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [isOpened, setIsOpened] = useState(true);
    const history = useHistory();

    const onRegisterSessionMember = useCallback(
        () =>
            dispatch(
                registerSessionMember({
                    sessionId,
                    payload: {
                        name,
                        role: invitationType,
                    },
                    callback: () => {
                        setIsOpened(false);
                        history.push(`/event/${routeParams.eventSlug}/session/${routeParams.sessionId}`);
                    },
                })
            ),
        [dispatch, history, invitationType, name, routeParams, sessionId]
    );

    return (
        <Dialog open={isOpened}>
            <DialogContent>
                <h3>Join as a {invitationType}</h3>
                <p>
                    You’ve been invited as a {invitationType} to session. Input your name (it will be visible to session’s
                    participants).
                </p>
                <ValidatorForm onSubmit={() => onRegisterSessionMember()}>
                    <TextValidator
                        className="setting-input"
                        type="text"
                        fullWidth={true}
                        margin="normal"
                        variant="outlined"
                        placeholder="Your name"
                        onChange={(e) => setName(e.target.value)}
                        InputProps={{
                            endAdornment: <WebIcon />,
                        }}
                    />
                </ValidatorForm>
                <DialogActions>
                    <Button
                        disabled={name.length < 2 || name.length > 80}
                        variant="contained"
                        onClick={() => onRegisterSessionMember()}
                    >
                        Enter
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default RegisterSessionMember;
