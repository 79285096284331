import {
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_REQUEST,
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_SUCCESS,
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_FAIL,
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_RESET,
} from '../actions/actionTypes';

export const importAuditoriumProgramReducer = (state = {importedAuditoriumData: {}}, action) => {
    switch (action.type) {
        case IMPORT_AUDITORIUM_TIMESLOT_IMPORT_REQUEST:
            return {loading: true, success: false, importedAuditoriumData: {}};
        case IMPORT_AUDITORIUM_TIMESLOT_IMPORT_SUCCESS:
            return {
                loading: false,
                success: true,
            };
        case IMPORT_AUDITORIUM_TIMESLOT_IMPORT_FAIL:
            return {loading: false, success: false, error: action.payload};
        case IMPORT_AUDITORIUM_TIMESLOT_IMPORT_RESET:
            return {success: false};
        default:
            return state;
    }
};
