import * as actionTypes from './actionTypes';
import axios from '../axios-instance';

export const getUserDetailed = (userId) => {
    return (dispatch) => {
        dispatch(getUserDetailedStart());

        return axios({
            method: 'get',
            url: '/master-admin/user-detailed',
            params: {
                userId,
            },
        })
            .then((response) => {
                const {data} = response;

                dispatch(getUserDetailedSuccess(data));
            })
            .catch((error) => {
                dispatch(getUserDetailedFail(error));
            });
    };
};

const getUserDetailedStart = () => {
    return {
        type: actionTypes.GET_USER_DETAILED_START,
    };
};
const getUserDetailedSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_DETAILED_SUCCESS,
        payload: data,
    };
};
const getUserDetailedFail = (errorMessage) => {
    return {
        type: actionTypes.GET_USER_DETAILED_FAIL,
        payload: errorMessage,
    };
};

export const getOrganizers = () => {
    return (dispatch) => {
        dispatch(getOrganizersStart());

        return axios({method: 'get', url: `/master-admin/platform-organizers`})
            .then((response) => {
                const data = response.data.organizers;
                dispatch(getOrganizersSuccess(data));
            })
            .catch((error) => {
                let errorMessage = "We couldn't approve the organizer. Please try again.";
                dispatch(getOrganizersFail(errorMessage));
            });
    };
};

const getUsersByNameStart = () => {
    return {
        type: actionTypes.GET_USERS_BY_NAME_START,
    };
};
const getUsersByNameSuccess = (data) => {
    return {
        type: actionTypes.GET_USERS_BY_NAME_SUCCESS,
        payload: data,
    };
};
const getUsersByNameFail = (errorMessage) => {
    return {
        type: actionTypes.GET_USERS_BY_NAME_FAIL,
        payload: errorMessage,
    };
};

export const getUsersByName = (search, deleteUserFlag = false) => {
    return (dispatch) => {
        dispatch(getUsersByNameStart());

        return axios({
            method: 'get',
            url: '/users',

            params: {
                search,
                deleteUserFlag,
            },
        })
            .then((response) => {
                dispatch(getUsersByNameSuccess(response));

                console.log(response);

                if (deleteUserFlag && response.data.users?.length === 0) {
                    alert('No users with this email found.');
                }
            })
            .catch((error) => {
                const errorMessage = "We couldn't find the users. Please try again.";
                dispatch(getUsersByNameFail(errorMessage));
            });
    };
};

const deleteUserv2Start = () => {
    return {
        type: actionTypes.DELETE_USER_V2_START,
    };
};
const deleteUserv2Success = (data) => {
    return {
        type: actionTypes.DELETE_USER_V2_SUCCESS,
        payload: data,
    };
};
const deleteUserv2Fail = (errorMessage) => {
    return {
        type: actionTypes.DELETE_USER_V2_FAIL,
        payload: errorMessage,
    };
};

const deleteUserv2Reset = () => {
    return {
        type: actionTypes.DELETE_USER_V2_IDLE,
    };
};

export const deleteUserv2 = (userId) => {
    return (dispatch) => {
        dispatch(deleteUserv2Start());

        return axios({
            method: 'DELETE',
            url: `/users/${userId}`,
        })
            .then((res) => {
                dispatch(deleteUserv2Success());
            })
            .catch((error) => dispatch(deleteUserv2Fail()))
            .finally(() => dispatch(deleteUserv2Reset()));
    };
};

const getOrganizersStart = () => {
    return {
        type: actionTypes.GET_ORGANIZERS_START,
    };
};
const getOrganizersSuccess = (data) => {
    return {
        type: actionTypes.GET_ORGANIZERS_SUCCESS,
        payload: data,
    };
};
const getOrganizersFail = (errorMessage) => {
    return {
        type: actionTypes.GET_ORGANIZERS_FAIL,
        payload: errorMessage,
    };
};

export const approveOrganizer = (userId, data) => {
    return (dispatch) => {
        dispatch(approveOrganizerStart());

        return axios({method: 'put', url: `/users/${userId}`, data: data})
            .then((response) => {
                dispatch(approveOrganizerSuccess());
            })
            .catch((error) => {
                let errorMessage = "We couldn't approve the organizer. Please try again.";
                dispatch(approveOrganizerFail(errorMessage));
            });
    };
};

const approveOrganizerStart = () => {
    return {
        type: actionTypes.APPROVE_ORGANIZER_START,
    };
};
const approveOrganizerSuccess = () => {
    return {
        type: actionTypes.APPROVE_ORGANIZER_SUCCESS,
    };
};
const approveOrganizerFail = (errorMessage) => {
    return {
        type: actionTypes.APPROVE_ORGANIZER_FAIL,
        payload: errorMessage,
    };
};

export const disableOrganizer = (userId, data) => {
    return (dispatch) => {
        dispatch(disableOrganizerStart());

        return axios({method: 'put', url: `/users/${userId}`, data: data})
            .then((response) => {
                dispatch(disableOrganizerSuccess());
            })
            .catch((error) => {
                let errorMessage = "We couldn't disable the organizer. Please try again.";
                dispatch(disableOrganizerFail(errorMessage));
            });
    };
};

const disableOrganizerStart = () => {
    return {
        type: actionTypes.DISABLE_ORGANIZER_START,
    };
};
const disableOrganizerSuccess = () => {
    return {
        type: actionTypes.DISABLE_ORGANIZER_SUCCESS,
    };
};
const disableOrganizerFail = (errorMessage) => {
    return {
        type: actionTypes.DISABLE_ORGANIZER_FAIL,
        payload: errorMessage,
    };
};

export const deleteOrganizer = (userId) => {
    return (dispatch) => {
        dispatch(deleteOrganizerStart());

        return axios({method: 'delete', url: `/users/${userId}`})
            .then((response) => {
                dispatch(deleteOrganizerSuccess());
            })
            .catch((error) => {
                let errorMessage = "We couldn't delete the organizer. Please try again.";
                dispatch(deleteOrganizerFail(errorMessage));
            });
    };
};

const deleteOrganizerStart = () => {
    return {
        type: actionTypes.DELETE_ORGANIZER_START,
    };
};
const deleteOrganizerSuccess = () => {
    return {
        type: actionTypes.DELETE_ORGANIZER_SUCCESS,
    };
};
const deleteOrganizerFail = (errorMessage) => {
    return {
        type: actionTypes.DELETE_ORGANIZER_FAIL,
        payload: errorMessage,
    };
};

export const getBrandingTranslation = (language) => {
    return (dispatch) => {
        dispatch(getBrandingTranslationStart());

        return axios({method: 'get', url: `/branding-translation/${language}`})
            .then((response) => {
                const data = response.data.translation;
                dispatch(getBrandingTranslationSuccess(data));
            })
            .catch((error) => {
                let errorMessage = '';
                dispatch(getBrandingTranslationFail(errorMessage));
            });
    };
};

const getBrandingTranslationStart = () => {
    return {
        type: actionTypes.GET_BRANDING_TRANSLATION_START,
    };
};
const getBrandingTranslationSuccess = (data) => {
    return {
        type: actionTypes.GET_BRANDING_TRANSLATION_SUCCESS,
        payload: data,
    };
};
const getBrandingTranslationFail = (errorMessage) => {
    return {
        type: actionTypes.GET_BRANDING_TRANSLATION_FAIL,
        payload: errorMessage,
    };
};

export const updateBrandingTranslation = (data) => {
    return (dispatch) => {
        dispatch(updateBrandingTranslationStart());
        return axios({method: 'post', url: `/branding-translation`, data: data})
            .then((response) => {
                dispatch(updateBrandingTranslationSuccess());
            })
            .catch((error) => {
                let errorMessage = '';
                dispatch(updateBrandingTranslationFail(errorMessage));
            });
    };
};
export const updateAdminBrandingTranslation = (data) => {
    return (dispatch) => {
        dispatch(updateBrandingTranslationStart());
        return axios({method: 'post', url: `/gdpr-template`, data: data})
            .then((response) => {
                dispatch(updateBrandingTranslationSuccess());
            })
            .catch((error) => {
                let errorMessage = '';
                dispatch(updateBrandingTranslationFail(errorMessage));
            });
    };
};

const updateBrandingTranslationStart = () => {
    return {
        type: actionTypes.UPDATE_BRANDING_TRANSLATION_START,
    };
};
const updateBrandingTranslationSuccess = () => {
    return {
        type: actionTypes.UPDATE_BRANDING_TRANSLATION_SUCCESS,
    };
};
const updateBrandingTranslationFail = (errorMessage) => {
    return {
        type: actionTypes.UPDATE_BRANDING_TRANSLATION_FAIL,
        payload: errorMessage,
    };
};

export const setAdminBrandingLanguage = (language) => {
    return {
        type: actionTypes.SET_ADMIN_BRANDING_LANGUAGE,
        payload: language,
    };
};

export const getAdminAllEvents = (page, perPage, sortBy, sortOrder, search) => {
    return (dispatch) => {
        dispatch(getAdminAllEventsStart());

        return axios({
            method: 'get',
            url: `/master-admin/platform-events${page ? `?page=${page}` : ''}${perPage ? `&perPage=${perPage}` : ''}${
                sortBy ? `&sortBy=${sortBy}` : ''
            }${sortOrder ? `&sortOrder=${sortOrder}` : ''}${search ? `&search=${search}` : ''}`,
        })
            .then((response) => {
                const data = response.data;
                dispatch(getAdminAllEventsSuccess(data));
            })
            .catch((error) => {
                let errorMessage = "We couldn't get all events. Please try again.";
                dispatch(getAdminAllEventsFail(errorMessage));
            });
    };
};

const getAdminAllEventsStart = () => {
    return {
        type: actionTypes.GET_ADMIN_ALL_EVENTS_START,
    };
};
const getAdminAllEventsSuccess = (data) => {
    return {
        type: actionTypes.GET_ADMIN_ALL_EVENTS_SUCCESS,
        payload: data,
    };
};
const getAdminAllEventsFail = (errorMessage) => {
    return {
        type: actionTypes.GET_ADMIN_ALL_EVENTS_FAIL,
        payload: errorMessage,
    };
};

export const setDataForDownloadExcel = (sortBy, sortOrder, search) => {
    return (dispatch) => {
        dispatch(setDataForDownloadExcelStart());

        return axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: `/master-admin/platform-events-excel${sortBy ? `?sortBy=${sortBy}` : ''}${
                sortOrder ? `&sortOrder=${sortOrder}` : ''
            }${search ? (!sortBy ? `?search=${search}` : `&search=${search}`) : ''}`,
        })
            .then((response) => {
                const data = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
                });
                dispatch(setDataForDownloadExcelSuccess(data));
            })
            .catch((error) => {
                let errorMessage = "We couldn't set all events. Please try again.";
                dispatch(setDataForDownloadExcelFail(errorMessage));
            });
    };
};

const setDataForDownloadExcelStart = () => {
    return {
        type: actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_START,
    };
};
const setDataForDownloadExcelSuccess = (data) => {
    return {
        type: actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_SUCCESS,
        payload: data,
    };
};
const setDataForDownloadExcelFail = (errorMessage) => {
    return {
        type: actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_FAIL,
        payload: errorMessage,
    };
};

export const getEventsToday = () => {
    return (dispatch) => {
        dispatch(getEventsTodayStart());

        return axios({
            method: 'get',
            url: `/master-admin/platform-current-events`,
        })
            .then((response) => {
                const data = response.data.events;
                dispatch(getEventsTodaySuccess(data));
            })
            .catch((error) => {
                let errorMessage = "We couldn't approve the organizer. Please try again.";
                dispatch(getEventsTodayFail(errorMessage));
            });
    };
};

const getEventsTodayStart = () => {
    return {
        type: actionTypes.GET_EVENTS_TODAY_START,
    };
};
const getEventsTodaySuccess = (data) => {
    return {
        type: actionTypes.GET_EVENTS_TODAY_SUCCESS,
        payload: data,
    };
};
const getEventsTodayFail = (errorMessage) => {
    return {
        type: actionTypes.GET_EVENTS_TODAY_FAIL,
        payload: errorMessage,
    };
};

export const getAdminCampaigns = () => {
    return (dispatch) => {
        dispatch(getAdminCampaignsStart());

        return axios({method: 'get', url: `/admin-emails/campaigns`})
            .then((res) => {
                let campaigns = res.data.campaigns;
                dispatch(getAdminCampaignsSuccess(campaigns));
            })
            .catch((err) => {
                dispatch(getAdminCampaignsFail(err));
            });
    };
};

export const getAdminCampaignsStart = () => {
    return {
        type: actionTypes.GET_ADMIN_CAMPAIGNS_START,
    };
};
export const getAdminCampaignsSuccess = (data) => {
    return {
        type: actionTypes.GET_ADMIN_CAMPAIGNS_SUCCESS,
        payload: data,
    };
};
export const getAdminCampaignsFail = (errorMessage) => {
    return {
        type: actionTypes.GET_ADMIN_CAMPAIGNS_FAIL,
        payload: errorMessage,
    };
};

export const getAdminAudiences = () => {
    return (dispatch) => {
        dispatch(getAdminAudiencesStart());

        return axios({method: 'get', url: `/admin-emails/audiences`})
            .then((res) => {
                let audiences = res.data.audiences;
                dispatch(getAdminAudiencesSuccess(audiences));
            })
            .catch((err) => {
                dispatch(getAdminAudiencesFail(err));
            });
    };
};

const getAdminAudiencesStart = () => {
    return {
        type: actionTypes.GET_ADMIN_AUDIENCES_START,
    };
};
const getAdminAudiencesSuccess = (data) => {
    return {
        type: actionTypes.GET_ADMIN_AUDIENCES_SUCCESS,
        payload: data,
    };
};
const getAdminAudiencesFail = (errorMessage) => {
    return {
        type: actionTypes.GET_ADMIN_AUDIENCES_FAIL,
        payload: errorMessage,
    };
};

export const addAdminCampaign = (data) => async (dispatch) => {
    dispatch({type: actionTypes.ADD_ADMIN_CAMPAIGN_START});
    try {
        const response = await axios.post(`/admin-emails/campaigns`, data);
        dispatch({
            type: actionTypes.ADD_ADMIN_CAMPAIGN_SUCCESS,
            payload: response.data.campaign,
        });
    } catch (error) {
        dispatch({type: actionTypes.ADD_ADMIN_CAMPAIGN_FAIL, error});
    }
};

export const duplicateAdminCampaign = (campaignId) => async (dispatch) => {
    dispatch({type: actionTypes.ADD_ADMIN_CAMPAIGN_START});
    try {
        const response = await axios.post(`/admin-emails/campaigns/${campaignId}/duplicate`);
        dispatch({
            type: actionTypes.ADD_ADMIN_CAMPAIGN_SUCCESS,
            payload: response.data.campaign,
        });
    } catch (error) {
        dispatch({type: actionTypes.ADD_ADMIN_CAMPAIGN_FAIL, error});
    }
};

export const deleteAdminCampaign = (campaignId) => async (dispatch) => {
    dispatch({type: actionTypes.DELETE_ADMIN_CAMPAIGN_START});
    try {
        const response = await axios.delete(`/admin-emails/campaigns/${campaignId}`);
        dispatch({
            type: actionTypes.DELETE_ADMIN_CAMPAIGN_SUCCESS,
            payload: campaignId,
        });
    } catch (error) {
        dispatch({type: actionTypes.DELETE_ADMIN_CAMPAIGN_FAIL, error});
    }
};

export const updateAdminCampaign = (campaignId, data) => async (dispatch) => {
    dispatch({type: actionTypes.UPDATE_ADMIN_CAMPAIGN_START});
    try {
        const response = await axios.put(`/admin-emails/campaigns/${campaignId}`, data);
        dispatch({
            type: actionTypes.UPDATE_ADMIN_CAMPAIGN_SUCCESS,
            payload: response.data.campaign,
        });
    } catch (error) {
        dispatch({type: actionTypes.UPDATE_ADMIN_CAMPAIGN_FAIL, error});
    }
};

export const addAdminCampaignToEmailQueue = (campaignId) => {
    return (dispatch) => {
        dispatch(addAdminCampaignToEmailQueueStart(campaignId));

        return axios({
            method: 'post',
            url: `/admin-emails/campaigns/${campaignId}/add-to-queue`,
        })
            .then(() => {})
            .catch((err) => {});
    };
};

export const addAdminCampaignToEmailQueueStart = (campaignId) => {
    return {
        type: actionTypes.ADD_ADMIN_CAMPAIGN_TO_QUEUE_START,
        payload: campaignId,
    };
};
