import {memo} from 'react';
import {connect} from 'react-redux';
import {VideoPlayerVideoPlates} from './VideoPlayerVideoPlates';
import * as selectors from '../../../../selectors';

export default connect(
    (state) => ({
        members: selectors.getUsers(state),
        playbacks: selectors.getPlaybacks(state),
    }),
    null
)(memo(VideoPlayerVideoPlates));
