import React from 'react';
import {connect} from 'react-redux';
import PlatformMenu from '../Components/PlatformMenu';
import '../CSS/otherPages.scss';
import HomepageBackground from '../Images/backgrounds/homepage-background.jpg';
import {preventDefaultDrag} from '../Utils/utils';

class NotAvailable extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {translation, defaultTranslation} = this.props;
        return (
            <div>
                <PlatformMenu />
                <div onDragStart={preventDefaultDrag} className="homepage login-page">
                    <div
                        onDragStart={preventDefaultDrag}
                        className="event-container"
                        style={{backgroundImage: `url('${HomepageBackground}')`}}
                    >
                        <div onDragStart={preventDefaultDrag} className="homepage-overlay">
                            <div onDragStart={preventDefaultDrag} className="smallContainer">
                                <div onDragStart={preventDefaultDrag} className="single-event-container">
                                    <h1 className="page-title">
                                        {translation?.notAvailable.title ||
                                            defaultTranslation?.notAvailable.title}
                                    </h1>
                                    <p>
                                        {translation?.notAvailable.description ||
                                            defaultTranslation?.notAvailable.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
        translation: state.languages.translations[state.languages.eventLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(NotAvailable);
