import React from 'react';
import './EventWelcomeScreen.scss'
import * as actions from "../../store/actions";

import DialogContent from '@material-ui/core/DialogContent';
import {connect} from 'react-redux';
import {isMobile} from 'react-device-detect';
import {preventDefaultDrag} from '../../Utils/utils';


class WelcomePicture extends React.Component {


    render() {
        const {eventWelcomeScreen, eventId} = this.props;
        const displayMobileImage = isMobile && eventWelcomeScreen.data?.mobileImage;
        return (
            <DialogContent>
                <div onDragStart={preventDefaultDrag} className={`booth-video-container ${displayMobileImage ? 'portrait' : ''}`}>
                    <div className="media-container">
                        {displayMobileImage
                            ?<img src={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${eventWelcomeScreen.data?.mobileImage}`}
                                  alt={"Welcome to event"}
                                  className={'welcome-image mobile'}
                            />
                            :<img src={`${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${eventWelcomeScreen.data?.desktopImage}`}
                                  alt={"Welcome to event"}
                                  className={'welcome-image desktop'}
                            />
                        }
                    </div>

                </div>
            </DialogContent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventWelcomeScreen: state.eventWelcomeScreen,
        languages: state.languages,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEventWelcomeScreen: (eventId) => dispatch(actions.getEventWelcomeScreen(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePicture);

