export const INVITATION_TYPES = {
    speakerInvitation: 'speaker-invitation',
    moderatorInvitation: 'moderator-invitation',
};

export const PARTICIPANT_TYPES = {
    organizer: 'organizer',
    speaker: 'speaker',
    moderator: 'moderator',
};

export const PROFILE_STATUSES = {
    new: 'new',
    approved: 'approved',
    pending: 'pending',
};

export const MEDIA_TYPES = {
    audio: 'audio',
    video: 'video',
    shareScreen: 'shareScreen',
};
