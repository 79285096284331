import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import Networking from '../../HOC/Networking';
import axios from '../../store/axios-instance';
import {newTimeslotAttendee} from '../../Api/socketApi';
import MatchingDialog from '../../Components/EventManagement/Matching/MatchingDialog';
import MatchingDialogConfirm from '../../Components/EventManagement/Matching/MatchingDialogConfirm';

class AuditoriumNetworking extends Component {
    state = {
        loadingAttendees: false,
        error: {
            hasError: false,
            message: '',
        },
        attendees: [],
        openMatchingCloseDialog: false,
    };

    componentDidMount() {
        if (this.props.timeSlotId) {
            this.setTimeSlotAttendees();
        }

        newTimeslotAttendee((err, data) => {
            const {attendees} = this.state;
            // check if new user id is already in list
            if (attendees.filter((attendee) => attendee === data.userId).length === 0) {
                const updatedAttendees = attendees.concat(data.userId);
                this.setState({
                    attendees: updatedAttendees,
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        const {timeSlotId} = this.props;
        if (prevProps.timeSlotId !== timeSlotId && timeSlotId) {
            this.setTimeSlotAttendees();
        }
    }

    setTimeSlotAttendees = () => {
        const {eventId, auditoriumId, timeSlotId} = this.props;
        this.setState({
            loadingAttendees: true,
            error: {
                hasError: false,
                message: '',
            },
        });

        axios({method: 'get', url: `event/${eventId}/auditoriums/${auditoriumId}/program/${timeSlotId}/attendees`})
            .then((response) => {
                const attendees = [...new Set(response.data.attendees)];
                this.setState({
                    loadingAttendees: false,
                    attendees: attendees,
                });
            })
            .catch(() => {
                this.setState({
                    error: {
                        loadingAttendees: false,
                        hasError: true,
                        message: 'There was a problem loading the attendees',
                    },
                });
            });
    };

    handleRequestCloseMatchingBanner = () => this.props.onRequestCloseMatchingBanner(true);
    openMatchingCloseDialog = () => this.setState({openMatchingCloseDialog: !this.state.openMatchingCloseDialog});

    closeMatchingModal = () => {
        this.setState({openMatchingCloseDialog: false});
        this.props.onToggleMatchingDialog(false);
    };

    openMatchingModal = () => {
        const {eventId, onGetQuestionnaires, onToggleMatchingDialog} = this.props;
        onGetQuestionnaires(eventId);
        onToggleMatchingDialog(true);
    };

    render() {
        const {attendees, loadingAttendees, openMatchingCloseDialog} = this.state;
        const {event, showInviteToStageButton, liveSessionGuestUsers, translation, defaultTranslation} = this.props;
        
        return (
            <>
                <Networking
                    attendees={attendees}
                    isAuditoriumNetworking
                    loadingAttendees={loadingAttendees}
                    showInviteToStageButton={showInviteToStageButton}
                    liveSessionGuestUsers={liveSessionGuestUsers}
                    openMatchingDialog={this.openMatchingModal}
                />
                {event?.matchingDialog && (
                    <MatchingDialog
                        noOpenNetworking
                        open={event?.matchingDialog}
                        close={this.openMatchingCloseDialog}
                        closeNoPrompt={this.closeMatchingModal}
                        closeBannerOnFinish={this.handleRequestCloseMatchingBanner}
                    />
                )}
                {openMatchingCloseDialog && (
                    <MatchingDialogConfirm
                        hasCloseButton
                        open={openMatchingCloseDialog}
                        dialogTitle={
                            translation?.matching?.closeMatchingTitle ||
                            defaultTranslation?.matching?.closeMatchingTitle
                        }
                        dialogDescription={`${
                            translation?.matching?.closeMatchingTextA ||
                            defaultTranslation?.matching?.closeMatchingTextA
                        } <br><br> ${
                            translation?.matching?.closeMatchingTextB ||
                            defaultTranslation?.matching?.closeMatchingTextB
                        }`}
                        cancelBtnLabel={translation?.generalText?.cancel || defaultTranslation?.generalText?.cancel}
                        confirmBtnLabel={translation?.generalText?.close || defaultTranslation?.generalText?.close}
                        closeConfirm={this.openMatchingCloseDialog}
                        handleConfirm={this.closeMatchingModal}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        eventUsers: state.eventUsers,
        user: state.user.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetQuestionnaires: (eventId) => dispatch(actions.getQuestionnaires(eventId)),
        onToggleMatchingDialog: (bool) => dispatch(actions.toggleMatchingDialog(bool)),
        onRequestCloseMatchingBanner: (bool) => dispatch(actions.requestCloseMatchingBanner(bool)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditoriumNetworking);
