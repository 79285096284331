import * as actionTypes from './actionTypes';
import axios from '../axios-instance';

export const apiCallInvitationStart = () => {
    return {
        type: actionTypes.API_CALL_INVITATIONS_START,
    };
};
export const apiCallInvitationFail = (error) => {
    return {
        type: actionTypes.API_CALL_INVITATIONS_FAIL,
        payload: error,
    };
};

export const getInvitationCodes = (eventId) => {
    return (dispatch) => {
        dispatch(apiCallInvitationStart());

        return axios({method: 'get', url: `/event/${eventId}/invitation-codes`})
            .then((response) => {
                let invitations = [];
                if (response.data) {
                    invitations = response.data.invitationCodes;
                }
                dispatch(getInvitationCodesSuccess(invitations));
            })
            .catch(() => dispatch(apiCallInvitationFail('getInvitationCodes')));
    };
};

const getInvitationCodesSuccess = (invitations) => {
    return {
        type: actionTypes.GET_INVITATION_CODES_SUCCESS,
        payload: invitations,
    };
};

export const addSingleInvitationCode = (codeData) => {
    return (dispatch) => {
        dispatch(apiCallInvitationStart());

        return axios({method: 'post', url: `/event/${codeData.eventId}/invitation-codes`, data: codeData})
            .then(() => {})
            .then(() => dispatch(getInvitationCodes(codeData.eventId)))
            .catch(() => dispatch(apiCallInvitationFail('deleteInvitationCode')));
    };
};

export const addMultipleInvitationCodes = (codeData) => {
    return (dispatch) => {
        dispatch(apiCallInvitationStart());

        return axios({
            method: 'post',
            url: `/event/${codeData.eventId}/invitation-codes/generate-invitation-codes`,
            data: codeData,
        })
            .then(() => {})
            .then(() => dispatch(getInvitationCodes(codeData.eventId)))
            .catch(() => dispatch(apiCallInvitationFail('deleteInvitationCode')));
    };
};

export const addImportInvitationCodes = (data) => {
    return (dispatch) => {
        dispatch(apiCallInvitationStart());

        return axios({
            method: 'post',
            url: `/event/${data.eventId}/invitation-codes/import-invitation-codes`,
            data: data,
        })
            .then(() => {})
            .then(() => dispatch(getInvitationCodes(data.eventId)))
            .catch(() => {
                dispatch(apiCallInvitationFail('deleteInvitationCode'));
            });
    };
};

export const deleteInvitationCode = (eventId, codeId) => {
    return (dispatch) => {
        dispatch(apiCallInvitationStart());

        return axios({method: 'delete', url: `/event/${eventId}/invitation-codes/${codeId}`})
            .then(() => {})
            .then(() => dispatch(getInvitationCodes(eventId)))
            .catch(() => dispatch(apiCallInvitationFail('deleteInvitationCode')));
    };
};

export const checkInvitationCode = (eventId, invitationCode) => {
    return (dispatch) => {
        dispatch(apiCallInvitationStart());

        const data = {
            code: invitationCode,
        };

        return axios({method: 'post', url: `/event/${eventId}/invitation-codes/check-invitation-code`, data: data})
            .then((response) => {
                if (response.data && response.data.isCodeValid) {
                    dispatch(checkInvitationCodeSuccess(response.data.isCodeValid, null));
                } else {
                    dispatch(checkInvitationCodeFail(response.data.isCodeValid, response.data.message));
                }
                return response.data;
            })
            .catch(() => dispatch(apiCallInvitationFail('checkInvitationCode')));
    };
};

const checkInvitationCodeSuccess = (isValid, errorMessage) => {
    return {
        type: actionTypes.CHECK_INVITATION_CODE_SUCCESS,
        payload: {isValid, errorMessage},
    };
};

const checkInvitationCodeFail = (isValid, errorMessage) => {
    return {
        type: actionTypes.CHECK_INVITATION_CODE_SUCCESS,
        payload: {isValid, errorMessage},
    };
};
