import {ReactComponent as Facebook} from '../Helpers/Icons/fb.svg';
import {ReactComponent as Twitter} from '../Helpers/Icons/tw.svg';
import {ReactComponent as Linkedin} from '../Helpers/Icons/in.svg';
import {ReactComponent as World} from '../Helpers/Icons/wd.svg';
const NotActiveSpeakerInfo = ({user}) => {
    return (
        <ul className="person-short-info">
            <li className="person-name">{user.name}</li>
            <li className="person-profession">{user.title}</li>
            <li className="person-social">
                {user.linkedIn && (
                    <a href={user.linkedIn} target="_blank" width="24" height="24" rel="noreferrer">
                        <Linkedin width="28" height="28" />
                    </a>
                )}
                {user.twitter && (
                    <a href={user.twitter} target="_blank" rel="noreferrer">
                        <Twitter width="27" height="27" />
                    </a>
                )}
                {user.facebook && (
                    <a href={user.facebook} target="_blank" rel="noreferrer">
                        <Facebook width="24" height="24" />
                    </a>
                )}
                {user.webpage && (
                    <a href={user.webpage} target="_blank" rel="noreferrer">
                        <World width="27" height="27" />
                    </a>
                )}
            </li>
        </ul>
    );
};

export default NotActiveSpeakerInfo;
