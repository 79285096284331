import * as actionTypes from './actionTypes';

export const setAuditoriumMuted = () => {
    return {
        type: actionTypes.SET_AUDITORIUM_MUTED,
    };
};

export const setAuditoriumUnMuted = () => {
    return {
        type: actionTypes.SET_AUDITORIUM_UNMUTED,
    };
};