import * as actionTypes from './actionTypes';

export const setUserMediaStreamCapture = (capture) => {
    return {
        type: actionTypes.SET_USER_MEDIA_STREAM_CAPTURE,
        payload: capture,
    };
};

export const setUserMediaStreamAudioDeviceId = (deviceId) => {
    return {
        type: actionTypes.SET_USER_MEDIA_STREAM_AUDIO_DEVICE_ID,
        payload: deviceId,
    };
};

export const setUserMediaStreamVideoDeviceId = (deviceId) => {
    return {
        type: actionTypes.SET_USER_MEDIA_STREAM_VIDEO_DEVICE_ID,
        payload: deviceId,
    };
};