import { useEffect } from 'react';

const useLoadScript = (src) => {
  useEffect(() => {
    if (!src) return;

    const script = document.createElement('script');
    script.id = 'openwidget';
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    document.body.appendChild(script);

    return () => {
      document.getElementById('openwidget')?.remove();
    };
  }, [src]);
};

const AIChatbot = ({ userRole }) => {
  const getScriptSrc = (role) => {
    switch (role) {
      case 'organizer':
      case 'co-organizer':
        return `${process.env.PUBLIC_URL}/open-widget-organizer-script-23473.js`;
      case 'company':
        return `${process.env.PUBLIC_URL}/open-widget-exhibitor-script-98253.js`;
      case 'scholar':
        return `${process.env.PUBLIC_URL}/open-widget-poster-study-script-07572.js`;
      default:
        return null;
    }
  };

  useLoadScript(getScriptSrc(userRole));

  useEffect(() => {
      window.OpenWidget?.call('minimize');
    return () => {
        window.OpenWidget?.call('hide');
    };
  }, [userRole]);

  return null;
};

export default AIChatbot;