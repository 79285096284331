import {VideoModule} from './VideoModule';
import {connect} from 'react-redux';
import {memo} from 'react';
import {
    changeSessionPlaybacks,
    toggleSessionScreen,
    toggleSessionShareScreen,
    toggleSessionFullScreen,
    videoConferenceReady,
} from '../../../../store/actions';
import * as selectors from '../../selectors';

export default connect(
    (state) => ({
        userProfile: selectors.getUserProfile(state),
        users: selectors.getUsers(state),
        videoConference: selectors.getVideoConference(state),
    }),
    {
        changeSessionPlaybacks,
        toggleSessionScreen,
        toggleSessionShareScreen,
        toggleSessionFullScreen,
        videoConferenceReady,
    }
)(memo(VideoModule));
