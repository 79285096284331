import React from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

class Pagination extends React.Component {
    render() {
        const {currentPage, previousURL, nextURL, totalPageCount, setInitialPage} = this.props;
        return (
            <div className={`pagination ${totalPageCount === 1 ? 'd-none' : null}`}>
                {parseInt(currentPage) !== 1 ? (
                    <div className="button-pag-wrapper previous-container" onClick={setInitialPage}>
                        <Link
                            to={{pathname: `/dashboard/organizers/all-events${previousURL}`}}
                            className="previous step-button"
                        >
                            <ChevronLeftOutlinedIcon className="pagination-icon" />
                        </Link>
                    </div>
                ) : null}
                {totalPageCount !== 1 ? (
                    <div className="page-count step-button">
                        {currentPage} {'of'} {totalPageCount}
                    </div>
                ) : null}
                {parseInt(currentPage) !== totalPageCount ? (
                    <div className="button-pag-wrapper next-container">
                        <Link
                            to={{pathname: `/dashboard/organizers/all-events${nextURL}`}}
                            className="next step-button"
                        >
                            <ChevronRightOutlinedIcon className="pagination-icon" />
                        </Link>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withRouter(Pagination);
