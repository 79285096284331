import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Search from './Search';
import DownloadProgramButton from './DownloadProgramButton';
import AddToCalendarButton from './AddToCalendarButton';
import DialogAddToExternalCalendar from '../DialogAddToExternalCalendar';
import {ReactComponent as ListStyle} from '../TimeslotsList/svgs/list.svg';
import {ReactComponent as CalendarStyle} from '../TimeslotsList/svgs/calendar.svg';
import './TopbarStyles.scss';
import {
    createExternalEventCalendarData,
    getEndHourOfLastTimeslot,
    getEventDescriptionFormattedBasedOnTimezoneValue,
    getEventStartOrEndDate,
    getProgramFormattedTitleDateMobile,
    getStartHourOfFirstTimeslot,
} from 'Utils/utils';
import SelectTimezone from './SelectTimezone';

const Topbar = ({
    setDebouncedSearch,
    setActiveTimeslot,
    setDisplayNumberOfSearchResult,
    setNumberOfSearchResult,
    displayNumberOfSearchResult,
    numberOfSearchResult,
    selectedDay,
    setUserTimezone,
    handleLayoutType,
    layoutType,
    layoutList,
}) => {
    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const languages = useSelector((state) => state?.languages);
    const translation = useSelector((state) => state.languages.translations[state.languages.platformLanguage]);
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);

    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
    const isMobile = useSelector((state) => state.layout.isMobile);

    const [externalCalendarDialog, setExternalCalendarDialog] = useState(false);
    const [calendarData, setCalendarData] = useState(null);
    const [showSearchInput, setShowSearchInput] = useState(false);

    const hideAddToExternalCalendarDialog = () => {
        setExternalCalendarDialog(false);
    };

    const createExternalCalendarData = () => {
        const calendarData = createExternalEventCalendarData(
            event,
            languages,
            user,
            event.eventStartDate,
            event.eventEndDate
        );

        setCalendarData(calendarData);
        setExternalCalendarDialog(true);
    };

    const getEventDescription = () => {
        const eventStartDate = getEventStartOrEndDate(event.eventStartDate, languages);
        const eventEndDate = getEventStartOrEndDate(event.eventEndDate, languages);
        const startHourOfFirstTimeslot = getStartHourOfFirstTimeslot(event, event.timezoneValue);
        const endHourOfLastTimeSlot = getEndHourOfLastTimeslot(event, event.timezoneValue);

        const eventDescription = getEventDescriptionFormattedBasedOnTimezoneValue(
            user,
            event,
            eventStartDate,
            eventEndDate,
            startHourOfFirstTimeslot,
            endHourOfLastTimeSlot,
            languages
        );

        return eventDescription;
    };
    return (
        <div className={`topbar ${showSearchInput ? 'is-searching' : ''}`}>
            <Search
                setShowSearchInput={setShowSearchInput}
                showSearchInput={showSearchInput}
                setDebouncedSearch={setDebouncedSearch}
                setDisplayNumberOfSearchResult={setDisplayNumberOfSearchResult}
                setNumberOfSearchResult={setNumberOfSearchResult}
                displayNumberOfSearchResult={displayNumberOfSearchResult}
                numberOfSearchResult={numberOfSearchResult}
            />

            <div className={'actions-container'}>
                {event.brandingData.programFile && isLargeScreen && (
                    <DownloadProgramButton eventId={event._id} programFile={event.brandingData.programFile} />
                )}
                {isLargeScreen && <AddToCalendarButton showAddToExternalCalendarDialog={createExternalCalendarData} />}
                {isMobile && (
                    <p className={'date-container-mobile-and-tablet'}>
                        {selectedDay && getProgramFormattedTitleDateMobile(selectedDay, languages)}
                    </p>
                )}
                {externalCalendarDialog && (
                    <DialogAddToExternalCalendar
                        open={externalCalendarDialog}
                        onClose={hideAddToExternalCalendarDialog}
                        eventCalendarData={calendarData}
                        durationTitle={
                            translation?.programDropdown.durationEventTitle ||
                            defaultTranslation?.programDropdown.durationEventTitle
                        }
                        duration={getEventDescription()}
                        title={
                            translation?.programDropdown.addEventToYourCalendarDialogTitle ||
                            defaultTranslation?.programDropdown.addEventToYourCalendarDialogTitle
                        }
                        tip={
                            translation?.programDropdown.tipDescription ||
                            defaultTranslation?.programDropdown.tipDescription
                        }
                    />
                )}
            </div>
            <div className={`list-style-and-calendar-container`}>
                {layoutList?.length > 1 && layoutType === 'list' && !showSearchInput && isLargeScreen && (
                    <div className={`item-list-styles-container`}>
                        <CalendarStyle
                            className={`${layoutType === 'calendar' ? 'active' : ''}`}
                            onClick={handleLayoutType('calendar')}
                        />
                        <ListStyle
                            className={`${layoutType === 'list' ? 'active' : ''}`}
                            onClick={handleLayoutType('list')}
                        />
                    </div>
                )}

                <div className="select-timezone-wrapper">
                    <SelectTimezone setActiveTimeslot={setActiveTimeslot} setUserTimezone={setUserTimezone} />
                </div>
            </div>
        </div>
    );
};

export default Topbar;
