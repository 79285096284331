import React, {useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import compact from 'lodash/compact';
import MinimizeScreenIcon from '@material-ui/icons/FullscreenExit';
import {ReactComponent as FullscreenIcon} from '../../../../Images/svg/fullscreen.svg';
import VideoChatPlayer from './VideoChatPlayer';
import VideoConferenceSpinner from './VideoConferenceSpinner';
import {preventDefaultDrag} from '../../../../Utils/utils';

const useStyles = makeStyles({
    root: ({hidden}) => {
        return {
            display: hidden ? 'none' : 'block',
        };
    },
});

const VideoConference = ({videoConference, hidden, members, translation, userId, chatId}) => {
    const classes = useStyles({hidden});
    const [fullScreenVideoConference, setFullScreenVideoConference] = useState(false);
    return (
        <div
            className={compact([
                classes.root,
                'video-conference-container',
                fullScreenVideoConference ? 'fullscreen' : null,
            ]).join(' ')}
        >
            <div onDragStart={preventDefaultDrag} className="position-relative">
                {!videoConference.isLoading && <VideoChatPlayer members={members} userId={userId} chatId={chatId} />}
                {videoConference.isLoading && <VideoConferenceSpinner />}
                <div
                    className="conference-buttons-container"
                    onClick={() => setFullScreenVideoConference(!fullScreenVideoConference)}
                >
                    <button className="toggle-fullscreen">
                        {fullScreenVideoConference && (
                            <div>
                                <MinimizeScreenIcon />
                                <span>{translation?.videoConferences.minimizeScreen}</span>
                            </div>
                        )}
                        {!fullScreenVideoConference && (
                            <div>
                                <FullscreenIcon />
                                <span>{translation?.videoConferences.fullScreen}</span>
                            </div>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    videoConference: state.videoConference,
});

export default connect(mapStateToProps)(VideoConference);
