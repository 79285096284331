import jwt from 'jsonwebtoken';
import {ConferenceApi, Utils} from 'avcore/client';
import {API_OPERATION} from 'avcore';

const avcoreUrl = process.env.REACT_APP_AVCORE_URL || 'https://streaming.myonvent.com';
const avcoreWorkersNumber = process.env.REACT_APP_AVCORE_WORKERS_NUMBER
    ? +process.env.REACT_APP_AVCORE_WORKERS_NUMBER
    : 8;
const avcoreWorker = Math.floor(Math.random() * Math.floor(avcoreWorkersNumber));
const defaultKinds = ['audio', 'video'];

// get the authenticating token for the stream server
// this should be done IN THE BACKEND
export const signChatToken = (stream, operation) => {
    const SECRET = 'LH_Secret1_';
    const ALGORITHM = 'HS512';
    return jwt.sign(
        {
            stream,
            operation,
            exp: Math.floor(Date.now() / 1000 + 12 * 24 * 3600),
        },
        SECRET,
        {algorithm: ALGORITHM}
    );
};

export const startCapture = async (publishStreamName, kinds = defaultKinds, isScreen, oldCapture, audioDeviceId, videoDeviceId) => {
    try {
        const publishToken = signChatToken(publishStreamName, API_OPERATION.PUBLISH);

        let hasAudio = false;
        let hasVideo = false;

        if(kinds.includes('audio')){
            hasAudio = true;
            if(audioDeviceId){
                hasAudio={
                    deviceId: audioDeviceId
                }
            }
        }

        if(kinds.includes('video')){
            hasVideo = true;
            if(videoDeviceId){
                hasVideo={
                    deviceId: videoDeviceId
                }
            }
        }

        let mediaStream = await Utils.getUserMedia(
            {video: hasVideo, audio: hasAudio && !isScreen},
            isScreen
        );
        if (isScreen && hasAudio) {
            const _stream = await Utils.getUserMedia({video: false, audio: hasAudio});
            mediaStream = new MediaStream([...mediaStream.getTracks(), ..._stream.getTracks()]);
        }

        // init our AVCore SDK instance
        const capture = new ConferenceApi({
            kinds,
            url: avcoreUrl,
            worker: avcoreWorker,
            stream: publishStreamName,
            token: publishToken,
            simulcast: true,
        });

        if (oldCapture) await oldCapture.close();
        // stream is now being published to the AVCore server and can be played by every client who knows stream name (publishStreamName)
        // publishStreamName = ${chatId}_${memberId}
        // every client that is able to authenticate
        await capture.publish(mediaStream);
        return capture;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const startPlayback = async (subscribeStreamName, kinds = defaultKinds) => {
    try {
        const subscribeToken = signChatToken(subscribeStreamName, API_OPERATION.SUBSCRIBE);

        const playback = new ConferenceApi({
            kinds,
            url: avcoreUrl,
            worker: avcoreWorker,
            stream: subscribeStreamName,
            token: subscribeToken,
            simulcast: true,
        });
        return playback;
    } catch (err) {
        console.error(err);
        return null;
    }
};
