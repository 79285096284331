import {SessionDashboard} from './SessionDashboard';
import {connect} from 'react-redux';
import {memo} from 'react';
import {sendChatMessage, closeSessionConference} from '../../store/actions';
import * as selectors from './selectors';

export default connect(
    (state) => ({
        profile: selectors.getUserProfile(state),
        sessionData: selectors.getSessionData(state),
        isFullScreen: selectors.getFullScreen(state),
        userIsLoggedIn: state.user.loggedIn,
        eventData: state.event.data,
    }),
    {
        sendChatMessage,
        closeSessionConference,
    }
)(memo(SessionDashboard));
