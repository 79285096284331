import React from 'react';
import HomepageBackground from '../Images/backgrounds/homepage-background.jpg';
import {preventDefaultDrag} from '../Utils/utils';

const PlatformNotAvailable = (props) => {
    return (
        <div
            onDragStart={preventDefaultDrag}
            className={`platform-not-available-page ${props.classes}`}
            style={{backgroundImage: `url('${HomepageBackground}')`}}
        >
            <div onDragStart={preventDefaultDrag} className="text-container">
                <p>
                    <span>
                        {props.message || `${process.env.REACT_APP_PLATFORM_NAME} is not currently optimized for mobile usage. ${process.env.REACT_APP_PLATFORM_NAME} apps coming soon. Please access your event using a PC or Mac!`}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default PlatformNotAvailable;
