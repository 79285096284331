import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    loading: false,
    error: false,
    data: null,
    triggerReloadUserProfile: false,
};

const reducer = (state = initialState, action) => 
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_USER_EVENT_PROFILE_START:
                draft.loading = true;
                break;

            case actionTypes.GET_USER_EVENT_PROFILE_SUCCESS:
                draft.loading = false;
                draft.data = action.payload;
                draft.triggerReloadUserProfile= false
                break;

            case actionTypes.GET_USER_EVENT_PROFILE_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            case actionTypes.TRIGGER_RELOAD_USER_PROFILE:
                draft.triggerReloadUserProfile = true;
                break;

            default:
                break;
        }
    })

export default reducer;
