import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import NotFound from '../../Pages/NotFound';
import Spinner from '../../SmallLayoutComponents/FullPageSpinner';

const SuperAdminRoute = ({component: Component, loggedIn, user, loadingUser, loadingEvent, ...rest}) => (
    <Route
        {...rest}
        render={(props) =>
            loggedIn === true && user.isMasterAdmin ? (
                <Component {...props} />
            ) : (
                <>
                    {loadingUser || loadingEvent ? (
                        <Spinner />
                    ) : (
                        <>
                            {loggedIn ? (
                                <NotFound />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: '/login',
                                        state: {from: props.location},
                                    }}
                                />
                            )}
                        </>
                    )}
                </>
            )
        }
    />
);

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        loadingUser: state.user.loading,
        loadingEvent: state.event.loading,
    };
};

export default connect(mapStateToProps)(SuperAdminRoute);
