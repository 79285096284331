import React, {useState, useEffect} from 'react';
import {ScreenShare as ScreenShareIcon, StopScreenShare as StopScreenShareIcon} from '@material-ui/icons';

import {startCapture} from '../../../../../../Utils/videoConference';
import axios from '../../../../../../store/axios-instance';

const VideoStreamButtonShare = ({capture, setCapture, streamName, user, session}) => {
    const [isFirstRender, setIsFirstRender] = useState(true);

    const toggleSharing = async (isShare) => {
        try {
            const userToken = localStorage.sessionToken || localStorage.token;
            if (!userToken) return;

            const videoUrlParam = isShare ? 'enable-sharing' : 'disable-sharing';

            await axios.post(`/sessions/${session._id}/${videoUrlParam}/${user.userId}`);
        } catch (err) {}
    };

    useEffect(() => {
        const updateCapture = async () => {
            const newCapture = await startCapture(streamName, undefined, user.screenSharing, capture);
            if (newCapture) setCapture(newCapture);
            else toggleSharing(!user.screenSharing);
        };

        if (isFirstRender) {
            setIsFirstRender(false);
            if (user.screenSharing) updateCapture();
            return;
        }

        updateCapture();
    }, [user.screenSharing]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {!user.screenSharing && <StopScreenShareIcon className="button" onClick={() => toggleSharing(true)} />}
            {!!user.screenSharing && <ScreenShareIcon className="button" onClick={() => toggleSharing(false)} />}
        </>
    );
};

export default VideoStreamButtonShare;
