import React, {Component} from 'react';

export default class RequestSent extends Component {
    render() {
        return (
            <svg
                draggable="false"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 13.5063 2.92199 15.7972 4.44531 17.5521C4.13961 19.1183 3.79331 21.1397 3.59082 22.377L8.19336 20.25C9.36672 20.7334 10.6522 21 12 21Z"
                    fill={this.props.fillWarning}
                />
                <path
                    d="M19.0711 3.87004C17.1822 2.0193 14.6711 1 12.0001 1C9.3289 1 6.81764 2.0193 4.92907 3.87004C3.04021 5.72079 2 8.18142 2 10.7989C2 12.9749 2.74641 15.1023 4.10646 16.8146L2.74052 22.4653C2.70161 22.626 2.75902 22.7943 2.88865 22.9C2.96978 22.9659 3.07036 23 3.1719 23C3.23274 23 3.29413 22.9876 3.35182 22.9625L9.49745 20.2877C10.3125 20.4937 11.1535 20.5979 12.0001 20.5979C14.6711 20.5979 17.1822 19.5786 19.0711 17.7277C20.9599 15.8771 22 13.4162 22 10.7989C22 8.18142 20.9598 5.72079 19.0711 3.87004ZM12.0001 19.7287C11.1792 19.7287 10.3645 19.6213 9.57802 19.4096C9.47922 19.3828 9.37412 19.3906 9.28053 19.4313L3.80951 21.8125L5.0191 16.8097C5.05102 16.6782 5.01841 16.5398 4.93139 16.435C3.61301 14.8492 2.88687 12.8476 2.88687 10.7989C2.88687 5.87494 6.97496 1.86891 12.0001 1.86891C17.025 1.86891 21.113 5.87494 21.113 10.7989C21.113 15.723 17.025 19.7287 12.0001 19.7287Z"
                    fill={this.props.fillWarning}
                />
                <path
                    d="M12.0201 15C11.7129 15 11.4658 15.106 11.2795 15.3173C11.093 15.5292 11 15.7831 11 16.0793C11 16.3646 11.093 16.6163 11.2795 16.8329C11.466 17.0499 11.7129 17.1584 12.0201 17.1584C12.3266 17.1584 12.5898 17.0499 12.8095 16.8329C13.0285 16.6163 13.1384 16.3646 13.1384 16.0793C13.1384 15.7831 13.0285 15.5292 12.8095 15.3173C12.5898 15.106 12.3266 15 12.0201 15Z"
                    fill="white"
                />
                <path
                    d="M14.4944 5.77107C13.88 5.25721 13.0576 5 12.0268 5C11.1054 5 10.3705 5.20355 9.82246 5.61026C9.27383 6.01736 9 6.44573 9 6.89537C9 7.14185 9.08441 7.33198 9.25381 7.46557C9.42321 7.59955 9.61184 7.66644 9.81952 7.66644C9.98303 7.66644 10.1279 7.61201 10.2535 7.50257C10.3793 7.39332 10.4859 7.27659 10.5735 7.15239C10.6608 7.02839 10.8247 6.91166 11.0654 6.80222C11.3056 6.69297 11.6062 6.63835 11.9666 6.63835C12.4805 6.63835 12.8901 6.75737 13.1961 6.9958C13.5022 7.23385 13.6553 7.56984 13.6553 8.00396C13.6553 8.25792 13.5923 8.5042 13.466 8.74225C13.34 8.98049 13.2136 9.16046 13.0878 9.28217C12.9616 9.40388 12.7723 9.57063 12.5201 9.78242L12.0431 10.1633C11.3851 10.7352 11.0562 11.5712 11.0562 12.6721C11.0562 13.011 11.1135 13.2439 11.2285 13.3708C11.3439 13.498 11.5385 13.5615 11.8129 13.5615C12.3609 13.5615 12.6353 13.3687 12.6353 12.9832C12.6353 12.4372 12.6789 12.0193 12.767 11.7301C12.8544 11.4413 13.0848 11.1521 13.4578 10.8628L13.7723 10.6058L14.2031 10.2043C14.2916 10.1188 14.4275 9.96603 14.61 9.74638C14.7926 9.52712 14.9312 9.32875 15.0252 9.15222C15.1194 8.97551 15.2077 8.75854 15.2906 8.50152C15.3738 8.24469 15.4154 7.9823 15.4154 7.71455C15.4154 6.93294 15.1086 6.28492 14.4944 5.77107Z"
                    fill="white"
                />
                <path
                    d="M12.0201 15C11.7129 15 11.4658 15.106 11.2795 15.3173C11.093 15.5292 11 15.7831 11 16.0793C11 16.3646 11.093 16.6163 11.2795 16.8329C11.466 17.0499 11.7129 17.1584 12.0201 17.1584C12.3266 17.1584 12.5898 17.0499 12.8095 16.8329C13.0285 16.6163 13.1384 16.3646 13.1384 16.0793C13.1384 15.7831 13.0285 15.5292 12.8095 15.3173C12.5898 15.106 12.3266 15 12.0201 15Z"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M14.4944 5.77107C13.88 5.25721 13.0576 5 12.0268 5C11.1054 5 10.3705 5.20355 9.82246 5.61026C9.27383 6.01736 9 6.44573 9 6.89537C9 7.14185 9.08441 7.33198 9.25381 7.46557C9.42321 7.59955 9.61184 7.66644 9.81952 7.66644C9.98303 7.66644 10.1279 7.61201 10.2535 7.50257C10.3793 7.39332 10.4859 7.27659 10.5735 7.15239C10.6608 7.02839 10.8247 6.91166 11.0654 6.80222C11.3056 6.69297 11.6062 6.63835 11.9666 6.63835C12.4805 6.63835 12.8901 6.75737 13.1961 6.9958C13.5022 7.23385 13.6553 7.56984 13.6553 8.00396C13.6553 8.25792 13.5923 8.5042 13.466 8.74225C13.34 8.98049 13.2136 9.16046 13.0878 9.28217C12.9616 9.40388 12.7723 9.57063 12.5201 9.78242L12.0431 10.1633C11.3851 10.7352 11.0562 11.5712 11.0562 12.6721C11.0562 13.011 11.1135 13.2439 11.2285 13.3708C11.3439 13.498 11.5385 13.5615 11.8129 13.5615C12.3609 13.5615 12.6353 13.3687 12.6353 12.9832C12.6353 12.4372 12.6789 12.0193 12.767 11.7301C12.8544 11.4413 13.0848 11.1521 13.4578 10.8628L13.7723 10.6058L14.2031 10.2043C14.2916 10.1188 14.4275 9.96603 14.61 9.74638C14.7926 9.52712 14.9312 9.32875 15.0252 9.15222C15.1194 8.97551 15.2077 8.75854 15.2906 8.50152C15.3738 8.24469 15.4154 7.9823 15.4154 7.71455C15.4154 6.93294 15.1086 6.28492 14.4944 5.77107Z"
                    stroke="white"
                    strokeWidth="0.5"
                />
            </svg>
        );
    }
}
