import React from 'react';
import Wall from '../../HOC/Wall/Wall';
import Resources from '../../HOC/Wall/VideoResources';
import AuditoriumsSlider from './AuditoriumsSlider';
import AuditoriumBackgroundImage from '../../SmallLayoutComponents/AuditoriumBackgroundImage';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {
    checkIfEventHasEnded,
    checkIfAuditoriumProgramHasEnded,
    checkTimeslotHasResources,
    getUtcTimestamp,
    getTimeslotStartTimestamp,
    getTimeslotEndTimestamp,
    preventDefaultDrag,
    checkPlatformLanguage,
} from '../../Utils/utils';
import AuditoriumVideoPlayer from './AuditoriumVideoPlayer';
import AuditoriumBanners from './AuditoriumBanners';
import AuditoriumNavigation from './AuditoriumNavigation';
import AuditoriumNavigationMobile from './AuditoriumNavigationMobile';
import AuditoriumSlotTitle from './AuditoriumSlotTitle';
import AuditoriumSideButtons from './AuditoriumSideButtons/AuditoriumSideButtons';
import AuditoriumTabs from './AuditoriumTabs';
import AuditoriumNextVideoTimer from './AuditoriumNextVideoTimer';
import AuditoriumSnackbar from './AuditoriumSnackbar';
import LockIcon from '@material-ui/icons/Lock';
import {AuditorumPollsOutput} from '../../Components/AuditorumPollsOutput';
import OnDemandVideos from '../../HOC/Wall/OnDemandVideos';
import AuditoriumNetworking from './AuditoriumNetworking';
import cloneDeep from 'lodash/cloneDeep';
import {
    connectToSession,
    connectUserToTimeslot,
    disconnectFromSession,
    disconnectUserFromTimeslot,
    handlePollsUpdated,
    handleAuditoriumResourcesUpdate,
    sessionUpdated,
    sessionUserInvite,
    deregisterSessionUserInvite,
    sessionCanRequestMicrophone,
    socketProgramUpdated,
} from '../../Api/socketApi';
import axios from '../../store/axios-instance';
import '../../CSS/auditorium.scss';
import colors from '../../CSS/_variables.module.scss';
import {ReactComponent as BackIcon} from '../../Images/svg/back.svg';
import {ReactComponent as GreaterArrow} from '../../Images/svg/greater-arrow.svg';
import VideoContainer from '../../Components/VideoStream/VideoContainer/VideoContainer';
import AuditoriumCaptureControlsBar from './AuditoriumCaptureControlsBar/AuditoriumCaptureControlsBar';
import UserVideoStream from '../LiveSession/UserVideoStream';
import Confirm from '../../Dialogs/Confirm';
import SessionSelfPreview from './SessionSelfPreview/SessionSelfPreview';
import './AuditoriumStyles.scss';

class Auditorium extends React.Component {
    eventHasEndedTimerId = 0;

    state = {
        auditoriumRoomIndex: 0,
        auditoriumTimeslots: [],
        currentTimeSlotIndex: -1,
        timeSlot: null,
        videoStreamingUrl: '',
        videoStreamingStartAt: 0,
        viewVideoWall: false,
        totalSecondsTillNextVideo: null,
        totalSecondsTillCurrentTimeSlotEnds: null,
        checkedVideoDuration: false,
        auditoriumProgramHasEnded: false,
        auditoriumHasVideos: true,
        currentTab: 'wall',
        hasPolls: false,
        hasResources: false,
        liveStreamEnded: false,
        waitingRegistrationApproval: false,
        sessionToken: null,
        leaveAuditorium: false,
        nextPath: '',
        isLeaving: false,
        sessionSelfPreview: false,
        isFirstVisit: true,
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;
        if (!isLargeScreen) {
            this.setState({currentTab: 'video'});
        }
        handlePollsUpdated(({hasPolls}) => {
            this.setState({hasPolls}, async () => {
                this.loadCurrentTimeSlot();
            });
            if (this.state.currentTab === 'polls' && !hasPolls) {
                if (!isLargeScreen) {
                    this.setState({currentTab: 'video'});
                } else {
                    this.setState({
                        currentTab: 'wall',
                    });
                }
            }
        });

        handleAuditoriumResourcesUpdate((resourcesData) => {
            if (this.state.timeSlot._id === resourcesData.programId) {
                this.updateAuditoriumResources(resourcesData, (hasResources) => {
                    if (this.state.currentTab === 'resources' && !hasResources) {
                        if (!isLargeScreen) {
                            this.setState({currentTab: 'video'});
                        } else {
                            this.setState({
                                currentTab: 'wall',
                            });
                        }
                    }
                });
            }
        });

        sessionUpdated((err, data) => {
            // whenever the Organizer starts/stops the sessionStream, we receive a Socket notification
            this.setAuditoriumTimeslots();
        });

        sessionUserInvite((err, data) => {
            // check if the user that got the invitation is me
            // theoretically I should be the only one receiving it
            // but we should also check it here, in the front

            // get the guest user data
            this.getSessionUserGuestProfile();
        });

        sessionCanRequestMicrophone((data) => {
            const sessionData = data.session;
            this.props.getLiveSessionSuccess(sessionData);
        });

        this.props.onSetEventProtectedMenu();
        this.props.closeTopNavigation();
        this.calculateAuditoriumIndex();
        this.checkIfEventHasEnded();
        this.startEventHasEndedCountdown();
        socketProgramUpdated((err, programData) => {
            this.props.onProgramUpdated(programData);
        });

        window.addEventListener('beforeunload', this.beforeUnload);
    }

    componentDidUpdate(prevProps, prevState) {
        const {currentTab, timeSlot, isLeaving} = this.state;
        const {event, match, isLargeScreen, liveSessionUser} = this.props;
        // if the speaker wants to leave from auditorium, block the react router to push to a new path
        if (liveSessionUser?.isReady && liveSessionUser?.status === 'approved') {
            this.props.history.block((location, action) => {
                // react router needs to be locked and unlocked to make, eventually, the push
                if (isLeaving) {
                    // this is true when leave button from popup is clicked
                    return true;
                } else {
                    // we need the new path, especially for the auditorium navigation when we set the deep links
                    this.setState({
                        leaveAuditorium: true,
                        nextPath: location.pathname,
                    });
                    return false;
                }
            });
        }
        if (!this.state.leaveAuditorium && prevProps.match.params.auditoriumIndex !== match.params.auditoriumIndex) {
            this.calculateAuditoriumIndex();
        }
        if (prevState?.timeSlot?._id !== timeSlot?._id) {
            if (prevState?.timeSlot?._id) {
                this.disconnectFromTimeslotSocket(prevState?.timeSlot?._id);
                this.handleLeaveSession();
            }
            this.connectToTimeslotSocket();
        }
        if (prevProps.event.hideAuditoriumNetworking !== event.hideAuditoriumNetworking) {
            if (event.hideAuditoriumNetworking && currentTab === 'networking') {
                // if is on tablet/mobile, we handle the automatic tabChange from the AuditoriumTabs component
                if (isLargeScreen) {
                    this.handleChangeTab('wall')();
                }
            }
        }

        if (prevProps.liveSessionUser && !this.props.liveSessionUser) {
            this.handleLeaveSession();
        }

        // if the user pressed the I'm ready button
        // automatically close the sessionSelfPreview overlay
        if (!prevProps.liveSessionUser?.isReady && this.props.liveSessionUser?.isReady) {
            this.setState({
                sessionSelfPreview: false,
            });
        }

        if (prevState.timeSlot?._id !== this.state.timeSlot?._id && this.state.timeSlot?._id && isLargeScreen) {
            this.setState({
                currentTab: 'wall',
            });
        }

        if (
            !this.state.timeSlot?._id &&
            isLargeScreen &&
            this.props.hasOnDemandVideos !== prevProps.hasOnDemandVideos
        ) {
            this.setState({
                currentTab: 'onDemand',
            });
        }
    }

    calculateAuditoriumIndex = () => {
        const auditoriumsLength = this.props.event.auditoriums.length;
        const auditoriumIndexURL = this.props.match.params.auditoriumIndex - 1;
        // if the URL has an auditorium/${index} that matches the length of the current event
        // auditoriums (starting from 0), load and set the auditorium in state and URL
        if (
            auditoriumIndexURL !== undefined &&
            !isNaN(auditoriumIndexURL) &&
            auditoriumIndexURL >= 0 &&
            auditoriumIndexURL <= auditoriumsLength
        ) {
            this.setActiveAuditoriumIndex(auditoriumIndexURL)();
        } else {
            this.setAuditoriumTimeslots();
        }
    };

    componentWillUnmount() {
        this.beforeUnload();
        window.removeEventListener('beforeunload', this.beforeUnload);

        deregisterSessionUserInvite();
        // window.removeEventListener("focus", this.loadCurrentTimeSlot);
        // window.removeEventListener("blur", this.tabBlured);
    }

    tabBlured = () => {
        // if the user moves to another tab, we clear all the countdowns
        this.clearEventHasEndedCountdown();
        // only if he leaves the tab we want to listen the 'focus' on tab event
        // if we listen to 'focus' in componentDidMount we fire the loadCurrentTimeSlot function 2 times and all the countdowns speed 2x
        // if the user moves to another tab, we have to trigger the loadCurrentTimeslot function when he focuses back to it
        // the loadCurrentTimeslot function also reRegisteres all the countodowns
        window.addEventListener('focus', this.loadCurrentTimeSlot);
    };

    // fallback for last time slots without video
    // once every 10 seconds we check if the event has ended
    startEventHasEndedCountdown = () => {
        this.eventHasEndedTimerId = setInterval(this.checkIfEventHasEnded, 10000);
    };

    beforeUnload = () => {
        this.props.closeSidebar();
        this.clearEventHasEndedCountdown();
        if (this.state.timeSlot?._id) {
            this.disconnectFromTimeslotSocket(this.state.timeSlot._id);
        }
        this.handleLeaveSession();
    };

    clearEventHasEndedCountdown = () => {
        clearInterval(this.eventHasEndedTimerId);
    };

    connectToTimeslotSocket = () => {
        const {eventRoles} = this.props;

        // if the user isOrganizer and doesn't have the participant profile enabled, don't connect him to the socketTimeslot
        if (eventRoles.isOrganizer && !eventRoles.isParticipant) {
            return null;
        }
        if (this.state.timeSlot?._id) {
            const {user, event} = this.props;
            const userId = user._id;
            const eventId = event._id;
            const auditoriumId = event.auditoriums[this.state.auditoriumRoomIndex]._id;
            connectUserToTimeslot(userId, this.state?.timeSlot?._id, auditoriumId, eventId);
        }
    };

    disconnectFromTimeslotSocket = (timeSlotId) => {
        const {eventRoles} = this.props;
        // if the user isOrganizer and doesn't have the participant profile enabled, don't connect him to the socketTimeslot
        if (eventRoles.isOrganizer && !eventRoles.isParticipant) {
            return null;
        }

        const {user} = this.props;
        const userId = user._id;
        disconnectUserFromTimeslot(userId, timeSlotId);
    };

    setAuditoriumTimeslots = () => {
        // this function will run at componentDidMount or when the user navigates through auditoriums
        // create an array with all timeslots of the current auditorium
        // make sure to also add to each timeslot the day when it runs
        const currentAuditorium = cloneDeep(this.props.event?.auditoriums[this.state.auditoriumRoomIndex]);

        let auditoriumTimeslots = [];
        currentAuditorium.dailyProgram.forEach((dayProgram) => {
            const currentProgramDay = dayProgram.date;
            dayProgram.program.forEach((timeslot) => {
                timeslot.date = currentProgramDay;
                auditoriumTimeslots.push(timeslot);
            });
        });

        // after we have set up the timeslots for the auditorium, we trigger the loadCurrentTimeslot
        // so that we make sure we change the current timeslot properly

        this.setState({auditoriumTimeslots: auditoriumTimeslots}, () => {
            this.loadCurrentTimeSlot();
        });
    };

    checkIfEventHasEnded = () => {
        // check if event has ended
        // if yes, redirect to the video archive page
        if (this.props.event) {
            let eventHasEnded = checkIfEventHasEnded(this.props.event, this.props.event.timezoneValue);
            if (eventHasEnded) {
                this.clearEventHasEndedCountdown();
                this.props.history.push(`/event/${this.props.eventSlug}/auditorium-archive`);
            }
        }
    };

    checkIfAuditoriumProgramHasEnded = () => {
        this.setState({auditoriumProgramHasEnded: false}, () => {
            if (this.props.event) {
                let auditoriumProgramHasEnded = checkIfAuditoriumProgramHasEnded(
                    this.props.event,
                    this.props.event.timezoneValue,
                    this.state.auditoriumRoomIndex
                );
                if (auditoriumProgramHasEnded) {
                    this.setState({auditoriumProgramHasEnded: true});
                }
            }
        });
    };

    checkIfAuditoriumHasVideos = () => {
        if (this.props.event) {
            // const currentAuditorium = this.props.event.auditoriums[this.state.auditoriumRoomIndex];
            const currentAuditorium = cloneDeep(this.props.event?.auditoriums[this.state.auditoriumRoomIndex]);
            let allAuditoriumTimeslots = [];

            // we create an array with all the timeslots (for each day, we push the timeslots to allAuditoriumTimeslots)
            // if at least one timeslot has Videos, it means that the auditoriumHasVideos = true
            currentAuditorium.dailyProgram.forEach((dayProgram) => allAuditoriumTimeslots.push(...dayProgram.program));
            const programHasVideo = allAuditoriumTimeslots.filter((timeslot) => {
                return timeslot.video || timeslot.image || !!timeslot.session;
            });
            this.setState({auditoriumHasVideos: programHasVideo.length > 0});
        }
    };

    loadCurrentTimeSlot = (videoDuration) => {
        const {eventId, isLargeScreen} = this.props;
        this.props.onHasOnDemandVideos(eventId);
        this.checkIfEventHasEnded();
        this.checkIfAuditoriumProgramHasEnded();
        this.checkIfAuditoriumHasVideos();
        let currentTime = new Date();
        let currentTimeStamp = getUtcTimestamp(currentTime);

        const {auditoriumTimeslots} = this.state;

        // first we check if we have a current running video
        // save the index in the program
        // same for the timeslot data
        let timeSlot = null;
        let currentTimeSlotIndex = auditoriumTimeslots.findIndex((timeSlot) => {
            let timeSlotStartTimestamp = getTimeslotStartTimestamp(
                timeSlot.date,
                timeSlot,
                this.props.event.timezoneValue
            );
            let timeSlotEndTimestamp = getTimeslotEndTimestamp(timeSlot.date, timeSlot, this.props.event.timezoneValue);

            if (currentTimeStamp >= timeSlotStartTimestamp && currentTimeStamp < timeSlotEndTimestamp) {
                // if this current Timeslot has video, we set up the video URL
                if (timeSlot.video && !timeSlot.isLiveStreaming) {
                    let milisecondsFromStart = currentTimeStamp - timeSlotStartTimestamp;
                    let secondsFromStart = Math.floor(milisecondsFromStart / 1000);
                    // let videoUrl = timeSlot.video + '?t=' + secondsFromStart;
                    let videoUrl = timeSlot.video;
                    this.setState({
                        videoStreamingUrl: videoUrl,
                        videoStreamingStartAt: secondsFromStart,
                    });
                } else if (timeSlot.video && timeSlot.isLiveStreaming) {
                    let videoUrl = timeSlot.video;
                    this.setState({
                        videoStreamingUrl: videoUrl,
                        videoStreamingStartAt: 0,
                    });
                } else if (
                    !timeSlot.video &&
                    timeSlot.session?.link &&
                    timeSlot.isLiveStreaming &&
                    timeSlot.session?.type === 'recorded'
                ) {
                    let milisecondsFromStart = currentTimeStamp - timeSlotStartTimestamp;
                    let secondsFromStart = Math.floor(milisecondsFromStart / 1000);
                    let videoUrl = `${process.env.REACT_APP_SESSION_FOLDER}${timeSlot.session.link}`; //timeSlot.video;
                    this.setState({
                        videoStreamingUrl: videoUrl,
                        videoStreamingStartAt: secondsFromStart,
                    });
                }
                return timeSlot;
            }

            return null;
        });
        if (currentTimeSlotIndex !== -1) {
            timeSlot = auditoriumTimeslots[currentTimeSlotIndex];

            // if we have an active timeslot, we always show up the video wall
            this.setVideoWallData(timeSlot);
            // only if the user is not on mobile and doesn't have an active tab we automatically show up the wall
            if (isLargeScreen && (this.state.currentTab === '' || this.state.currentTab === 'onDemand')) {
                this.setState({currentTab: 'wall'});
            }

            // we set up a timer for the snackbar that will open 10, 5, 2 minutes before the timeslotEnds
            // totalSecondsTillCurrentTimeSlotEnds will be used in AuditoriumSnackbar
            let timeSlotEndTimestamp = getTimeslotEndTimestamp(timeSlot.date, timeSlot, this.props.event.timezoneValue);
            let videoWillEndInNextSeconds = timeSlotEndTimestamp - currentTimeStamp;

            videoWillEndInNextSeconds = Math.floor(videoWillEndInNextSeconds / 1000);
            this.setState({totalSecondsTillCurrentTimeSlotEnds: videoWillEndInNextSeconds});
        } else {
            this.setState({totalSecondsTillCurrentTimeSlotEnds: null});
        }

        // if we don't have an active timeslot at this time, search if we have a next timeslot to set up the timer
        // we also display the timer if the current timeslot has no video added
        if (
            currentTimeSlotIndex === -1 ||
            !(
                auditoriumTimeslots[currentTimeSlotIndex].video ||
                auditoriumTimeslots[currentTimeSlotIndex].isLiveStreaming
            )
        ) {
            if (isLargeScreen && currentTimeSlotIndex === -1 && this.props.hasOnDemandVideos) {
                this.setState({currentTab: 'onDemand'});
            }

            auditoriumTimeslots.findIndex((timeSlot) => {
                let timeSlotStartTimestamp = getTimeslotStartTimestamp(
                    timeSlot.date,
                    timeSlot,
                    this.props.event.timezoneValue
                );

                if (
                    currentTimeStamp < timeSlotStartTimestamp &&
                    (timeSlot.video || timeSlot.imageUrl || !!timeSlot.session)
                ) {
                    let nextVideoWillStartInNextSeconds = timeSlotStartTimestamp - currentTimeStamp;
                    nextVideoWillStartInNextSeconds = nextVideoWillStartInNextSeconds / 1000;

                    this.setState({
                        totalSecondsTillNextVideo: nextVideoWillStartInNextSeconds,
                    });

                    return timeSlot;
                }

                return null;
            });
        } else {
            this.setState({videoEnded: false});
        }
        this.setState(
            {
                currentTimeSlotIndex: currentTimeSlotIndex,
                timeSlot: timeSlot,
                liveStreamEnded: false,
                hasResources: checkTimeslotHasResources(timeSlot),
            },
            async () => {
                // Check if current timeslot has active polls before handlePollsUpdated socket
                this.setState({hasPolls: false});
                if (timeSlot !== null) {
                    const response = await axios.get(
                        `/polls/event/${this.props.event._id}/auditoriums/${
                            this.props.event.auditoriums[this.state.auditoriumRoomIndex]._id
                        }/program/${timeSlot._id}`
                    );
                    let hasPolls = response.data.polls?.map((poll) => poll.hidden).includes(false);
                    this.setState({hasPolls});
                }
            }
        );
    };

    setVideoWallData = (timeSlot) => {
        // if we have a time slot currently running
        // we set up the sideMenu to have videoWall
        this.props.setHasVideoWall(true);

        const activeVideoWallId = timeSlot.videoWall;
        // we save in state the current user Id's we need to highlight in the wall posts
        let highlightUsers = [];
        timeSlot.speakers.forEach((speaker) => {
            highlightUsers.push(speaker.user._id);
        });

        // we set up the current wall Id to be the video Wall Id
        const wallData = {
            wallId: activeVideoWallId,
            highlightUsers: highlightUsers,
        };
        this.props.setTimeSlotVideoWall(wallData);
        this.props.setActiveWall(wallData);

        // we open the sideBar to the videoWall
        this.props.seeVideoWall();
    };

    // set auditorium index in localStorage, state and push URL
    setActiveAuditoriumIndex = (auditoriumIndex) => () => {
        const {history, eventSlug, liveSessionUser, isLargeScreen} = this.props;
        const {isFirstVisit} = this.state;
        // we also display "leave auditorium" popup when user wants to navigate through auditoriums
        if (liveSessionUser?.isReady && liveSessionUser?.status === 'approved') {
            this.setState({
                leaveAuditorium: true,
                nextPath: `/event/${eventSlug}/auditorium/${auditoriumIndex + 1}`,
            });
        } else {
            this.props.closeSidebar();
            this.setState(
                {
                    auditoriumRoomIndex: auditoriumIndex,
                    currentTimeSlotIndex: -1,
                    timeslot: null,
                    currentTab: !isLargeScreen ? 'video' : '',
                },
                () => {
                    this.handleLeaveSession();
                    this.setAuditoriumTimeslots();
                    if (this.props.history.action === 'POP' && isFirstVisit) {
                        this.setState({isFirstVisit: false});
                    } else {
                        history.push(`/event/${eventSlug}/auditorium/${auditoriumIndex + 1}`);
                    }
                }
            );
        }
    };

    handleChangeTab = (tabName) => (e) => {
        this.setState({
            currentTab: tabName,
        });
    };

    pollsUpdated = (polls) => {
        // here we always get the latest polls for the current timeslot
    };

    getUserAuditoriumAccess = () => {
        let hasAccessToCurrentAuditorium = true;

        const {event, eventRoles, resourcesAccess} = this.props;
        const {auditoriumRoomIndex} = this.state;
        const auditoriums = event.auditoriums;

        if (event.hasAccessManagement && eventRoles.isParticipant && resourcesAccess) {
            hasAccessToCurrentAuditorium = resourcesAccess.auditoriums.includes(auditoriums[auditoriumRoomIndex]._id);
        } else if (
            (event.exhibitorHasAccessManagement || event.scholarHasAccessManagement) &&
            eventRoles.isExhibitor &&
            resourcesAccess
        ) {
            hasAccessToCurrentAuditorium = resourcesAccess.auditoriums.includes(auditoriums[auditoriumRoomIndex]._id);
        }

        return hasAccessToCurrentAuditorium;
    };

    setLiveStreamEnded = () => {
        this.setState({
            liveStreamEnded: true,
        });
    };

    setLiveStreamStarted = () => {
        this.setState({
            liveStreamEnded: false,
        });
    };

    checkLiveStreamEnded = () => {
        const {timeSlot} = this.state;
        return this.state.liveStreamEnded || (timeSlot?.session?.records?.length > 0 && !timeSlot?.session?.isActive);
    };
    checkLiveStreamPendingToStart = () => {
        const {timeSlot} = this.state;
        const timeslotHasNoRecords = timeSlot?.session?.records?.length === 0;
        return timeslotHasNoRecords && !timeSlot?.session?.isActive;
    };

    handleRegisterSession = () => {
        const {timeSlot} = this.state;
        const {user} = this.props;

        const sessionId = timeSlot?.session._id;
        const userName = `${user.first} ${user.last}`;

        const payload = {
            name: userName,
            role: 'speaker',
            isGuest: true,
            isReady: false,
        };

        axios.post(`/sessions/${sessionId}/request-access`, payload).then((response) => {
            const sessionUser = response.data.user;
            const sessionToken = response.data.sessionToken;

            connectToSession(sessionId, sessionToken);
            this.props.getLiveSession(sessionId);
            this.props.getLiveSessionUserSuccess(sessionUser);

            // we added the registration Request
            // we need to wait to be approved to enter the sessionDashboard
            this.setState({
                waitingRegistrationApproval: true,
                sessionToken: sessionToken,
            });
        });
    };

    handleLeaveSession = () => {
        const {timeSlot} = this.state;
        const sessionId = timeSlot?.session?._id;
        if (sessionId) {
            disconnectFromSession(sessionId, this.state.sessionToken);
            this.setState({
                sessionToken: null,
                waitingRegistrationApproval: false,
                sessionSelfPreview: false,
            });
            this.props.getLiveSessionSuccess(null);
            this.props.getLiveSessionUserSuccess(null);
        }
    };

    getSessionUserGuestProfile = () => {
        const {timeSlot} = this.state;
        const sessionId = timeSlot?.session._id;

        axios.get(`/sessions/${sessionId}/guest-profile`).then((response) => {
            const sessionUser = response.data.user;
            const sessionToken = response.data.sessionToken;

            connectToSession(sessionId, sessionToken);
            this.props.getLiveSession(sessionId);
            this.props.getLiveSessionUserSuccess(sessionUser);

            // we added the registration Request
            // we need to wait to be approved to enter the sessionDashboard
            this.setState({
                waitingRegistrationApproval: false,
                sessionToken: sessionToken,
            });
        });
    };

    updateAuditoriumResources = (resourcesData, callback) => {
        const {documents, links, description} = resourcesData;

        const hasResources = checkTimeslotHasResources({
            ...this.state.timeSlot,
            documents,
            links,
            description,
        });

        this.setState(
            (prevState) => ({
                hasResources,
                timeSlot: {...prevState.timeSlot, documents, links, description},
            }),
            callback(hasResources)
        );
    };

    handleLeaveAuditorium = () => {
        const {nextPath} = this.state;
        this.setState(
            {
                isLeaving: true,
                leaveAuditorium: false,
            },
            () => {
                this.handleLeaveSession();
                this.setAuditoriumTimeslots();
                this.props.history.push(nextPath);

                // this.props.history.push(nextPath);
                // window.location.replace(`${window.location.origin}/${nextPath}}`);
            }
        );
    };

    handleIsLeavingAuditorium = () => {
        this.setState({
            isLeaving: true,
        });
    };

    closeLeaveAuditoriumPopup = () => {
        this.setState({
            leaveAuditorium: false,
        });
    };

    handleToggleSessionSelfPreview = () => {
        this.setState({
            sessionSelfPreview: !this.state.sessionSelfPreview,
        });
    };

    render() {
        const {
            event,
            eventId,
            isLargeScreen,
            translation,
            defaultTranslation,
            isRtlLanguage,
            platformLanguage,
            hasOnDemandVideos,
            liveSessionUser,
            liveSessionActiveUsers,
            liveSession,
        } = this.props;
        const {
            timeSlot,
            auditoriumTimeslots,
            currentTimeSlotIndex,
            auditoriumRoomIndex,
            auditoriumHasVideos,
            totalSecondsTillCurrentTimeSlotEnds,
            totalSecondsTillNextVideo,
            auditoriumProgramHasEnded,
            currentTab,
            hasPolls,
            hasResources,
            waitingRegistrationApproval,
            leaveAuditorium,
        } = this.state;

        const auditoriums = event.auditoriums;
        const currentAuditorium = auditoriums[auditoriumRoomIndex];
        let hasAccessToCurrentAuditorium = this.getUserAuditoriumAccess();

        // timeslot has a YTB video URL or is a prerecorded video
        const timeslotHasVideo = timeSlot?.video || (timeSlot?.isLiveStreaming && timeSlot?.session?.link);

        // is a streaming video and not a prerecorded video
        const timeslotSessionLiveStreaming = timeSlot?.isLiveStreaming && timeSlot?.session && !timeSlot?.session?.link;

        const hideVideo = !isLargeScreen && currentTab !== 'video' && currentTab !== '';
        const sideContainerHidden = currentTab === '';
        const displaySideBar =
            (isLargeScreen && (timeSlot || hasOnDemandVideos)) ||
            (!isLargeScreen && currentTab !== '' && currentTab !== 'video');

        const liveStreamEnded = this.checkLiveStreamEnded();
        const liveStreamNotStarted = this.checkLiveStreamPendingToStart();

        return (
            <div onDragStart={preventDefaultDrag} className="position-background auditorium-page-wrapper">
                {totalSecondsTillCurrentTimeSlotEnds && hasAccessToCurrentAuditorium && (
                    <AuditoriumSnackbar
                        totalSecondsTillCurrentTimeSlotEnds={totalSecondsTillCurrentTimeSlotEnds}
                        loadCurrentTimeSlot={this.loadCurrentTimeSlot}
                        timeSlot={timeSlot}
                    />
                )}
                <div
                    id="js-auditorium-page"
                    className={`auditorium-page mobile-page-container ${
                        currentTab !== 'video' ? 'wall-tab-active' : ''
                    }`}
                >
                    {!isLargeScreen && (
                        <div onDragStart={preventDefaultDrag} className="page-title">
                            {translation?.roomsDropdown.auditoriumButton}
                        </div>
                    )}

                    {!isLargeScreen && (
                        <AuditoriumTabs
                            hasVideoWall={true}
                            hasPolls={hasPolls}
                            hasVideo={!!this.state.timeSlot?._id}
                            hasResources={hasResources}
                            currentTab={currentTab}
                            handleChangeTab={this.handleChangeTab}
                        />
                    )}

                    {/*SHOW BACKGROUND IMAGE ONLY ON LARGE SCREENS*/}
                    {isLargeScreen && <AuditoriumBackgroundImage brandingData={event.brandingData} />}

                    {hasAccessToCurrentAuditorium && timeSlot && isLargeScreen && (
                        <AuditoriumSlotTitle
                            auditoriumRoomIndex={auditoriumRoomIndex}
                            hide={this.props.event?.hideAuditoriumProgram}
                        />
                    )}

                    {auditoriums.length > 1 && !liveSessionUser && (
                        <AuditoriumsSlider
                            auditoriums={auditoriums}
                            activeAuditoriumIndex={auditoriumRoomIndex}
                            onSetActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
                            isRtlLanguage={isRtlLanguage}
                        />
                    )}

                    {liveSessionUser && liveSession && !liveStreamEnded && (
                        <AuditoriumCaptureControlsBar
                            handleLeaveSession={this.handleLeaveSession}
                            handleIsLeavingAuditorium={this.handleIsLeavingAuditorium}
                            handleToggleSessionSelfPreview={this.handleToggleSessionSelfPreview}
                            sessionSelfPreview={this.state.sessionSelfPreview}
                        />
                    )}

                    {displaySideBar && (
                        <div
                            className={`booth-wall-container ${sideContainerHidden ? 'hidden' : ''}`}
                            onDragStart={preventDefaultDrag}
                        >
                            <div
                                onDragStart={preventDefaultDrag}
                                className="booth-wall-header"
                                style={{justifyContent: 'center'}}
                            >
                                {timeSlot ? (
                                    <>
                                        <div
                                            onClick={this.handleChangeTab('wall')}
                                            className={`tab wall-tab ${
                                                checkPlatformLanguage(platformLanguage, ['es', 'pt']) ? 'pl-10 ' : ''
                                            } ${currentTab === 'wall' ? 'active' : ''}`}
                                        >
                                            <span>{translation?.auditorium.videoWallButton}</span>
                                        </div>
                                        {!event.hideAuditoriumNetworking && (
                                            <div
                                                onClick={this.handleChangeTab('networking')}
                                                className={`tab networking-tab ${
                                                    currentTab === 'networking' ? 'active' : ''
                                                }`}
                                            >
                                                <span>
                                                    {translation?.sideMenu.audience ||
                                                        defaultTranslation?.sideMenu.audience}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <span className="on-demand-title">{translation?.auditorium.onDemandButton}</span>
                                )}
                                {!timeSlot && currentTab === 'onDemand' ? (
                                    <div
                                        className="close-dropdown auditorium-sidebar-close-dropdown"
                                        onClick={this.handleChangeTab('')}
                                    >
                                        <GreaterArrow fill={colors.whiteVariant} />
                                    </div>
                                ) : (
                                    <AuditoriumSideButtons
                                        timeSlot={timeSlot}
                                        translation={translation}
                                        defaultTranslation={defaultTranslation}
                                        handleChangeTab={this.handleChangeTab}
                                        hasOnDemandVideos={hasOnDemandVideos}
                                        currentTab={currentTab}
                                        hasPolls={hasPolls}
                                        hasResources={hasResources}
                                    />
                                )}
                            </div>
                            {currentTab !== 'wall' && currentTab !== 'networking' && timeSlot && (
                                <div onDragStart={preventDefaultDrag} className="booth-wall-subheader">
                                    <BackIcon fill={colors.grey} onClick={this.handleChangeTab('wall')} />
                                    <span className="button-container-absolute">
                                        {currentTab === 'polls' &&
                                            (translation?.polls?.polls || defaultTranslation?.polls?.polls)}
                                        {currentTab === 'resources' &&
                                            (translation?.auditorium?.resourcesButton ||
                                                defaultTranslation?.auditorium?.resourcesButton)}
                                        {currentTab === 'onDemand' &&
                                            (translation?.auditorium?.onDemandButton ||
                                                defaultTranslation?.auditorium?.onDemandButton)}
                                    </span>
                                </div>
                            )}
                            {currentTab === 'wall' && timeSlot && <Wall isVideoWall />}
                            {currentTab === 'networking' && (
                                <AuditoriumNetworking
                                    timeSlotId={timeSlot?._id}
                                    auditoriumId={auditoriums[auditoriumRoomIndex]?._id}
                                />
                            )}
                            {currentTab === 'polls' && timeSlot && (
                                <AuditorumPollsOutput
                                    auditoriumId={auditoriums[auditoriumRoomIndex]._id}
                                    programId={timeSlot._id}
                                    pollsUpdate={this.pollsUpdated}
                                    translation={translation}
                                    isRtlLanguage={isRtlLanguage}
                                />
                            )}
                            {currentTab === 'resources' && timeSlot && <Resources currentTimeslot={timeSlot} />}
                            {currentTab === 'onDemand' && <OnDemandVideos currentTimeslot={timeSlot} />}
                        </div>
                    )}

                    <div onDragStart={preventDefaultDrag} className={`centerOfPage ${hideVideo ? 'hide' : ''}`}>
                        <div onDragStart={preventDefaultDrag} className="relativeCenter">
                            <AuditoriumBanners
                                filesUrl={
                                    auditoriums[auditoriumRoomIndex].stageBanner
                                        ? `${process.env.REACT_APP_EVENT_FOLDER}${eventId}/`
                                        : event.brandingData.filesUrl
                                }
                                image={
                                    auditoriums[auditoriumRoomIndex].stageBanner
                                        ? auditoriums[auditoriumRoomIndex].stageBanner
                                        : event.brandingData.lobbyBanner
                                }
                            />

                            {liveSessionUser?.isReady && (
                                <div className="videoWrapper video-plates-container">
                                    {liveSessionActiveUsers.map((activeUser, activeUserIndex) => {
                                        return (
                                            <UserVideoStream
                                                key={activeUser._id}
                                                activeUser={activeUser}
                                                activeUserIndex={activeUserIndex}
                                            />
                                        );
                                    })}
                                </div>
                            )}

                            {this.state.sessionSelfPreview && <SessionSelfPreview />}

                            {hasAccessToCurrentAuditorium ? (
                                <>
                                    {timeslotHasVideo || timeslotSessionLiveStreaming ? (
                                        <>
                                            {timeslotHasVideo && (
                                                <AuditoriumVideoPlayer
                                                    auditorium
                                                    timeSlot={timeSlot}
                                                    videoStreamingUrl={this.state.videoStreamingUrl}
                                                    videoStreamingStartAt={this.state.videoStreamingStartAt}
                                                    showFastForwardButtons={false}
                                                    showPlayPauseButtons={false}
                                                    loadCurrentTimeSlot={this.loadCurrentTimeSlot}
                                                    autoplayFix
                                                    hasOnDemandVideos
                                                    currentAuditorium={currentAuditorium}
                                                    liveStreamEnded={liveStreamEnded}
                                                />
                                            )}
                                            {timeslotSessionLiveStreaming && (
                                                <div
                                                    className={`videoWrapper ${
                                                        liveStreamEnded || liveStreamNotStarted ? 'video-ended' : ''
                                                    }`}
                                                >
                                                    {liveStreamEnded || liveStreamNotStarted ? (
                                                        <div
                                                            onDragStart={preventDefaultDrag}
                                                            className="image-ended-video"
                                                        >
                                                            <>
                                                                {liveStreamNotStarted ? (
                                                                    <p>
                                                                        {translation?.videoPlayer.videoStreamNotStarted}
                                                                    </p>
                                                                ) : (
                                                                    <>
                                                                        {timeSlot.imageUrl ? (
                                                                            <img
                                                                                draggable="false"
                                                                                onDragStart={preventDefaultDrag}
                                                                                src={timeSlot.imageUrl}
                                                                                alt="current program"
                                                                            />
                                                                        ) : (
                                                                            <p>
                                                                                {
                                                                                    translation?.videoPlayer
                                                                                        .videoEndedTextFirst
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    translation?.videoPlayer
                                                                                        .videoEndedTextSecond
                                                                                }
                                                                            </p>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {!liveSessionUser?.isReady && (
                                                                <VideoContainer
                                                                    translation={translation}
                                                                    defaultTranslation={defaultTranslation}
                                                                    session={timeSlot.session}
                                                                    className={'video-container'}
                                                                    interpretationSourceLanguage={
                                                                        currentAuditorium?.interpretationSourceLanguage
                                                                    }
                                                                    withInterpretation={
                                                                        currentAuditorium?.isInterpretationEnabled
                                                                    }
                                                                    isAuditoriumPlayer={true}
                                                                    handleRegisterSession={this.handleRegisterSession}
                                                                    waitingRegistrationApproval={
                                                                        waitingRegistrationApproval
                                                                    }
                                                                    // liveStreamEnded={this.setLiveStreamEnded}
                                                                    // liveStreamStarted={this.setLiveStreamStarted}
                                                                />
                                                            )}
                                                            {leaveAuditorium && (
                                                                <Confirm
                                                                    open={leaveAuditorium}
                                                                    closeConfirm={this.closeLeaveAuditoriumPopup}
                                                                    dialogTitle={
                                                                        translation?.auditorium.leavePopupTitle ||
                                                                        defaultTranslation?.auditorium.leavePopupTitle
                                                                    }
                                                                    dialogDescription={
                                                                        translation?.auditorium.leavePopupDescription ||
                                                                        defaultTranslation?.auditorium
                                                                            .leavePopupDescription
                                                                    }
                                                                    dialogConfirmButtonLabel={
                                                                        translation?.generalText.leave ||
                                                                        defaultTranslation?.generalText.leave
                                                                    }
                                                                    dialogCancelButtonLabel={
                                                                        translation?.generalText.cancel ||
                                                                        defaultTranslation?.generalText.cancel
                                                                    }
                                                                    handleConfirm={this.handleLeaveAuditorium}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <AuditoriumNextVideoTimer
                                            timeSlot={timeSlot}
                                            translation={translation}
                                            auditoriumTimeslots={auditoriumTimeslots}
                                            currentTimeSlotIndex={currentTimeSlotIndex}
                                            auditoriumProgramHasEnded={auditoriumProgramHasEnded}
                                            auditoriumHasVideos={auditoriumHasVideos}
                                            totalSecondsTillNextVideo={totalSecondsTillNextVideo}
                                            loadCurrentTimeSlot={this.loadCurrentTimeSlot}
                                        />
                                    )}
                                </>
                            ) : (
                                <div onDragStart={preventDefaultDrag} className="next-video">
                                    <div onDragStart={preventDefaultDrag} className="next-video-container restricted">
                                        <p>
                                            <span>{translation?.auditorium.noAccess}</span>
                                            <LockIcon />
                                        </p>
                                    </div>
                                </div>
                            )}
                            {auditoriums.length > 1 && isLargeScreen && (
                                <div onDragStart={preventDefaultDrag} className="videoWrapper">
                                    <div onDragStart={preventDefaultDrag} className="video-container">
                                        <AuditoriumNavigation
                                            videoPlayer
                                            timeSlot={timeSlot}
                                            setActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
                                            auditoriums={auditoriums}
                                            auditoriumRoomIndex={auditoriumRoomIndex}
                                        />
                                    </div>
                                </div>
                            )}
                            {!isLargeScreen && (
                                <div onDragStart={preventDefaultDrag} className="slot-mobile-details">
                                    <AuditoriumSlotTitle
                                        auditoriumRoomIndex={auditoriumRoomIndex}
                                        hide={this.props.event?.hideAuditoriumProgram}
                                    />
                                    {auditoriums.length > 1 && !isLargeScreen && (
                                        <AuditoriumNavigationMobile
                                            timeSlot={timeSlot}
                                            setActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
                                            auditoriums={auditoriums}
                                            auditoriumRoomIndex={auditoriumRoomIndex}
                                            timezoneName={event.timezoneName}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        eventSlug: state.event.eventSlug,
        event: state.event.data,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
        isMobile: state.layout.isMobile,
        isLargeScreen: state.layout.isLargeScreen,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        isRtlLanguage: state.languages.isRtlLanguage,
        platformLanguage: state.languages.platformLanguage,
        hasOnDemandVideos: state.onDemandVideos.hasOnDemandVideos,
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        liveSessionActiveUsers: state.liveSession.liveSessionActiveUsers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeSidebar: () => dispatch(actions.sideNavClose()),
        closeTopNavigation: () => dispatch(actions.topNavClose()),
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        setTimeSlotVideoWall: (wallId) => dispatch(actions.setTimeSlotVideoWall(wallId)),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        seeVideoWall: () => dispatch(actions.sideNavSeeVideoWall()),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
        onHasOnDemandVideos: (eventId) => dispatch(actions.hasOnDemandVideos(eventId)),
        onProgramUpdated: (programData) => dispatch(actions.programUpdated(programData)),
        getLiveSession: (sessionId) => dispatch(actions.getLiveSession(sessionId)),
        getLiveSessionSuccess: (session) => dispatch(actions.getLiveSessionSuccess(session)),
        getLiveSessionUser: (sessionId) => dispatch(actions.getLiveSessionUser(sessionId)),
        getLiveSessionUserSuccess: (user) => dispatch(actions.getLiveSessionUserSuccess(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auditorium);
