import React, {useEffect} from 'react';
import {Mic as MicIcon, MicOff as MicOffIcon} from '@material-ui/icons';

import axios from '../../../../../../store/axios-instance';

const VideoStreamButtonsMute = ({capture, user, session}) => {
    useEffect(() => {
        capture.mediaStream.getAudioTracks().forEach((track) => (track.enabled = user.audio));
    }, [capture, user.audio]);

    const toggleSound = async (isSound) => {
        try {
            const userToken = localStorage.sessionToken || localStorage.token;
            if (!userToken) return;

            const audioUrlParam = isSound ? 'unmute' : 'mute';

            await axios.post(`/sessions/${session._id}/${audioUrlParam}/${user.userId}`);
        } catch (err) {}
    };

    return (
        <>
            {user.audio && <MicIcon className="button" onClick={() => toggleSound(false)} />}
            {!user.audio && <MicOffIcon className="button" onClick={() => toggleSound(true)} />}
        </>
    );
};

export default VideoStreamButtonsMute;
