import React, {Component, createRef} from 'react';
import './SessionSelfPreview.scss';
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';


class SessionSelfPreview extends Component {

    micVolumeRef = createRef();

    state = {
        audioContext: null,
        mediaStreamSource: null,
        setIntervalId: null
    }

    componentDidMount() {
        const {liveSessionUser} = this.props;

        if(liveSessionUser.audio){
            this.startAudioProcesing();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // user started audio
        if(!prevProps.liveSessionUser.audio && this.props.liveSessionUser.audio){
            this.startAudioProcesing();
        }

        // user muted himself
        if(prevProps.liveSessionUser.audio && !this.props.liveSessionUser.audio){
            this.stopAudioProcesing();
        }

        // user stopped his stream
        if(prevProps.userMediaStreamCapture && !this.props.userMediaStreamCapture){
            this.stopAudioProcesing();
        }
    }

    componentWillUnmount() {
        // clear audioContext
        this.stopAudioProcesing();
    }

    startAudioProcesing = () => {
        const {userMediaStreamCapture} = this.props;

        const reactThis = this;

        const audioContext = new AudioContext();
        const mediaStreamSource = audioContext.createMediaStreamSource(userMediaStreamCapture?.mediaStream);

        const analyserNode = audioContext.createAnalyser();
        mediaStreamSource.connect(analyserNode);
        const pcmData = new Float32Array(analyserNode.fftSize);

        const onFrame = () => {
            analyserNode.getFloatTimeDomainData(pcmData);
            let sumSquares = 0.0;
            for (const amplitude of pcmData) { sumSquares += amplitude*amplitude; }
            // volumeMeterEl.value = Math.sqrt(sumSquares / pcmData.length);
            reactThis.updateMeter(Math.sqrt(sumSquares / pcmData.length) * 100);
        };
        const setIntervalId = setInterval(onFrame, 50);
        this.setState({
            setIntervalId: setIntervalId
        })
    }

    updateMeter = (pct) => {
        // const meter = document.getElementById('meter');
        const meter = this.micVolumeRef.current;
        if(meter){
            meter.style.width = pct + '%';
        }
    }

    stopAudioProcesing = () => {
        const {audioContext, mediaStreamSource, setIntervalId} = this.state;
        mediaStreamSource?.disconnect();
        if(audioContext){
            audioContext.close().then(()=>{
                // reset the width of the audio context to be 0
                this.updateMeter(0);
                this.setState({
                    audioContext: null,
                    mediaStreamSource: null,
                })
            })
        }
        if(setIntervalId){
            clearInterval(setIntervalId);
        }
    }

    render() {
        const {liveSessionUser} = this.props;
        return (
            <div className="mic-volume">
                {liveSessionUser.audio
                    ?<VolumeDownIcon/>
                    :<VolumeOffIcon/>
                }
                <div className="mic-input-volume">
                    <div id="meter" ref={this.micVolumeRef}></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        userMediaStreamCapture: state.userMediaStream.capture,
        userMediaStreamSelectedAudioDeviceId: state.userMediaStream.selectedAudioDeviceId,
        userMediaStreamSelectedVideoDeviceId: state.userMediaStream.selectedVideoDeviceId,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserMediaStreamCapture: (capture) => dispatch(actions.setUserMediaStreamCapture(capture)),
        setUserMediaStreamAudioDeviceId: (deviceId) => dispatch(actions.setUserMediaStreamAudioDeviceId(deviceId)),
        setUserMediaStreamVideoDeviceId: (deviceId) => dispatch(actions.setUserMediaStreamVideoDeviceId(deviceId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionSelfPreview);