import React from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import "../CSS/dialogs.scss";
import { withRouter } from "react-router-dom";
import { preventDefaultDrag } from "../Utils/utils";

class OrganizerNavigation extends React.Component {
	state = {
		links: [
			{ path: `dashboard/settings`, label: "Setup", isVisible: false },
			{ path: `dashboard/branding`, label: "Event Info", isVisible: false },
			{
				path: `dashboard/buildings`,
				label: "Buildings",
				isVisible: this.props.event.hasVillage,
			},
			{ path: `dashboard/auditoriums`, label: "Auditoriums", isVisible: false },
			{ path: `dashboard/event-sessions`, label: "Sessions", isVisible: false },
			{ path: `dashboard/program`, label: "Program", isVisible: false },
			{ path: `dashboard/showfloor`, label: "Exhibit Halls", isVisible: false },
			{ path: `dashboard/participants`, label: "Users", isVisible: false },
			{ path: `dashboard/event-payment`, label: "Access", isVisible: false },
			{ path: `dashboard/statistics`, label: "Statistics", isVisible: false },
			{
				path: `dashboard/communication`,
				label: "Communication",
				isVisible: false,
			},
			{ path: `dashboard/archived-events`, label: "Archive", isVisible: false },
			{ path: `dashboard/event-gdpr`, label: "LEGAL", isVisible: false },
		],
	};

	componentDidMount() {
		const { user, eventSlug } = this.props;
		const userRolesInEvent = user.eventRoles.find(
			(role) => role.event.slug === eventSlug,
		);
		const userIsCoOrganizer =
			userRolesInEvent && userRolesInEvent?.roles?.includes("coOrganizer");
		document.body.classList.add("sticky-menu");
		// set links visibility based on the Co-Organizer accessRights for
		// the user that has Co-Organizer role using userIsCoOrganizer
		// instead of eventRoles.isCoOrganizer because the latter has a delay
		// else set them for Organizer
		if (userIsCoOrganizer) {
			this.setCoOrganizerAccess();
		} else {
			this.setOrganizerAccess();
		}
	}

	componentDidUpdate(prevProps) {
		const { user, event, eventRoles } = this.props;
		// update links visibility whenever Co-Organizer eventRoles / accessRights change
		// but only for the user that has Co-Organizer role
		if (
			prevProps.user.eventRoles !== user.eventRoles &&
			eventRoles.isCoOrganizer
		) {
			this.setCoOrganizerAccess();
		}
		if (
			prevProps.event.sessionsStreaming !== event.sessionsStreaming &&
			eventRoles.isCoOrganizer
		) {
			this.setCoOrganizerAccess();
		}
		if (
			prevProps.event.statisticsEnabled !== event.statisticsEnabled &&
			eventRoles.isCoOrganizer
		) {
			this.setCoOrganizerAccess();
		}

		// display the Building Link if the event hasVillage
		// hide the Auditoriums and Program Links if the event hasVillage
		if (prevProps.event.hasVillage !== event.hasVillage) {
			let updatedLinks = [...this.state.links];

			let buildingsLinkIndex = updatedLinks.findIndex(
				(link) => link.label === "Buildings",
			);
			updatedLinks[buildingsLinkIndex].isVisible = event.hasVillage;
			let auditoriumsLinkIndex = updatedLinks.findIndex(
				(link) => link.label === "Auditorium",
			);
			updatedLinks[auditoriumsLinkIndex].isVisible = !event.hasVillage;
			let programLinkIndex = updatedLinks.findIndex(
				(link) => link.label === "Program",
			);
			updatedLinks[programLinkIndex].isVisible = !event.hasVillage;
			this.setState({ links: updatedLinks });
		}

		// display the Sessions Link if the event has sessions enabled
		if (prevProps.event.sessionsStreaming !== event.sessionsStreaming) {
			let updatedLinks = [...this.state.links];
			let sessionsLinkIndex = updatedLinks.findIndex(
				(link) => link.label === "Sessions",
			);
			updatedLinks[sessionsLinkIndex].isVisible = event.sessionsStreaming;
			this.setState({ links: updatedLinks });
		}

		// display the Statistics Link if the event has sessions enabled
		if (prevProps.event.statisticsEnabled !== event.statisticsEnabled) {
			let updatedLinks = [...this.state.links];
			let statisticsLinkIndex = updatedLinks.findIndex(
				(link) => link.label === "Statistics",
			);
			updatedLinks[statisticsLinkIndex].isVisible = event.statisticsEnabled;
			this.setState({ links: updatedLinks });
		}
	}

	componentWillUnmount() {
		document.body.classList.remove("sticky-menu");
	}

	setOrganizerAccess = () => {
		const { links } = this.state;
		const { event } = this.props;
		let linksToUpdate = [...links];
		const setupLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Setup",
		);
		const brandingLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Event Info",
		);
		const auditoriumsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Auditoriums",
		);
		const programLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Program",
		);
		const showfloorsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Exhibit Halls",
		);
		const usersLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Users",
		);
		const statisticsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Statistics",
		);
		const communicationLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Communication",
		);
		const archiveLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Archive",
		);
		const sessionsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Sessions",
		);
		const gdprLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "LEGAL",
		);
		const accessLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Access",
		);

		linksToUpdate[setupLinkIndex].isVisible = true;
		linksToUpdate[brandingLinkIndex].isVisible = true;
		linksToUpdate[auditoriumsLinkIndex].isVisible = !event.hasVillage;
		linksToUpdate[programLinkIndex].isVisible = !event.hasVillage;
		linksToUpdate[showfloorsLinkIndex].isVisible = true;
		linksToUpdate[usersLinkIndex].isVisible = true;
		linksToUpdate[statisticsLinkIndex].isVisible = event.statisticsEnabled;
		linksToUpdate[communicationLinkIndex].isVisible = true;
		linksToUpdate[archiveLinkIndex].isVisible = true;
		linksToUpdate[sessionsLinkIndex].isVisible = event.sessionsStreaming;
		linksToUpdate[gdprLinkIndex].isVisible = true;
		linksToUpdate[accessLinkIndex].isVisible = true;

		this.setState({ links: linksToUpdate });
	};

	setCoOrganizerAccess = () => {
		const { links } = this.state;
		const { user, event, eventSlug } = this.props;
		const userRolesInEvent = user.eventRoles.find(
			(role) => role.event.slug === eventSlug,
		);
		let linksToUpdate = [...links];
		const setupLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Setup",
		);
		const brandingLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Event Info",
		);
		const auditoriumsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Auditoriums",
		);
		const programLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Program",
		);
		const showfloorsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Exhibit Halls",
		);
		const usersLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Users",
		);
		const statisticsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Statistics",
		);
		const communicationLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Communication",
		);
		const archiveLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Archive",
		);
		const sessionsLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Sessions",
		);
		const gdprLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "LEGAL",
		);
		const accessLinkIndex = linksToUpdate.findIndex(
			(link) => link.label === "Access",
		);

		linksToUpdate[setupLinkIndex].isVisible =
			userRolesInEvent?.accessRights.setup;
		linksToUpdate[brandingLinkIndex].isVisible =
			userRolesInEvent?.accessRights.branding;
		linksToUpdate[auditoriumsLinkIndex].isVisible =
			userRolesInEvent?.accessRights.auditoriums && !event.hasVillage;
		linksToUpdate[programLinkIndex].isVisible =
			userRolesInEvent?.accessRights.program && !event.hasVillage;
		linksToUpdate[showfloorsLinkIndex].isVisible =
			userRolesInEvent?.accessRights.showfloors;
		linksToUpdate[usersLinkIndex].isVisible =
			userRolesInEvent?.accessRights.users;
		linksToUpdate[statisticsLinkIndex].isVisible =
			userRolesInEvent?.accessRights.statistics && event.statisticsEnabled;
		linksToUpdate[communicationLinkIndex].isVisible =
			userRolesInEvent?.accessRights.communication;
		linksToUpdate[archiveLinkIndex].isVisible =
			userRolesInEvent?.accessRights.archive;
		linksToUpdate[sessionsLinkIndex].isVisible =
			userRolesInEvent?.accessRights.sessions && event.sessionsStreaming;
		linksToUpdate[gdprLinkIndex].isVisible =
			userRolesInEvent?.accessRights.gdpr;
		linksToUpdate[accessLinkIndex].isVisible =
			userRolesInEvent?.accessRights.accessManagement;

		this.setState({ links: linksToUpdate });
	};

	render() {
		const { links } = this.state;
		const { eventSlug } = this.props;
		return (
			<div
				onDragStart={preventDefaultDrag}
				className="organizer-navigation-container"
			>
				<div onDragStart={preventDefaultDrag} className="dashboard-navigation">
					{links
						.filter((link) => link.isVisible)
						.map((link) => {
							const path = `/event/${eventSlug}/${link.path}`;
							return (
								<NavLink
									to={{ pathname: path }}
									activeClassName="active"
									key={link.label}
									data-is-navigation
								>
									<Button
										tabIndex="-1"
										disableRipple
										variant={"text"}
										size={"small"}
									>
										{link.label}
									</Button>
								</NavLink>
							);
						})}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.data,
		event: state.event.data,
		eventId: state.event.eventId,
		eventSlug: state.event.eventSlug,
		eventRoles: state.user.eventRoles,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(OrganizerNavigation),
);
