import {
    IMPORT_AUDITORIUM_PROGRAM_REQUEST,
    IMPORT_AUDITORIUM_PROGRAM_SUCCESS,
    IMPORT_AUDITORIUM_PROGRAM_FAIL,
    IMPORT_AUDITORIUM_PROGRAM_RESET,
} from '../actions/actionTypes';

export const importProgramReducer = (state = {importedData: {}}, action) => {
    switch (action.type) {
        case IMPORT_AUDITORIUM_PROGRAM_REQUEST:
            return {loading: true, success: false, importedData: {}};
        case IMPORT_AUDITORIUM_PROGRAM_SUCCESS:
            return {
                loading: false,
                success: true,
            };
        case IMPORT_AUDITORIUM_PROGRAM_FAIL:
            return {loading: false, success: false, error: action.payload};
        case IMPORT_AUDITORIUM_PROGRAM_RESET:
            return {success: false};
        default:
            return state;
    }
};
