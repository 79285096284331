import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    isAuditoriumMuted: false,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.SET_AUDITORIUM_MUTED:
                draft.isAuditoriumMuted = true;
                break;

            case actionTypes.SET_AUDITORIUM_UNMUTED:
                draft.isAuditoriumMuted = false;
                break;

            default:
                break;
        }
    });

export default reducer;
