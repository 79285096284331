import * as actionTypes from './actionTypes';
import axios from '../axios-instance';
import {setEventLanguage} from './userActions';
import {getUsers} from './eventUsersActions';

export const createEvent = (eventData) => {
    return (dispatch) => {
        dispatch(createEventStart());

        return axios({method: 'post', url: `/event`, data: eventData})
            .then((response) => {
                let data = response.data.event;
                dispatch(createEventSuccess(data));
            })
            .catch((error) => {
                dispatch(createEventFail(error));
            });
    };
};

export const createEventStart = () => {
    return {
        type: actionTypes.CREATE_EVENT_START,
    };
};
export const createEventSuccess = (event) => {
    return {
        type: actionTypes.CREATE_EVENT_SUCCESS,
        payload: event,
    };
};
export const createEventFail = () => {
    return {
        type: actionTypes.CREATE_EVENT_FAIL,
    };
};

export const setEventId = (eventId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_EVENT_ID,
            payload: eventId,
        });
    };
};

export const setEventSlug = (eventSlug) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_EVENT_SLUG,
            payload: eventSlug,
        });
    };
};

export const setEventIdBySlug = (eventSlug) => {
    return (dispatch) => {
        dispatch(setEventIdBySlugStart());

        return axios({method: 'get', url: `/event-slug/${eventSlug}`})
            .then((response) => {
                const eventId = response.data.event._id;
                dispatch(setEventIdBySlugSuccess(eventId));
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 404) {
                    dispatch(setEventNotFound(error));
                }
                dispatch(setEventIdBySlugFail(error));
            });
    };
};

export const setEventIdBySlugStart = () => {
    return {
        type: actionTypes.SET_EVENT_ID_BY_SLUG_START,
    };
};
export const setEventIdBySlugSuccess = (eventId) => {
    return {
        type: actionTypes.SET_EVENT_ID_BY_SLUG_SUCCESS,
        payload: eventId,
    };
};
export const setEventIdBySlugFail = () => {
    return {
        type: actionTypes.SET_EVENT_ID_BY_SLUG_FAIL,
    };
};
export const setEventNotFound = () => {
    return {
        type: actionTypes.SET_EVENT_NOT_FOUND,
    };
};
export const registerParticipant = (eventId, user) => {
    return (dispatch) => {
        dispatch(registerParticipantStart());

        return axios({method: 'post', url: `/event/v2/${eventId}/register-participant`, data: user})
            .then((response) => {
                let data = response.data;
                dispatch(registerParticipantSuccess(data));
            })
            .catch((error) => {
                dispatch(registerParticipantFail(error.response.data.error));
                throw error;
            });
    };
};

export const registerParticipantStart = () => {
    return {
        type: actionTypes.REGISTER_PARTICIPANT_START,
    };
};
export const registerParticipantSuccess = (participant) => {
    return {
        type: actionTypes.REGISTER_PARTICIPANT_SUCCESS,
        payload: participant,
    };
};
export const registerParticipantFail = (error) => {
    return {
        type: actionTypes.REGISTER_PARTICIPANT_FAIL,
        payload: error,
    };
};

export const registerExhibitor = (eventId, user) => {
    return (dispatch) => {
        dispatch(registerExhibitorStart());

        return axios({method: 'post', url: `/event/v2/${eventId}/register-exhibitor`, data: user})
            .then((response) => {
                let data = response.data;
                dispatch(registerExhibitorSuccess(data));
            })
            .catch((error) => {
                dispatch(registerExhibitorFail(error.response.data.error));
                throw error;
            });
    };
};

export const registerExhibitorStart = () => {
    return {
        type: actionTypes.REGISTER_EXHIBITOR_START,
    };
};
export const registerExhibitorSuccess = (exhibitor) => {
    return {
        type: actionTypes.REGISTER_EXHIBITOR_SUCCESS,
        payload: exhibitor,
    };
};
export const registerExhibitorFail = (error) => {
    return {
        type: actionTypes.REGISTER_EXHIBITOR_FAIL,
        payload: error,
    };
};

// reload eventData is the same API call as the normal getEvent api call
// the only difference is that we dont also immediately getUsers
// reloadEventData is only triggered from the Menu.js component
// when I get the socketEvent notification reloadEventData
export const reloadEventData = (eventId) => {
    return (dispatch) => {
        dispatch(getEventStart());
        return axios({method: 'get', url: `/event/v2/${eventId}`})
            .then((response) => {
                let event = response.data.data.event;
                dispatch(getEventSuccess(event));
            })
            .catch((error) => {
                dispatch(getEventFail(error));
            });
    };
};

export const getEvent = (eventId) => {
    return (dispatch) => {
        dispatch(getEventStart());

        return axios({method: 'get', url: `/event/v2/${eventId}`})
            .then((response) => {
                const event = response.data.data.event;
                dispatch(getUsers(event._id));
                dispatch(getEventSuccess(event));
            })
            .catch((error) => {
                dispatch(getEventFail(error));
            });
    };
};

export const getEventStart = () => {
    return {
        type: actionTypes.GET_EVENT_START,
    };
};
export const getEventSuccess = (event) => {
    return {
        type: actionTypes.GET_EVENT_SUCCESS,
        payload: event,
    };
};
export const getEventFail = () => {
    return {
        type: actionTypes.GET_EVENT_FAIL,
    };
};

export const getEventInfo = (eventId) => {
    return (dispatch) => {
        dispatch(getEventInfoStart());

        axios({method: 'get', url: `/event-info/v2/${eventId}`})
            .then((response) => {
                let eventInfo = response.data.data.event;
                let defaultEventLanguage = eventInfo.language;
                dispatch(setEventLanguage(defaultEventLanguage));
                dispatch(getEventInfoSuccess(eventInfo));
            })
            .catch((error) => {
                dispatch(getEventInfoFail(error));
            });
    };
};

export const getEventInfoStart = () => {
    return {
        type: actionTypes.GET_EVENT_INFO_START,
    };
};
export const getEventInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_EVENT_INFO_SUCCESS,
        payload: data,
    };
};
export const getEventInfoFail = () => {
    return {
        type: actionTypes.GET_EVENT_INFO_FAIL,
    };
};

export const eventSettingUpdated = (eventSettingData) => {
    return {
        type: actionTypes.EVENT_SETTING_UPDATED,
        payload: eventSettingData,
    };
};

export const programUpdated = (programData) => {
    return {
        type: actionTypes.SOCKET_PROGRAM_UPDATED,
        payload: programData,
    };
};

export const sideNavHasVideoWall = (booleanValue) => {
    return {
        type: actionTypes.SIDE_NAV_HAS_VIDEO_WALL,
        payload: booleanValue,
    };
};

export const setActiveWall = (wallData) => {
    return {
        type: actionTypes.SET_ACTIVE_WALL,
        payload: wallData,
    };
};

export const setTimeSlotVideoWall = (wallData) => {
    return {
        type: actionTypes.SET_TIME_SLOT_VIDEO_WALL_ID,
        payload: wallData,
    };
};

export const newFlashNotification = (flashNotification) => {
    return {
        type: actionTypes.NEW_FLASH_NOTIFICATION,
        payload: flashNotification,
    };
};

export const closeFlashNotification = () => {
    return {
        type: actionTypes.CLOSE_FLASH_NOTIFICATION,
    };
};

export const updateSingleAuditoriumStart = (auditoriumData) => {
    return {
        type: actionTypes.UPDATE_SINGLE_AUDITORIUM_START,
    };
};

export const updateSingleAuditoriumSuccess = (auditoriumData) => {
    return {
        type: actionTypes.UPDATE_SINGLE_AUDITORIUM_SUCCESS,
        payload: auditoriumData,
    };
};

export const updateSingleAuditoriumFail = (error) => {
    return {
        type: actionTypes.UPDATE_SINGLE_AUDITORIUM_FAIL,
        payload: error,
    };
};

export const updateAuditoriumResources = (data) => {
    return {
        type: actionTypes.UPDATE_AUDITORIUM_RESOURCES,
        payload: data,
    };
};

export const updateTimeslotSession = (data) => {
    return {
        type: actionTypes.UPDATE_TIMESLOT_SESSION,
        payload: data,
    };
};

export const setEnableMatching = (data) => {
    return {
        type: actionTypes.SET_ENABLE_MATCHING,
        payload: data,
    };
};

export const toggleMatchingDialog = (data) => {
    return {
        type: actionTypes.TOGGLE_MATCHING_DIALOG,
        payload: data,
    };
};

export const requestCloseMatchingBanner = (data) => {
    return {
        type: actionTypes.CLOSE_MATCHING_BANNER,
        payload: data,
    };
};

export const getEventRegistrationFields = (eventId) => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_EVENT_REGISTRATION_FIELDS_START});
        return axios({method: 'get', url: `/event/${eventId}/registration-fields`})
            .then((response) => {
                let eventRegistrationFields = response.data.event;
                dispatch(getEventRegistrationFieldsSuccess(eventRegistrationFields));
            })
            .catch((error) => {
                dispatch({type: actionTypes.GET_EVENT_REGISTRATION_FIELDS_FAIL, payload: error?.message});
            });
    };
};

export const getEventRegistrationFieldsSuccess = (eventRegistrationFields) => {
    return {
        type: actionTypes.GET_EVENT_REGISTRATION_FIELDS_SUCCESS,
        payload: eventRegistrationFields,
    };
};

export const getEventBranding = (eventId) => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_EVENT_BRANDING_START});
        return axios({method: 'get', url: `/event/${eventId}/branding`})
            .then((response) => {
                let brandingData = response.data.event?.brandingData || {};
                dispatch(getEventBrandingSuccess(brandingData));
            })
            .catch((error) => {
                dispatch({type: actionTypes.GET_EVENT_BRANDING_FAIL, payload: error?.message});
            });
    };
};

export const getEventBrandingSuccess = (brandingData) => {
    return {
        type: actionTypes.GET_EVENT_BRANDING_SUCCESS,
        payload: brandingData,
    };
};

export const getEventBrandingTranslations = (eventId) => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_START});
        return axios({method: 'get', url: `/event/${eventId}/branding-translations`})
            .then((response) => {
                let brandingTranslationsData = response.data.event?.brandingTranslations || [];
                dispatch(getEventBrandingTranslationsSuccess(brandingTranslationsData));
            })
            .catch((error) => {
                dispatch({type: actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_FAIL, payload: error?.message});
            });
    };
};

export const getEventBrandingTranslationsSuccess = (brandingTranslationsData) => {
    return {
        type: actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_SUCCESS,
        payload: brandingTranslationsData,
    };
};

export const updateEventBrandingTranslations = (eventId, brandingTranslationData) => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_START});
        return axios({
            method: 'post',
            url: `/event/v2/${eventId}/edit-branding-translation`,
            data: brandingTranslationData,
        })
            .then((response) => {
                const brandingTranslationsData = response.data.data.brandingTranslations.brandingTranslations || [];
                dispatch(getEventBrandingTranslationsSuccess(brandingTranslationsData));
            })
            .catch((error) => {
                dispatch({type: actionTypes.GET_EVENT_BRANDING_TRANSLATIONS_FAIL, payload: error?.message});
            });
    };
};

export const saveClosedDoorsInfo = (eventId, data) => {
    return (dispatch) => {
        dispatch({type: actionTypes.GET_EVENT_BRANDING_START});
        return axios({method: 'post', url: `/event/${eventId}/closed-doors-info`, data: data})
            .then((response) => {
                const brandingData = response.data.brandingData;
                dispatch(getEventBrandingSuccess(brandingData));
            })
            .catch((error) => {
                dispatch({type: actionTypes.GET_EVENT_BRANDING_FAIL, payload: error?.message});
            });
    };
};
