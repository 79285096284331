import React from 'react';
import {Dialog, DialogContent} from '@material-ui/core';

const KickPopup = ({currentUser, invitation}) => {
    const {kicked, status} = currentUser;

    return (
        <Dialog open={kicked && !invitation}>
            <DialogContent>
                {status === 'new' && <p>Your join request has been declined!</p>}
                {status === 'approved' && <p>A moderator has removed you from this Session!</p>}
            </DialogContent>
        </Dialog>
    );
};

export default KickPopup;
