import axios from '../axios-instance';
import {
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_REQUEST,
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_SUCCESS,
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_FAIL,
    IMPORT_AUDITORIUM_TIMESLOT_IMPORT_RESET,
} from './actionTypes';

export const importAuditoriumProgramFromXLS = (formData, auditoriumId, eventId) => async (dispatch) => {
    try {
        dispatch({type: IMPORT_AUDITORIUM_TIMESLOT_IMPORT_REQUEST});
        //POSTMAN url - below
        //{{url}}/v1/event/:eventId/auditoriums/:auditoriumId/import
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        await axios.post(`/event/${eventId}/auditoriums/${auditoriumId}/import`, formData, config);
        dispatch({
            type: IMPORT_AUDITORIUM_TIMESLOT_IMPORT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: IMPORT_AUDITORIUM_TIMESLOT_IMPORT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};
export const importAuditoriumProgramFromXLSReset = (formData) => async (dispatch) => {
    try {
        dispatch({type: IMPORT_AUDITORIUM_TIMESLOT_IMPORT_RESET});
    } catch (error) {
        dispatch({
            type: IMPORT_AUDITORIUM_TIMESLOT_IMPORT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};
