import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import SortIcon from '@material-ui/icons/Sort';

export default function SortMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const textButton = props.text;
    const hasImage = props.image;
    const isArrowDown = props.arrowDown;
    const isSortIcon = props.sortIcon;
    const sortBy = props.sortBy;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (sortBy, order) => () => {
        setAnchorEl(null);
        if (order) {
            props.sortArray(sortBy, order);
        }
    };

    return (
        <div>
            <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                {textButton}
                {hasImage ? (
                    <>
                        {isArrowDown && <ExpandMoreRoundedIcon />}
                        {isSortIcon && <SortIcon />}
                    </>
                ) : null}
            </Button>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose(null)}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={handleClose(sortBy, 'asc')}>Ascendant</MenuItem>
                <MenuItem onClick={handleClose(sortBy, 'desc')}>Descendant</MenuItem>
            </Menu>
        </div>
    );
}
