import React, {useState, useContext, useEffect, useRef, createContext} from 'react';
import {useSelector} from 'react-redux';

import {userStatuses as userStatusesApi} from '../Api/event10x';
import {handleUserStatusUpdate, changeUserStatusToBusy, changeUserStatusToOnline} from '../Api/socketApi';
import {userStatuses} from '../data';

// Helpers
const formPlatformLevelUserIdList = (user) => {
    if (!user) {
        return [];
    }

    const privateChatUserIdList =
        user?.privateChats
            .map(({chat}) => [chat.user_1, chat.user_2])
            .flat()
            .map(({_id}) => _id) || [];

    const groupChatUserIdList =
        user?.groupChats
            .map(({chat}) => chat.users)
            .flat()
            .map(({_id}) => _id) || [];

    const contactUserIdList = user?.contacts.map(({user}) => user?._id) || [];

    return [...privateChatUserIdList, ...groupChatUserIdList, ...contactUserIdList];
};

const formEventLevelUserIdList = (event) => {
    if (!event) {
        return [];
    }

    const participantIdList = event?.participants.map(({user}) => user._id) || [];
    const exhibitorIdList = event?.exhibitors.map(({user}) => user._id) || [];

    return [...participantIdList, ...exhibitorIdList, event?.owner._id];
};

const normalizeUserStatuseList = (userStatuseList, status) =>
    Object.fromEntries(userStatuseList.map(({userId}) => [userId, status]));

const getRelatedUsersStatuses = (allStatuses, relatedUserIdList) => {
    const statuses = {};
    relatedUserIdList.forEach((id) => (statuses[id] = allStatuses[id] || userStatuses.offline));
    return statuses;
};

const useDataFromRedux = () => {
    const user = useSelector((state) => state?.user?.data);
    const event = useSelector((state) => state?.event?.data);
    const isVideoConferenceActive = useSelector((state) => state.videoConference.isActive);
    const userId = user?._id;

    const platformLevelUserIdList = formPlatformLevelUserIdList(user, userId);
    const eventLevelUserIdList = formEventLevelUserIdList(event, user?._id);
    const relatedUserIdList = [...platformLevelUserIdList, ...eventLevelUserIdList];
    const relatedUserIdListWithoutCurrentUserId = relatedUserIdList.filter((id) => id !== userId);
    const uniqRelatedUserIdList = [...new Set(relatedUserIdListWithoutCurrentUserId)];

    return {userId: user?._id, eventId: event?._id, relatedUserIdList: uniqRelatedUserIdList, isVideoConferenceActive};
};

// Hook
const useHook = () => {
    const [statuses, setStatuses] = useState({});
    const {userId, eventId, relatedUserIdList, isVideoConferenceActive} = useDataFromRedux();

    // Refs
    const isFirstRender = useRef(true);

    const relatedUserIdListRef = useRef(relatedUserIdList);
    relatedUserIdListRef.current = relatedUserIdList;

    const statusesRef = useRef(statuses);
    statusesRef.current = statuses;

    const {requestOnlineUsers, requestBusyUsers} = userStatusesApi();

    const requestAllUsersStatuses = () => {
        Promise.all([requestOnlineUsers(), requestBusyUsers()])
            .then(([onlineData, busyData]) =>
                setStatuses(
                    getRelatedUsersStatuses(
                        {
                            ...normalizeUserStatuseList(onlineData.data.users, userStatuses.online),
                            ...normalizeUserStatuseList(busyData.data.users, userStatuses.busy),
                        },
                        relatedUserIdList
                    )
                )
            )
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        if (!userId || !eventId) {
            return;
        }

        requestAllUsersStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, eventId]);

    useEffect(() => {
        handleUserStatusUpdate(({id, status}) => {
            if (relatedUserIdListRef.current.includes(id)) {
                setStatuses({...statusesRef.current, [id]: status});
            }
        });
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (isVideoConferenceActive) {
            changeUserStatusToBusy(userId);
            return;
        }

        changeUserStatusToOnline(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVideoConferenceActive]);

    return {
        getUserStatus: (userId) => statuses[userId] || userStatuses.offline,
    };
};

const UsersStatusesContext = createContext();

export {UsersStatusesContext};
export const useUsersStatuses = () => useContext(UsersStatusesContext);
export const UsersStatusesStore = ({children}) => (
    <UsersStatusesContext.Provider value={useHook()}>{children}</UsersStatusesContext.Provider>
);
