import React, {Component} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {Link} from 'react-router-dom';

class AdminAccordionNavigation extends Component {
    render() {
        const {tabsList, selectedTab} = this.props;
        return (
            <div>
                {tabsList.map((tab) => {
                    return (
                        <Link key={tab.name} to={{pathname: tab.link}}>
                            <Accordion
                                expanded={selectedTab === tab.name}
                                className={'accordion ' + (selectedTab === tab.name ? 'active' : '')}
                            >
                                <AccordionSummary className="summary">
                                    <p>{tab.label}</p>
                                </AccordionSummary>
                            </Accordion>
                        </Link>
                    );
                })}
            </div>
        );
    }
}

export default AdminAccordionNavigation;
