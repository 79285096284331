export const initialState = {
    sessionsList: {
        isLoading: false,
        sessions: [],
    },
    sessionDashboard: {
        isLoading: false,
        userStatus: null,
        profile: {},
        session: null,
        updatedUser: null,
        videoConference: {
            isActive: false,
            isLoading: false,
            isCalling: false,
            isScreenShared: false,
            isFullScreen: false,
            capture: null,
            selfPlayback: null,
            playbacks: [],
        },
    },
    zoomSessionDashboard: {
        isLoading: false,
        userStatus: null,
        profile: {},
        session: null,
        usersLists: {
            speakerList: [],
            moderatorsList: [],
        },
        updatedUser: null,
        videoConference: {
            isActive: false,
            isLoading: false,
            isCalling: false,
            isScreenShared: false,
            isFullScreen: false,
            capture: null,
            selfPlayback: null,
            playbacks: [],
        },
    },
};
