import React from 'react';
import {connect} from 'react-redux';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {withRouter, Link} from 'react-router-dom';
import {preventDefaultDrag} from '../Utils/utils';

const initialBreadCrumbLinks = {
    showEventAuditoriumArchive: false,
    showEventArchive: false,
    showVillage: false,
    showBuildingLobby: false,
    showBuildingAuditoriumArchive: false,
};

class BreadcrumbsNavigation extends React.Component {
    state = {
        currentPage: 'mainLobby',
        currentPageLabel: 'Main Lobby',
        buildingRoot: '',
        mainLobbyLink: '',
        eventAuditoriumArchiveLink: '',
        eventAuditoriumArchiveTimeslotLink: '',
        eventArchiveLink: '',
        eventArchiveTimeslotLink: '',
        villageLink: '',
        buildingLobbyLink: '',
        buildingAuditoriumArchiveLink: '',
        buildingAuditoriumArchiveTimeslotLink: '',
        breadcrumbLinks: {...initialBreadCrumbLinks},
    };

    componentDidMount() {
        this.setLinks();
    }

    setLinks = () => {
        const {eventSlug, buildingId} = this.props;

        const eventRoot = `/event/${eventSlug}`;
        const mainLobbyLink = `${eventRoot}/lobby`;
        const eventAuditoriumArchiveLink = `${eventRoot}/auditorium-archive`;
        const eventAuditoriumArchiveTimeslotLink = `${eventRoot}/auditorium-archive/timeslot`;
        const eventArchiveLink = `${eventRoot}/event-archive`;
        const eventArchiveTimeslotLink = `${eventRoot}/event-archive/event`;
        const villageLink = `${eventRoot}/village`;

        const buildingRoot = `${eventRoot}/village/building/${buildingId}`;
        const buildingLobbyLink = `${buildingRoot}/lobby`;
        const buildingAuditoriumArchiveLink = `${buildingRoot}/auditorium-archive`;
        const buildingAuditoriumArchiveTimeslotLink = `${buildingRoot}/auditorium-archive/timeslot`;

        this.setState(
            {
                eventRoot: eventRoot,
                buildingRoot: buildingRoot,
                mainLobbyLink: mainLobbyLink,
                eventAuditoriumArchiveLink: eventAuditoriumArchiveLink,
                eventAuditoriumArchiveTimeslotLink: eventAuditoriumArchiveTimeslotLink,
                eventArchiveLink: eventArchiveLink,
                eventArchiveTimeslotLink: eventArchiveTimeslotLink,
                villageLink: villageLink,
                buildingLobbyLink: buildingLobbyLink,
                buildingAuditoriumArchiveLink: buildingAuditoriumArchiveLink,
                buildingAuditoriumArchiveTimeslotLink: buildingAuditoriumArchiveTimeslotLink,
            },
            this.setPageType
        );
    };

    setPageType = () => {
        const currentPathname = this.props.history?.location?.pathname;

        const {
            eventRoot,
            buildingRoot,
            eventAuditoriumArchiveLink,
            eventAuditoriumArchiveTimeslotLink,
            eventArchiveLink,
            eventArchiveTimeslotLink,
            villageLink,
            buildingLobbyLink,
            buildingAuditoriumArchiveLink,
            buildingAuditoriumArchiveTimeslotLink,
        } = this.state;

        let currentPage = 'mainLobby';
        let currentPageLabel = 'Main Lobby';
        let breadcrumbLinks = {...initialBreadCrumbLinks};
        if (currentPathname.includes(buildingAuditoriumArchiveTimeslotLink)) {
            currentPage = 'buildingAuditoriumArchiveTimeslot';
            currentPageLabel = 'Presentation';
            breadcrumbLinks.showVillage = true;
            breadcrumbLinks.showBuildingLobby = true;
            breadcrumbLinks.showBuildingAuditoriumArchive = true;
        } else if (currentPathname.includes(buildingAuditoriumArchiveLink)) {
            currentPage = 'buildingAuditoriumArchive';
            currentPageLabel = 'Auditorium Archive';
            breadcrumbLinks.showVillage = true;
            breadcrumbLinks.showBuildingLobby = true;
        } else if (currentPathname.includes(`${buildingRoot}/auditorium`)) {
            currentPage = 'buildingAuditorium';
            currentPageLabel = 'Auditorium';
            breadcrumbLinks.showVillage = true;
            breadcrumbLinks.showBuildingLobby = true;
        } else if (currentPathname.includes(`${buildingRoot}/showfloor`)) {
            currentPage = 'buildingShowfloor';
            currentPageLabel = 'Showfloor';
            breadcrumbLinks.showVillage = true;
            breadcrumbLinks.showBuildingLobby = true;
        } else if (currentPathname.includes(buildingLobbyLink)) {
            currentPage = 'buildingLobby';
            currentPageLabel = this.props.building?.name || 'Building Lobby';
            breadcrumbLinks.showVillage = true;
        } else if (currentPathname.includes(villageLink)) {
            currentPage = 'village';
            currentPageLabel = 'Village';
        } else if (currentPathname.includes(eventArchiveTimeslotLink)) {
            currentPage = 'eventArchiveTimeslot';
            currentPageLabel = 'Timeslot';
            breadcrumbLinks.showEventArchive = true;
        } else if (currentPathname.includes(eventArchiveLink)) {
            currentPage = 'eventArchive';
            currentPageLabel = 'Event Archive';
        } else if (currentPathname.includes(eventAuditoriumArchiveTimeslotLink)) {
            currentPage = 'eventAuditoriumArchiveTimeslot';
            currentPageLabel = 'Presentation';
            breadcrumbLinks.showEventAuditoriumArchive = true;
        } else if (currentPathname.includes(eventAuditoriumArchiveLink)) {
            currentPage = 'eventAuditoriumArchive';
            currentPageLabel = 'Auditorium Archive';
        } else if (currentPathname.includes(`${eventRoot}/auditorium`)) {
            currentPage = 'eventAuditorium';
            currentPageLabel = 'Auditorium';
        } else if (currentPathname.includes(`${eventRoot}/showfloor`)) {
            currentPage = 'eventShowfloor';
            currentPageLabel = 'Showfloor';
        }
        this.setState({
            currentPage: currentPage,
            currentPageLabel: currentPageLabel,
            breadcrumbLinks: breadcrumbLinks,
        });
    };

    render() {
        const {
            currentPageLabel,
            mainLobbyLink,
            eventAuditoriumArchiveLink,
            eventArchiveLink,
            villageLink,
            buildingLobbyLink,
            buildingAuditoriumArchiveLink,
            breadcrumbLinks,
        } = this.state;

        return (
            <div onDragStart={preventDefaultDrag} id="breadcrumbs" className={this.props.className}>
                <Breadcrumbs separator={<NavigateNextIcon color={'inherit'} />}>
                    <Link to={{pathname: `${mainLobbyLink}`}}>Main Lobby</Link>

                    {/*village type event breadcrumbs*/}
                    {breadcrumbLinks.showVillage && <Link to={{pathname: `${villageLink}`}}>Village</Link>}
                    {breadcrumbLinks.showBuildingLobby && (
                        <Link to={{pathname: `${buildingLobbyLink}`}}>
                            {this.props.building?.name || 'Building Lobby'}
                        </Link>
                    )}
                    {breadcrumbLinks.showBuildingAuditoriumArchive && (
                        <Link to={{pathname: `${buildingAuditoriumArchiveLink}`}}>Auditorium Archive</Link>
                    )}

                    {/*normal event breadcrumbs*/}
                    {breadcrumbLinks.showEventArchive && (
                        <Link to={{pathname: `${eventArchiveLink}`}}>Event Archive</Link>
                    )}
                    {breadcrumbLinks.showEventAuditoriumArchive && (
                        <Link to={{pathname: `${eventAuditoriumArchiveLink}`}}>Auditorium Archive</Link>
                    )}
                    <span>{currentPageLabel}</span>
                </Breadcrumbs>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventSlug: state.event.eventSlug,
        buildingId: state.building.buildingId,
        building: state.building.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BreadcrumbsNavigation));
