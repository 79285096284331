import React from 'react';
import {connect} from 'react-redux';
import axios from '../../../store/axios-instance';
import LinkIcon from '@material-ui/icons/Link';
import {checkBoothOwnerOrRepresentative, preventDefaultDrag} from '../../../Utils/utils';
import moment from 'moment';

class Links extends React.Component {
    // sendLinkClickStatistic = () => {
    //     if (this.props.eventRoles.isParticipant) {
    //         const dataToSend = {participantId: this.props.eventRoles.participantId, actionType: 'linkClicks'};
    //         axios({
    //             method: 'post',
    //             url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
    //             data: dataToSend,
    //         })
    //             .then((response) => {})
    //             .catch((error) => {});
    //     }
    // };

    handleClickOnLink = (linkId, linkName) => (e) => {
        const {event, user} = this.props;
        const {owner} = event;

        const actionType = 'boothLinks';
        const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
        let trackBoothStatistics = !isRepresentative;

        if (owner._id === user._id) {
            trackBoothStatistics = false;
            let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (linkId && linkName && trackBoothStatistics) {
            const data = {
                userId: this.props.user._id,
                linkId: linkId,
                linkName: linkName,
                actionType: actionType,
                timestamp: moment.utc(),
            };
            axios({
                method: 'post',
                url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
                data: data,
            })
                .then((res) => {})
                .catch((err) => {});
        }
    };

    render() {
        const {exhibitor} = this.props;

        return (
            <ul className="exhibitor-assets">
                {exhibitor.booth.socialMediaLinks.map((link) => (
                    <li key={link.platform} onClick={this.handleClickOnLink(link._id, link.platform)}>
                        <a href={link.link} target="_blank" rel="noopener noreferrer">
                            <span>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="label">
                                    {link.platform}:{' '}
                                </span>
                                <span onDragStart={preventDefaultDrag} draggable="false" className="link">
                                    {link.link}
                                </span>
                            </span>
                            <LinkIcon />
                        </a>
                    </li>
                ))}
                {exhibitor.booth.links.map((link) => (
                    <li key={link.label} onClick={this.handleClickOnLink(link._id, link.label)}>
                        <a href={link.link} target="_blank" rel="noopener noreferrer">
                            <span onDragStart={preventDefaultDrag} draggable="false" className="link">
                                {link.label}
                            </span>
                            <LinkIcon />
                        </a>
                    </li>
                ))}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        exhibitor: state.exhibitor.data,
        user: state.user.data,
    };
};

export default connect(mapStateToProps)(Links);
