export const loadStates = {
    loading: 'loading',
    ready: 'ready',
    error: 'error',
};

export const mediaTypes = {
    image: 'image',
    video: 'video',
};

export const agendaTypes = {
    meeting: 'meeting',
    program: 'program',
    custom: 'custom',
};

export const meetingStatuses = {
    pending: 'pending',
    confirmed: 'confirmed',
};

export const userStatuses = {
    online: 'online',
    offline: 'offline',
    busy: 'busy',
};
