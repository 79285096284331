import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import compact from 'lodash/compact';
import {connect} from 'react-redux';
import useForceUpdate from 'use-force-update';
import {EVENT} from 'avcore';
import {Utils} from 'avcore/client';

import VideoAvatar from './VideoAvatar';
import {preventDefaultDrag} from '../../../../Utils/utils';

const useStyles = makeStyles({
    videoPlate: {
        width: '100%',
        height: '100%',
    },
    activeStreamsVideoPlate: {
        position: 'absolute',
        width: 50,
        height: 40,
        right: 0,
        top: 0,
        zIndex: 150,
        overflow: 'hidden',
        border: '1px solid #fff',
        backgroundColor: '#000',
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    userName: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
});

const VideoChatPlayerSelfVideo = ({
    activeStreamsCount,
    selfVidRef,
    selfPlayback,
    videoConference,
    user,
    startVideo,
}) => {
    const classes = useStyles();
    const forceUpdate = useForceUpdate();

    const update = ({kind}) => {
        if (kind === 'video')
            setTimeout(() => {
                if (Utils.isFirefox && selfVidRef.current) selfVidRef.current.play();
                forceUpdate();
            });
    };
    selfPlayback.api._client.on(EVENT.STREAM_STARTED, update);
    selfPlayback.api._client.on(EVENT.STREAM_STOPPED, update);

    useEffect(() => {
        (async () => await startVideo(selfVidRef, selfPlayback))();
    }, [selfPlayback]); // eslint-disable-line react-hooks/exhaustive-deps

    const isCameraOff = !videoConference.selfPlayback.availableKinds.includes('video');
    const isActiveStreams = activeStreamsCount > 0;
    return (
        <div
            onDragStart={preventDefaultDrag}
            className={compact([isActiveStreams ? classes.activeStreamsVideoPlate : classes.videoPlate]).join(' ')}
        >
            {isCameraOff && (
                <div onDragStart={preventDefaultDrag} className={classes.userName}>
                    <VideoAvatar member={user} isMinified={isActiveStreams} />
                </div>
            )}
            <video
                className={compact([classes.video, isCameraOff ? classes.hidden : null]).join(' ')}
                ref={selfVidRef}
                id="playback-video-self"
                muted={true}
                playsInline={true}
            ></video>
        </div>
    );
};

const mapStateToProps = (state) => ({
    videoConference: state.videoConference,
    user: state.user.data,
});

export default connect(mapStateToProps)(VideoChatPlayerSelfVideo);
