import {EVENT} from 'avcore';
import {
    CLOSE_VIDEO_CONFERENCE,
    VIDEO_CONFERENCE_LOADING,
    VIDEO_CONFERENCE_READY,
    VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED,
    VIDEO_CONFERENCE_CHANGE_PLAYBACKS,
    VIDEO_CONFERENCE_START_CALLING,
    VIDEO_CONFERENCE_STOP_CALLING,
} from '../actions/actionTypes';
import {startCapture, startPlayback} from '../../Utils/videoConference';
import {callUsers} from '../../Api/socketApi';

const videoConferenceReady = (payload) => {
    return {
        type: VIDEO_CONFERENCE_READY,
        payload,
    };
};

const videConferenceLoading = () => {
    return {
        type: VIDEO_CONFERENCE_LOADING,
    };
};

export const startCalling = () => {
    return {
        type: VIDEO_CONFERENCE_START_CALLING,
    };
};

export const stopCalling = () => {
    return {
        type: VIDEO_CONFERENCE_STOP_CALLING,
    };
};

export const closeVideoConference = (videoConference) => {
    const closePlayback = (playback) => {
        if (!playback) return;
        playback.api._client.off(EVENT.STREAM_STARTED);
        playback.api._client.off(EVENT.STREAM_STOPPED);
        if (playback.mediaStream) {
            playback.mediaStream.oninactive = null;
            playback.mediaStream.onactive = null;
        }
        playback.close();
    };

    videoConference.playbacks.forEach((playback) => closePlayback(playback));
    if (videoConference.selfPlayback) closePlayback(videoConference.selfPlayback);
    if (videoConference.capture) videoConference.capture.close();
    return {
        type: CLOSE_VIDEO_CONFERENCE,
    };
};

export const openVideoConference = (videoConference, user, otherMembers, chatId) => {
    return async (dispatch) => {
        if (videoConference.isActive) dispatch(closeVideoConference(videoConference));
        dispatch(videConferenceLoading());

        if (!videoConference.isActive) {
            callUsers(otherMembers);
        }
        const membersPromises = otherMembers.map((memberId) => startPlayback(`${chatId}_${memberId}`));

        // startCapture: captures your camera an mic and publishes it to the AVCore server
        // startPlayback: gets the stream from the AVCore server so you can see yourself and the other chat participants
        const payload = {
            capture: await startCapture(`${chatId}_${user._id}`),
            selfPlayback: await startPlayback(`${chatId}_${user._id}`, ['video']),
            playbacks: await Promise.all(membersPromises),
        };

        dispatch(videoConferenceReady(payload));
    };
};

export const toggleShareScreen = (payload) => {
    return {
        type: VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED,
        payload,
    };
};

export const toggleScreen = (videoConference, isShare) => {
    return async (dispatch) => {
        const streamName = videoConference.capture.configs.stream;
        const capture = await startCapture(streamName, undefined, isShare, videoConference.capture);
        if (!capture) return;
        dispatch(toggleShareScreen({capture, isScreenShared: isShare}));
    };
};

const updatePlaybacks = (payload) => {
    return {
        type: VIDEO_CONFERENCE_CHANGE_PLAYBACKS,
        payload,
    };
};

export const changePlaybacks = (videoConference, user, members, chatId) => {
    return async (dispatch) => {
        for (const member of members) {
            const stream = `${chatId}_${member._id}`;
            const index = videoConference.playbacks.findIndex((pb) => pb.configs.stream === stream);
            if (index >= 0) {
                await videoConference.playbacks[index].close();
                videoConference.playbacks.splice(index, 1);
            } else {
                const playback = await startPlayback(stream);
                videoConference.playbacks.push(playback);
            }
        }

        dispatch(updatePlaybacks({playbacks: [...videoConference.playbacks]}));
    };
};
