import axios from '../axios-instance';
import {
    IMPORT_AUDITORIUM_PROGRAM_REQUEST,
    IMPORT_AUDITORIUM_PROGRAM_SUCCESS,
    IMPORT_AUDITORIUM_PROGRAM_FAIL,
    IMPORT_AUDITORIUM_PROGRAM_RESET,
} from './actionTypes';

export const importProgramFromXLS = (formData, auditoriumId, eventId) => async (dispatch) => {
    try {
        dispatch({type: IMPORT_AUDITORIUM_PROGRAM_REQUEST});
        //POSTMAN url - below
        //{{url}}/v1/event/:eventId/auditoriums/:auditoriumId/import-program
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        await axios.post(`/event/${eventId}/auditoriums/${auditoriumId}/import-program`, formData, config);
        dispatch({
            type: IMPORT_AUDITORIUM_PROGRAM_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: IMPORT_AUDITORIUM_PROGRAM_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};
export const importProgramFromXLSReset = (formData) => async (dispatch) => {
    try {
        dispatch({type: IMPORT_AUDITORIUM_PROGRAM_RESET});
    } catch (error) {
        dispatch({
            type: IMPORT_AUDITORIUM_PROGRAM_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};
