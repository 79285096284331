import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const initialState = {
    loading: false,
    onlineUsersObject: {},
    onlineUsersNumber: 0,
    error: false,
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_ONLINE_USERS_START:
                draft.loading = true;
                break;
            case actionTypes.GET_ONLINE_USERS_SUCCESS:
                // newState = cloneDeep(state)
                // newState.loading = false;
                // newState.onlineUsersNumber = action.payload.length;
                // // map the array of users to an object where the key is the ID
                // action.payload.forEach(userId=>{
                //     newState.onlineUsersObject[userId] = true;
                // })
                draft.loading = false;
                draft.onlineUsersNumber = action.payload.length;
                // map the array of users to an object where the key is the ID
                action.payload.forEach(userId=>{
                    draft.onlineUsersObject[userId] = true;
                })
                break;
            case actionTypes.GET_ONLINE_USERS_FAIL:
                // newState = cloneDeep(state)
                // newState.loading = false;
                // return newState;
                draft.loading = false
                break;
            case actionTypes.SET_EVENT_ONLINE_USER:
                const users = action.payload;

                users.forEach(user=>{
                    // if user gets ONLINE and its id DOESN'T exist in the onlineUsersObject
                    // push him in onlineUsersObject
                    if (user?.status === 'online' && !state.onlineUsersObject[user.id]) {
                        draft.onlineUsersObject[user.id] = true;
                        draft.onlineUsersNumber = draft.onlineUsersNumber + 1;
                    }

                    // if user goes OFFLINE and its id EXIST in the onlineUsers
                    // delete him from onlineUsersObject
                    if (user?.status === 'offline' && state.onlineUsersObject[user.id]) {
                        delete draft.onlineUsersObject[user.id];
                        draft.onlineUsersNumber = draft.onlineUsersNumber - 1;
                    }
                })

                break;
            default:
                break;
        }
    });


export default reducer;
