import * as actionTypes from '../actions/actionTypes';
import produce from "immer";

const noError = {
    apiCall: '',
    errorMessage: '',
};

const initialState = {
    loading: false,
    liveSession: null,
    error: noError,
    liveSessionUser: null,
    liveSessionActiveUsers: [],
};

const reducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.GET_LIVE_SESSION_START:
            case actionTypes.GET_LIVE_SESSION_USER_START:
                draft.loading = true;
                draft.error = noError;
                break;
            case actionTypes.GET_LIVE_SESSION_SUCCESS:
                draft.loading = false;
                draft.liveSession = action.payload;

                // set the liveSessionActiveUsers
                let liveSessionActiveUsers = [];
                liveSessionActiveUsers = action.payload?.users
                    .filter((user) => user.connected)
                    .filter((user) => user.isReady)
                    .filter((user) => user.audio || user.video || user.screenSharing);
                draft.liveSessionActiveUsers = liveSessionActiveUsers;

                // set the liveSesionUser

                let liveSessionUser = action.payload?.users.find(
                    (user) => user.userId === state.liveSessionUser?.userId
                );
                if (liveSessionUser === undefined) {
                    liveSessionUser = null;
                }
                draft.liveSessionUser = liveSessionUser;

                break;

            case actionTypes.GET_LIVE_SESSION_FAIL:
                draft.loading = false;
                draft.error = 'Get live session failed.';
                break;

            case actionTypes.GET_LIVE_SESSION_USER_SUCCESS:
                draft.liveSessionUser = action.payload;
                break;
            case actionTypes.GET_LIVE_SESSION_USER_FAIL:
                draft.loading = false;
                draft.error = 'Get live session user failed.';
                break;

            case actionTypes.SET_LIVE_SESSION_IS_ACTIVE:
                draft.liveSession.isActive = action.payload;
                draft.error = 'Set stream failed.';
                break;

            case actionTypes.SET_LIVE_SESSION_RECORDS:
                draft.liveSession.records = action.payload;
                draft.error = 'Set records failed.';
                break;

            default:
                break;
        }
    });


export default reducer;
