import React, {Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import ColoredScrollbars from '../../SmallLayoutComponents/ColoredScrollbars';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import {checkIfEventHasEnded, getFormatedDate, getVideoIdFromYoutubeUrl, preventDefaultDrag} from '../../Utils/utils';
import BreadcrumbsNavigation from '../../Components/BreadcrumbsNavigation';
import NoBuildingAccess from './NoBuildingAccess';
import '../../CSS/auditorium.scss';
import colors from '../../CSS/_variables.module.scss';
import PlayIcon from '../../Images/svg/play.svg';
import {ReactComponent as Search} from '../../Images/svg/search-icon.svg';

class BuildingAuditoriumArchive extends React.Component {
    state = {
        search: '',
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();
        const {event, buildingId, building} = this.props;

        let buildingProgramHasEnded = checkIfEventHasEnded(building, this.props.event.timezoneValue);
        if (!buildingProgramHasEnded) {
            this.props.history.push(`/event/${event.slug}/village/building/${buildingId}/auditorium`);
        }
        this.props.setHasVideoWall(false);
        this.props.closeTopNavigation();
    }

    getDay = (dateToFormat) => {
        let date = new Date(dateToFormat);
        const d = date.getDate();
        const formatedDate = (d < 9 ? '0' + d : d) + ' - ';
        return formatedDate;
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const {event, building, buildingId, resourcesAccess, eventRoles} = this.props;
        if (
            event.hasAccessManagement &&
            eventRoles.isParticipant &&
            resourcesAccess !== null &&
            !resourcesAccess.buildings.includes(building._id)
        ) {
            return <NoBuildingAccess />;
        }
        return (
            <div onDragStart={preventDefaultDrag} className="auditorium-archive-page">
                <BreadcrumbsNavigation />
                <div onDragStart={preventDefaultDrag} className="program-dashboard">
                    <div onDragStart={preventDefaultDrag} className="program-section">
                        <div onDragStart={preventDefaultDrag} className="smallContainer">
                            <div onDragStart={preventDefaultDrag} className="page-title">
                                <span onDragStart={preventDefaultDrag} draggable="false" className="name">
                                    {event.name}
                                </span>
                                {/*remove d-none to show the search input*/}
                                <div
                                    onDragStart={preventDefaultDrag}
                                    className="search-container position-relative d-none"
                                >
                                    <input
                                        id="search"
                                        placeholder="Tap to search"
                                        value={this.state.search}
                                        onChange={this.handleChange('search')}
                                    />
                                    <Search fill={colors.grey} />
                                </div>
                            </div>
                            {event ? (
                                <div onDragStart={preventDefaultDrag} className="auditoriums-archive-container">
                                    <ColoredScrollbars>
                                        {building.auditoriums.map((auditorium) => {
                                            let hasVideoTimeslots = false;
                                            auditorium.dailyProgram.forEach((dayProgram) => {
                                                dayProgram.program.forEach((timeslot) => {
                                                    if (timeslot.video || timeslot.session?.records?.length) {
                                                        hasVideoTimeslots = true;
                                                    }
                                                });
                                            });

                                            if (!hasVideoTimeslots) {
                                                return null;
                                            }

                                            return (
                                                <div
                                                    className="single-auditorium-archive-container"
                                                    key={auditorium._id}
                                                >
                                                    <h4 className="auditorium-name">
                                                        <span>{auditorium.name}</span>
                                                        <span
                                                            onDragStart={preventDefaultDrag}
                                                            draggable="false"
                                                            className="break"
                                                        >
                                                            |
                                                        </span>
                                                        {auditorium.dailyProgram.length > 1 ? (
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="dates"
                                                            >
                                                                {this.getDay(auditorium.dailyProgram[0].date)}
                                                                {getFormatedDate(
                                                                    auditorium.dailyProgram[
                                                                        auditorium.dailyProgram.length - 1
                                                                    ].date,
                                                                    true
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                onDragStart={preventDefaultDrag}
                                                                draggable="false"
                                                                className="dates"
                                                            >
                                                                {getFormatedDate(auditorium.dailyProgram[0].date, true)}
                                                            </span>
                                                        )}
                                                    </h4>
                                                    <Grid container className="smallContainer">
                                                        {auditorium.dailyProgram.map((dayProgram) => {
                                                            return (
                                                                <Fragment key={dayProgram._id}>
                                                                    {dayProgram.program.map((timeslot) => {
                                                                        let exhibitorVideoId = getVideoIdFromYoutubeUrl(
                                                                            timeslot.video
                                                                        );
                                                                        if (
                                                                            !timeslot.video &&
                                                                            !timeslot.session?.records?.length
                                                                        ) {
                                                                            return null;
                                                                        }
                                                                        let timeslotLink = `/event/${event.slug}/village/building/${buildingId}/auditorium-archive/timeslot/${timeslot._id}`;
                                                                        return (
                                                                            <Grid
                                                                                item
                                                                                xs={6}
                                                                                md={4}
                                                                                lg={3}
                                                                                key={timeslot._id}
                                                                                className="program-entry"
                                                                            >
                                                                                <div
                                                                                    onDragStart={(e) =>
                                                                                        e.preventDefault()
                                                                                    }
                                                                                    className="video-preview-container"
                                                                                >
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: timeslotLink,
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            onDragStart={(e) =>
                                                                                                e.preventDefault()
                                                                                            }
                                                                                            className="clickable-element"
                                                                                        >
                                                                                            <div
                                                                                                onDragStart={(e) =>
                                                                                                    e.preventDefault()
                                                                                                }
                                                                                                className="image-preview-video"
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        'https://img.youtube.com/vi/' +
                                                                                                        exhibitorVideoId +
                                                                                                        '/0.jpg'
                                                                                                    }
                                                                                                    alt="preview video"
                                                                                                />
                                                                                                {/*remove d-none to show duration*/}
                                                                                                <div
                                                                                                    onDragStart={(e) =>
                                                                                                        e.preventDefault()
                                                                                                    }
                                                                                                    className="video-duration d-none"
                                                                                                >
                                                                                                    <span>40:16</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="play-button"
                                                                                                    style={{
                                                                                                        backgroundImage: `url('${PlayIcon}')`,
                                                                                                    }}
                                                                                                ></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                                <div
                                                                                    onDragStart={(e) =>
                                                                                        e.preventDefault()
                                                                                    }
                                                                                    className="video-details-container"
                                                                                >
                                                                                    <div
                                                                                        onDragStart={(e) =>
                                                                                            e.preventDefault()
                                                                                        }
                                                                                        className="speakers-container"
                                                                                    >
                                                                                        {timeslot.speakersExtended
                                                                                            .length
                                                                                            ? timeslot.speakersExtended.map(
                                                                                                  (speaker, index) => {
                                                                                                      return (
                                                                                                          <span
                                                                                                              className="speaker"
                                                                                                              key={
                                                                                                                  speaker._id
                                                                                                              }
                                                                                                          >
                                                                                                              {
                                                                                                                  speaker.fullName
                                                                                                              }
                                                                                                              {index !==
                                                                                                              timeslot
                                                                                                                  .speakersExtended
                                                                                                                  .length -
                                                                                                                  1
                                                                                                                  ? ', '
                                                                                                                  : null}
                                                                                                          </span>
                                                                                                      );
                                                                                                  }
                                                                                              )
                                                                                            : null}
                                                                                    </div>
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: timeslotLink,
                                                                                        }}
                                                                                    >
                                                                                        {timeslot.title}
                                                                                    </Link>
                                                                                    <div
                                                                                        onDragStart={(e) =>
                                                                                            e.preventDefault()
                                                                                        }
                                                                                        className="program-date-hash"
                                                                                    >
                                                                                        <span
                                                                                            draggable="false"
                                                                                            className="date"
                                                                                        >
                                                                                            {getFormatedDate(
                                                                                                dayProgram.date
                                                                                            )}
                                                                                        </span>
                                                                                        {/*remove d-none to show hashtgas*/}
                                                                                        <span
                                                                                            draggable="false"
                                                                                            className="hashtag d-none"
                                                                                        >
                                                                                            #test #123 #video
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                    </ColoredScrollbars>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventId: state.event.eventId,
        event: state.event.data,
        user: state.user.data,
        buildingId: state.building.buildingId,
        building: state.building.data,
        eventRoles: state.user.eventRoles,
        resourcesAccess: state.user.resourcesAccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeTopNavigation: () => dispatch(actions.topNavClose()),
        setHasVideoWall: (booleanValue) => dispatch(actions.sideNavHasVideoWall(booleanValue)),
        onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingAuditoriumArchive);
