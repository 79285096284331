import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getTimeAgoString, preventDefaultDrag} from '../../Utils/utils';

class TimeAgo extends PureComponent {
    componentDidMount() {
        this.interval = setInterval(() => this.forceUpdate(), 60000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {createdAt, updatedAt, translation, defaultTranslation, platformLanguage} = this.props;
        let timeAgoString = getTimeAgoString(createdAt, platformLanguage);

        return (
            <p onDragStart={preventDefaultDrag} className="time-ago">
                {timeAgoString}{' '}
                {updatedAt
                    ? `• ${translation?.wall.edited || defaultTranslation?.wall.edited}`
                    : ''}
            </p>
        );
    }
}
function mapStateToProps(state) {
    return {
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
        platformLanguage: state.languages.platformLanguage,
    };
}
export default connect(
    mapStateToProps,
)(TimeAgo);