import * as actionTypes from './actionTypes';
import axios from '../axios-instance';

export const getEventArchive = (eventId) => {
    return (dispatch) => {
        dispatch(getEventArchiveStart());

        axios({method: 'get', url: `/event/${eventId}/archived-events`})
            .then((response) => {
                let eventArchiveData = response.data.archivedEvents;
                dispatch(getEventArchiveSuccess(eventArchiveData));
            })
            .catch((error) => {
                let errorMessage = "We couldn't retrieve the event arhchive. Please try again.";
                dispatch(getEventArchiveFail(errorMessage));
            });
    };
};

const getEventArchiveStart = () => {
    return {
        type: actionTypes.GET_EVENT_ARCHIVE_START,
    };
};
const getEventArchiveSuccess = (eventArchiveData) => {
    return {
        type: actionTypes.GET_EVENT_ARCHIVE_SUCCESS,
        payload: eventArchiveData,
    };
};
const getEventArchiveFail = (errorMessage) => {
    return {
        type: actionTypes.GET_EVENT_ARCHIVE_FAIL,
        payload: errorMessage,
    };
};

export const updateSingleArchivedEventDetails = (singleEventData) => {
    return {
        type: actionTypes.UPDATE_SINGLE_EVENT_ARCHIVE_SUCCESS,
        payload: singleEventData,
    };
};
