import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
    loading: false,
    error: null,
    hasOnDemandVideos: false,
    auditoriums: [],
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.API_CALL_ON_DEMAND_VIDEOS_START:
                draft.loading = true;
                break;

            case actionTypes.API_CALL_ON_DEMAND_VIDEOS_FAIL:
                draft.loading = false;
                draft.error = action.payload;
                break;

            case actionTypes.HAS_ON_DEMAND_VIDEOS_SUCCESS:
                draft.loading = false;
                draft.error = null;
                draft.hasOnDemandVideos = action.payload;
                break;

            case actionTypes.GET_ON_DEMAND_VIDEOS_SUCCESS:
                draft.loading = false;
                draft.error = null;
                draft.auditoriums = action.payload;
                break;

            default:
                break;
        }
    });

export default reducer;
