import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {isMobile} from 'react-device-detect';

import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CallEndIcon from '@material-ui/icons/CallEnd';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';

import * as actions from '../../../../store/actions/index';
import {preventDefaultDrag} from '../../../../Utils/utils';

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        position: 'absolute',
        width: '100%',
        zIndex: 200,
        bottom: 5,
    },
    button: {
        color: '#fff',
        cursor: 'pointer',
        fontSize: '45px',
    },
    buttonEndCall: {
        color: 'red',
    },
});

const VideoChatPlayerButtons = ({
    soundEnabled,
    videoEnabled,
    toggleSound,
    toggleVideo,
    close,
    videoConference,
    toggleScreen,
    removeVideoTracks,
    toggleShareScreen,
}) => {
    const classes = useStyles();

    const toggleScreenShare = (isShare) => {
        if (isShare) {
            toggleScreen(videoConference, isShare);
        } else {
            if (videoEnabled) return toggleScreen(videoConference, isShare);
            removeVideoTracks();
            toggleShareScreen({isScreenShared: false});
        }
    };

    return (
        <div onDragStart={preventDefaultDrag} className={`${classes.buttons} video-conference-buttons`}>
            {soundEnabled && <MicIcon className={classes.button} onClick={toggleSound} />}
            {!soundEnabled && <MicOffIcon className={classes.button} onClick={toggleSound} />}
            <CallEndIcon className={`${classes.button} ${classes.buttonEndCall}`} onClick={close} />

            {!videoConference.isScreenShared && (
                <>
                    {videoEnabled && <VideocamIcon className={classes.button} onClick={toggleVideo} />}
                    {!videoEnabled && <VideocamOffIcon className={classes.button} onClick={toggleVideo} />}
                </>
            )}

            {!isMobile && !videoConference.isScreenShared && (
                <ScreenShareIcon className={classes.button} onClick={() => toggleScreenShare(true)} />
            )}
            {!isMobile && videoConference.isScreenShared && (
                <StopScreenShareIcon className={classes.button} onClick={() => toggleScreenShare(false)} />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    videoConference: state.videoConference,
});

const mapDispatchToProps = (dispatch) => ({
    toggleScreen: (videoConference, isShare) => dispatch(actions.toggleScreen(videoConference, isShare)),
    toggleShareScreen: (payload) => dispatch(actions.toggleShareScreen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoChatPlayerButtons);
