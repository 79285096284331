import {createSelector} from 'reselect';

const sessionDashboard = (state) => state.sessions.sessionDashboard;

export const getVideoConference = createSelector(sessionDashboard, (state) => state.videoConference);

export const getUserProfile = createSelector(sessionDashboard, (state) => state.profile);

export const getUsers = createSelector(sessionDashboard, (state) => state.session.users);

export const getPlaybacks = createSelector(sessionDashboard, (state) => state.videoConference.playbacks);

export const getUpdatedUser = createSelector(sessionDashboard, (state) => state.updatedUser);

export const getUpdatedUserProps = createSelector(sessionDashboard, (state) => state.updatedUserProps);

export const getSessionData = createSelector(sessionDashboard, (state) => state.session);

export const getFullScreen = createSelector(sessionDashboard, (state) => state.videoConference.isFullScreen);
