import handleSessionsList from './handlers/sessionsList';
import handleSessionDashboard from './handlers/sessionDashboard';
import handleZoomSessionDashboar from './handlers/zoomSessionDashboard';

const reducer = (state, action) => ({
    sessionsList: handleSessionsList(state, action),
    sessionDashboard: handleSessionDashboard(state, action),
    zoomSessionDashboard: handleZoomSessionDashboar(state, action),
});

export default reducer;
