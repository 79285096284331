import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import {ValidatorForm} from 'react-material-ui-form-validator';

import Send from '@material-ui/icons/Send';

const Thumb = ({style, ...props}) => (
    <div className="grey-scroll" style={{...style, backgroundColor: `rgb(137,137,137)`, left: 0}} {...props} />
);

const ChatModule = ({messages = [], users = [], sendMessage}) => {
    const [inputValue, setInputValue] = useState('');
    const scrollbar = useRef(null);

    useEffect(() => {
        scrollbar.current.scrollToBottom();
    }, [messages]);

    const handleSendMessage = () => {
        if (!inputValue) return;
        sendMessage(inputValue);
        setInputValue('');
    };

    return (
        <div className="module -chat">
            <div className="module-container">
                <div className="module-header">
                    <span>SESSION CHAT</span>
                </div>

                <div className="module-body">
                    <div className="chat-container">
                        <Scrollbars
                            ref={scrollbar}
                            className="scrollbar"
                            renderThumbVertical={Thumb}
                            width="100%"
                            height="100%"
                        >
                            <div className="message-container">
                                {messages.length &&
                                    messages.map((message) => {
                                        const user = users.find((user) => user.userId === message.userId);

                                        return (
                                            <div className="message" key={message._id}>
                                                <div className="message-avatar">{user.name.charAt(0)}</div>
                                                <div className="message-text">
                                                    <h4 className="title">
                                                        {user.name} {user.role.toUpperCase()}
                                                    </h4>
                                                    <span>{message.text}</span>
                                                </div>
                                                <div className="message-time">
                                                    {moment(message.createdAt).format('hh:mm a')}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </Scrollbars>
                    </div>
                    <ValidatorForm className="message-input" onSubmit={handleSendMessage}>
                        <input
                            id="newMessage"
                            placeholder="Type message here"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />

                        <button>
                            <Send />
                        </button>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );
};

export default ChatModule;
