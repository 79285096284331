import React, {PureComponent} from 'react';
import SinglePost from './SinglePost';
import {withRouter} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';

class PostsList extends PureComponent {
    render() {
        const { posts } = this.props;
        return (
            <>
                {posts.map((post) => {
                    return (
                        <SinglePost
                            key={post._id}
                            currentPost={post}
                            isPinned={post.isPinned}
                        />
                    );
                })}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps)(PostsList));
