import React, {useEffect, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {receiveCallUsers} from '../../Api/socketApi';
import {startCalling} from '../../store/actions';
import ring from './ring.mp3';

const RingContainer = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const {isActive, isCalling} = useSelector((state) => state.videoConference);

    const audio = useRef(false);

    useEffect(() => {
        if (isCalling && !isActive) {
            audio.current.play();
        } else {
            audio.current.pause();
        }
    }, [isCalling, isActive]);

    const getCalls = useCallback(() => {
        receiveCallUsers((userIds) => {
            if (userIds.includes(user?._id) && !isActive) {
                dispatch(startCalling());
            }
        });
    }, [isActive, user, dispatch]);

    useEffect(() => {
        getCalls();
    }, [getCalls]);

    return <audio ref={audio} src={ring} loop={false} hidden />;
};
export default RingContainer;
