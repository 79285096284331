import NotActiveSpeakerInfo from './NotActiveSpeakerInfo';
import ActiveSpeakerInfo from './ActiveSpeakerInfo';
import {useEffect, useState} from 'react';
import {ReactComponent as DefaultUser} from '../Helpers/Icons/svg/default_user.svg';
const Person = ({
    status,
    activeSpeaker,
    handleKeyDown,
    user,
    closeAllDialogs,
    keyDownCloseAllDialogs,
    eventId,
    visited,
    activeSpeakerId,
}) => {
    const {innerWidth} = window;
    const [personMargin, setPersonMargin] = useState('55px');

    const getHeights = () => {
        const overlays = document.querySelectorAll('.speakers-item');
        for (var i = 0; i < overlays.length; i++) {
            overlays[i].offsetHeight >= 214 && setPersonMargin(innerWidth <= 1300 ? '120px' : '100px');
        }
    };

    const notActive = (_) => {
        return;
    };

    useEffect(() => {
        getHeights();
    }, []);
    return (
        <div className="speakers-item">
            <div className="person-short">
                {user && user.image ? (
                    <div
                        tabIndex="1"
                        onClick={
                            (user.description && user.description !== '<p><br></p>') || innerWidth < 1024
                                ? activeSpeaker
                                : notActive
                        }
                        onKeyDown={
                            (user.description && user.description !== '<p><br></p>') || innerWidth < 1024
                                ? handleKeyDown
                                : notActive
                        }
                        className={`person-img ${status ? 'active-person-img' : ''} ${
                            !user.description || user.description === '<p><br></p>' ? 'no-hover' : ''
                        } ${
                            activeSpeakerId !== null
                                ? user._id === activeSpeakerId
                                    ? ''
                                    : 'focus-black-white-unselected'
                                : ''
                        }`}
                        style={{
                            background: `url(${process.env.REACT_APP_EVENT_FOLDER}${eventId}/${user.image})`,
                            filter: `${
                                activeSpeakerId !== null
                                    ? user._id === activeSpeakerId
                                        ? 'grayscale(0%)'
                                        : 'grayscale(100%)'
                                    : 'grayscale(0%)'
                            }`,

                            marginBottom: `${status ? personMargin : '5px'}`,
                        }}
                    ></div>
                ) : (
                    <>
                        <DefaultUser
                            style={{marginBottom: `${status ? personMargin : '5px'}`}}
                            className={`${
                                (user.description && user.description !== '<p><br></p>') || innerWidth < 1024
                                    ? `default-user ${status ? 'active-person-svg' : ''}`
                                    : 'outline-0'
                            }`}
                            tabIndex="1"
                            onClick={
                                (user.description && user.description !== '<p><br></p>') || innerWidth < 1024
                                    ? activeSpeaker
                                    : notActive
                            }
                        />
                    </>
                )}

                {status ? <div className="active-speaker-bottom"></div> : <NotActiveSpeakerInfo user={user} />}
            </div>
            {status && (
                <ActiveSpeakerInfo
                    eventId={eventId}
                    user={user}
                    status={status}
                    closeAllDialogs={closeAllDialogs}
                    keyDownCloseAllDialogs={keyDownCloseAllDialogs}
                    visited={visited}
                />
            )}
        </div>
    );
};

export default Person;
