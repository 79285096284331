import * as actionTypes from './actionTypes';
import axios from '../axios-instance';

const apiCallStart = () => {
    return {
        type: actionTypes.API_CALL_ON_DEMAND_VIDEOS_START,
    };
};
const apiCallFail = (error) => {
    return {
        type: actionTypes.API_CALL_ON_DEMAND_VIDEOS_FAIL,
        payload: error,
    };
};

export const hasOnDemandVideos = (eventId) => {
    return (dispatch) => {
        dispatch(apiCallStart());

        return axios({method: 'get', url: `event/${eventId}/has-on-demand-videos`})
            .then((response) => {
                const hasVideos = response.data.response;
                dispatch(hasOnDemandVideosSuccess(hasVideos));
            })
            .catch((error) => {
                dispatch(apiCallFail('hasOnDemandVideos'));
            });
    };
};

const hasOnDemandVideosSuccess = (hasVideos) => {
    return {
        type: actionTypes.HAS_ON_DEMAND_VIDEOS_SUCCESS,
        payload: hasVideos,
    };
};

export const getOnDemandVideos = (eventId) => {
    return (dispatch) => {
        dispatch(apiCallStart());

        return axios({method: 'get', url: `event/${eventId}/on-demand-videos`})
            .then((response) => {
                let auditoriums = [];
                if (response.data) {
                    auditoriums = response.data.auditoriums || [];
                }
                dispatch(getOnDemandVideosSuccess(auditoriums));
            })
            .catch((error) => {
                dispatch(apiCallFail('getOnDemandVideos'));
            });
    };
};

const getOnDemandVideosSuccess = (auditoriums) => {
    return {
        type: actionTypes.GET_ON_DEMAND_VIDEOS_SUCCESS,
        payload: auditoriums,
    };
};
