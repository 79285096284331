import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {changeSessionPermission} from '../../../store/actions';
import {changeSpeakerAuthorization} from "../../../store/actions/sessionActions";

export const useChangePermission = ({sessionId, user}) => {
    const dispatch = useDispatch();
    const sessionEnded = false;

    const handleSpeakerAuthorization = useCallback(
        (permissionType, permissionValue) => {
            if (sessionEnded) return;

            dispatch(changeSpeakerAuthorization({sessionId, userId: user?.userId, permissionType, permissionValue}));
        },
        [dispatch, user, sessionEnded, sessionId]
    );

    const handlePermission = useCallback(
        (permissionType) => {
            if (sessionEnded) return;

            dispatch(changeSessionPermission({sessionId, permissionType, userId: user?.userId}));
        },
        [dispatch, user, sessionEnded, sessionId]
    );

    const handleAdmitUser = () => {
        handlePermission('approve');
    };

    const handleToggleScreenShare = useCallback(() => {
        const actionType = user?.screenSharing ? 'disable-sharing' : 'enable-sharing';
        handlePermission(actionType);
    }, [user, handlePermission]);

    const handleToggleAudio = useCallback(() => {
        const actionType = user?.audio ? 'mute' : 'unmute';
        handlePermission(actionType);
    }, [user, handlePermission]);

    const handleToggleVideo = useCallback(() => {
        const actionType = user?.video ? 'disable-video' : 'enable-video';
        handlePermission(actionType);
    }, [user, handlePermission]);

    const handleKickUser = useCallback(() => {
        handlePermission('kick');
    }, [handlePermission]);


    const handleToggleScreenShareAuthorization = useCallback(() => {
        const actionType = 'allowScreenSharing';
        const actionValue = !user?.allowScreenSharing;
        handleSpeakerAuthorization(actionType, actionValue);
    }, [user, handleSpeakerAuthorization]);

    const handleToggleAudioAuthorization = useCallback(() => {
        const actionType = 'allowAudio';
        const actionValue = !user?.allowAudio;
        handleSpeakerAuthorization(actionType, actionValue);
    }, [user, handleSpeakerAuthorization]);

    const handleToggleVideoAuthorization = useCallback(() => {
        const actionType = 'allowVideo';
        const actionValue = !user?.allowVideo;
        handleSpeakerAuthorization(actionType, actionValue);
    }, [user, handleSpeakerAuthorization]);


    return {
        handleAdmitUser,
        handleToggleScreenShare,
        handleToggleAudio,
        handleToggleVideo,
        handleKickUser,
        handleToggleScreenShareAuthorization,
        handleToggleAudioAuthorization,
        handleToggleVideoAuthorization
    };
};
