import * as actionTypes from './actionTypes';
import axios from '../axios-instance';

export const getExhibitor = (exhibitorId) => {
    return (dispatch) => {
        dispatch(getExhibitorStart());

        axios({method: 'get', url: '/exhibitor/' + exhibitorId})
            .then((response) => {
                let exhibitor = response.data.exhibitor;

                dispatch(getExhibitorSuccess(exhibitor));
            })
            .catch((error) => {
                dispatch(getExhibitorFail());
            });
    };
};

export const getExhibitorStart = () => {
    return {
        type: actionTypes.GET_EXHIBITOR_START,
    };
};
export const getExhibitorSuccess = (exhibitor) => {
    return {
        type: actionTypes.GET_EXHIBITOR_SUCCESS,
        payload: exhibitor,
    };
};
export const getExhibitorFail = () => {
    return {
        type: actionTypes.GET_EXHIBITOR_FAIL,
    };
};
