import React from 'react';
import {ReactComponent as MainLogoImage} from '../Images/svg/logo_header.svg';
import {ReactComponent as MainLogoImageColored} from '../Images/svg/logo_scrolled.svg';

const AdminLogo = (props) => {
    if (props.colored) {
        return <MainLogoImage />;
    }
    return <MainLogoImageColored />;
};

export default AdminLogo;
