import {useState, useEffect, useRef} from 'react';

import {useInterpretation} from '../../../store/reducers/interpretation';
import {getRecognizerControls} from './VideoContainer.helpers';

const CUE_REMOVING_DELAY = 2.5;

// useSubtitlesDisplay
const useSubtitlesTrack = (video, isEnabled) => {
    const [track, setTrack] = useState(null);

    useEffect(() => {
        if (!video || !isEnabled) {
            return;
        }

        const track = video.addTextTrack('subtitles', 'Interpretation', 'en_US');
        track.mode = 'showing';
        setTrack(track);
    }, [video, isEnabled]);

    return track;
};

const useSubtitlesUpdatingOnTextChanging = (video, track, text) => {
    useEffect(() => {
        if (!track || !text) {
            return;
        }

        const cue = new VTTCue(video.currentTime, video.currentTime + CUE_REMOVING_DELAY, text);
        cue.align = 'start';
        track.addCue(cue);

        return () => track.removeCue(cue);
    }, [video, track, text]);
};

const useSubtitlesDisplay = ({video, text, isEnabled}) => {
    const track = useSubtitlesTrack(video, isEnabled);
    useSubtitlesUpdatingOnTextChanging(video, track, text);
};

// useMicrosoftAzureRecognizer
const useMicrosoftAzureRecognizer = ({video, isEnabled, updateText}) => {
    const [{interpretationTargetLocale, interpretationSourceLocale}] = useInterpretation();
    const [mediaStream, setMediaStream] = useState(null);
    const [wasLaunched, setWasLaunched] = useState();

    const targetLocaleRef = useRef(interpretationTargetLocale);
    targetLocaleRef.current = interpretationTargetLocale;

    const stopRef = useRef(() => {});

    useEffect(
        () => () => {
            stopRef.current();
            setWasLaunched(false);
        },
        [interpretationSourceLocale]
    );

    useEffect(() => {
        if (
            !video ||
            !mediaStream ||
            !interpretationSourceLocale ||
            !interpretationTargetLocale ||
            !isEnabled ||
            wasLaunched
        ) {
            return;
        }

        const [start, stop] = getRecognizerControls({
            mediaStream,
            sourceLocale: interpretationSourceLocale,
            onRecognized: updateText,
            onRecognizing: updateText,
        });
        start();
        setWasLaunched(true);
        stopRef.current = stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video, mediaStream, interpretationSourceLocale, interpretationTargetLocale, isEnabled, wasLaunched]);

    return setMediaStream;
};

// useTargetLocalResettingOnUnmount
const useTargetLocalResettingOnUnmount = () => {
    const [, {resetTargetInterpretationLocale}] = useInterpretation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => resetTargetInterpretationLocale, []);
};

// useTextUpdateCallback
const useTextUpdateCallback = (callback) => {
    const [{interpretationTargetLocale}] = useInterpretation();

    const targetLocaleRef = useRef(interpretationTargetLocale);
    targetLocaleRef.current = interpretationTargetLocale;

    return (translationCollection) => callback(translationCollection.get(targetLocaleRef.current));
};

// Main hook
export const useInterpretator = (video, sourceLocale, isEnabled) => {
    const [text, setText] = useState('');

    useSubtitlesDisplay({video, text, isEnabled});

    const updateText = useTextUpdateCallback(setText);

    const setMediaStream = useMicrosoftAzureRecognizer({
        video,
        isEnabled,
        updateText,
    });

    useTargetLocalResettingOnUnmount();

    return setMediaStream;
};
