import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    StopScreenShare as StopScreenShareIcon,
    ScreenShare as ScreenShareIcon,
    CallEnd as CallEndIcon,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon,
} from '@material-ui/icons';
import {MEDIA_TYPES} from '../../../../../../Utils/constants/organizer/sessions';

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        position: 'absolute',
        width: '100%',
        zIndex: 200,
        bottom: 5,
    },
    button: {
        color: '#fff',
        cursor: 'pointer',
        fontSize: '45px',
    },
    buttonEndCall: {
        color: 'red',
    },
});

const VideoPlayerButtons = ({
    soundEnabled,
    videoEnabled,
    toggleSound,
    toggleVideo,
    close,
    videoConference: {isScreenShared},
    toggleScreen,
    withCallEndIcon = true,
    setMediaSelfEnabled,
}) => {
    const classes = useStyles();

    const toggleMediaKind = (mediaKind) => {

        setMediaSelfEnabled(mediaKind);

        switch (mediaKind) {
            case MEDIA_TYPES.audio:
                return toggleSound();
            case MEDIA_TYPES.video:
                return toggleVideo();
            case MEDIA_TYPES.shareScreen:
                return toggleScreen();
            default:
                return;
        }
    };

    return (
        <div className={`${classes.buttons} video-conference-buttons`}>
            {soundEnabled && <MicIcon className={classes.button} onClick={() => toggleMediaKind(MEDIA_TYPES.audio)} />}
            {!soundEnabled && (
                <MicOffIcon className={classes.button} onClick={() => toggleMediaKind(MEDIA_TYPES.audio)} />
            )}
            {withCallEndIcon && (
                <CallEndIcon className={`${classes.button} ${classes.buttonEndCall}`} onClick={close} />
            )}

            {!isScreenShared && (
                <>
                    {videoEnabled && (
                        <VideocamIcon className={classes.button} onClick={() => toggleMediaKind(MEDIA_TYPES.video)} />
                    )}
                    {!videoEnabled && (
                        <VideocamOffIcon
                            className={classes.button}
                            onClick={() => toggleMediaKind(MEDIA_TYPES.video)}
                        />
                    )}
                </>
            )}

            {!isScreenShared && (
                <ScreenShareIcon className={classes.button} onClick={() => toggleMediaKind(MEDIA_TYPES.shareScreen)} />
            )}
            {isScreenShared && (
                <StopScreenShareIcon
                    className={classes.button}
                    onClick={() => toggleMediaKind(MEDIA_TYPES.shareScreen)}
                />
            )}
        </div>
    );
};

export default VideoPlayerButtons;
