import React from 'react';
export default class MatchingQuestionsCounter extends React.Component {
    lenLessEqualTen = (len, activeIndex) => {
        return [...Array(len).keys()].map((number, numberIndex) => (
            <p key={numberIndex} className={`counter-numbers ${activeIndex === number + 1 ? 'active' : ''}`}>
                {number + 1}
            </p>
        ));
    };
    lenMoreThanTen = (len, activeIndex) => {
        return [...Array(len).keys()].map((number) => {
            const questionNumber = number + 1;
            if (
                questionNumber !== 1 &&
                questionNumber !== activeIndex - 4 &&
                questionNumber !== activeIndex - 3 &&
                questionNumber !== activeIndex - 2 &&
                questionNumber !== activeIndex - 1 &&
                questionNumber !== activeIndex &&
                questionNumber !== activeIndex + 1 &&
                questionNumber !== activeIndex + 2 &&
                questionNumber !== activeIndex + 3 &&
                questionNumber !== activeIndex + 4 &&
                questionNumber !== len
            )
                return null;
            return (
                <p key={questionNumber} className={`counter-numbers ${questionNumber === activeIndex ? 'active' : ''}`}>
                    {this.renderCounter(questionNumber, len, activeIndex)}
                </p>
            );
        });
    };
    renderCounter = (number, len, activeIndex) => {
        if (number === 1) return number;
        if (number === activeIndex - 3) return number;
        if (number === activeIndex - 2) return number;
        if (number === activeIndex - 1) return number;
        if (number === activeIndex) return number;
        if (number === activeIndex + 1) return number;
        if (number === activeIndex + 2) return number;
        if (number === activeIndex + 3) return number;
        if (number === len) return number;
        return '...';
    };
    render() {
        const {len, activeIndex} = this.props;
        return (
            <div className="matching-counter-wrapper">
                {len <= 10 ? this.lenLessEqualTen(len, activeIndex) : this.lenMoreThanTen(len, activeIndex)}
            </div>
        );
    }
}
