import React from 'react';
import {Dialog, DialogContent} from '@material-ui/core';

import Spinner from '../../../../SmallLayoutComponents/Spinner';

const WaitForApprovePopup = ({currentUser}) => {
    const {kicked, status} = currentUser;

    return (
        <Dialog open={status === 'new' && !kicked}>
            <DialogContent
                style={{
                    height: '200px',
                }}
            >
                <p>Please wait for approval</p>
                <div
                    style={{
                        position: 'relative',
                        height: '100px',
                        margin: '20px 0 0',
                    }}
                >
                    <Spinner />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default WaitForApprovePopup;
