import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import {ReactComponent as Test} from '../../images/back.svg';
import {preventDefaultDrag} from 'Utils/utils';
import Email from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import StrongPassword from '../StrongPassword';
import RegistrationProgressbar from '../Progressbar/RegistrationProgressbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import UploadProfilePicture from '../UploadProfilePicture';
import {verifyFileType} from 'Utils/verifyFileType';
import {useHistory} from 'react-router-dom';
import {handleStrongPassword} from '../../Utils';
import classnames from 'classnames';
import RegisterEventEmailCodeValidation from '../../../Event/EmailCodeValidation/EmailCodeValidation';
import * as actions from 'store/actions';
import {isMobile, isMobileOnly} from 'react-device-detect';
const RegistrationForm = ({handleEmailValidation, showEmailValidationSection, handleVerificationEmail}) => {
    const dispatch = useDispatch();
    const formRef = useRef();
    const history = useHistory();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();

    const passwordCopyPasteAllowed = process.env.REACT_APP_PASSWORD_COPY_PASTE_ALLOWED;

    //redux
    const defaultTranslation = useSelector((state) => state.languages.translations['en']);
    const translation = useSelector((state) => state.languages.translations[state.languages.eventLanguage]);
    const languages = useSelector((state) => state.languages);
    const eventSlug = useSelector((state) => state.event.eventSlug);
    const currentLanguage = useSelector((state) => state.languages.eventLanguage);
    const langArabic = currentLanguage === 'ar';

    //states
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
    });
    const [passwords, setPasswords] = useState({
        password: '',
        passwordConfirmation: '',
    });
    const [userNameUserSurnameError, setUserNameUserSurnameError] = useState({
        firstName: false,
        lastName: false,
    });
    const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState({password: false, passwordConfirmation: false});
    const [displayAgreeToPrivacyPolicyMessage, setDisplayAgreeToPrivacyPolicyMessage] = useState(false);
    const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
    const [displayUserCreatedScreen, setDisplayUserCreatedScreen] = useState(false);

    const [showBottomStickyButton, setShowBottomStickyButton] = useState(true);

    const [strongPassword, setStrongPassword] = useState({
        isPasswordLengthBetween8and64: false,
        isPasswordContainsOneOrMoreLowercaseLetters: false,
        isPasswordContainsOneOrMoreUppercaseLetters: false,
        isPasswordContainsOneOrMoreNumbers: false,
        isPasswordContainsOneOrMoreSpecialCharacters: false,
    });

    const [photoData, setPhotoData] = useState({
        file: '',
        imagePreviewUrl: '',
        imgError: false,
        imgErrorText: '',
    });

    const [allValidations, setAllValidations] = useState({
        firstName: false,
        lastName: false,
        password: false,
        passwordConfirmation: false,
        photo: true,
        agreeToPrivacyPolicy: false,
    });

    const handleShowPasswordToggle = (el) => {
        return () => {
            setShowPassword({...showPassword, [el]: !showPassword[el]});
        };
    };
    //if user pushes to space, it won't affect the state
    const handleKeydown = (e) => {
        if (e.keyCode === 32) {
            e.preventDefault();
            return;
        }
    };

    //handle change name surname

    /*
     * Why its with useCallback?
     * The useCallback hook is used to memoize the callback function.
     * It will return the same function instance if the dependencies are not changed.
     * If the dependencies are changed, it will return a new function instance.
     * This is useful when passing callbacks to optimized child components
     * that rely on reference equality to prevent unnecessary renders.
     * */
    const handleChange = useCallback(
        (e) => {
            const {name, value} = e.target;

            setValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
            const isValidInput = /^[a-zA-ZæøåÆØÅ][a-zA-Z0-9æøåÆØÅ.' -]*$/.test(value);
            setAllValidations((prevData) => ({...prevData, [name]: value.length >= 2 && isValidInput}));
        },
        [setValues]
    );

    const handleNameErrors = (e) => {
        const {name, value} = e.target;
        const isValidInput = /^[a-zA-ZæøåÆØÅ][a-zA-Z0-9æøåÆØÅ.' -]*$/.test(value);
        setUserNameUserSurnameError((prevData) => ({...prevData, [name]: !isValidInput}));
    };
    const handleFocusNameSurname = (e) => {
        const {name} = e.target;
        setUserNameUserSurnameError((prevData) => ({...prevData, [name]: false}));
    };

    //END - handleChange and validation of email
    //end handle change name surname

    const createRegistrationCode = async () => {
        const userEmail = email.trim();
        const first = values.firstName.trim();
        const last = values.lastName.trim();
        const language = languages.eventLanguage;
        const formData = {
            email: userEmail,
            language: language,
            first: first,
            last: last,
        };
        dispatch(actions.createRegistrationCode(formData)).then((response) => {
            handleEmailValidation(true);
        });
        handleVerificationEmail(email);
    };

    const handleRegister = (validationCode = '') => {
        const formData = new FormData();

        formData.append('email', email);
        formData.append('first', values.firstName);
        formData.append('last', values.lastName);
        formData.append('password', passwords.password);
        formData.append('passwordConfirmation', passwords.passwordConfirmation);
        formData.append('code', validationCode);
        formData.append('consent', agreeToPrivacyPolicy);

        if (photoData.file) {
            formData.append('avatar', photoData.file, photoData.file.name, photoData.file.type);
        }

        dispatch(actions.registerUser(formData))
            .then((response) => {
                setDisplayUserCreatedScreen(true);
            })
            .catch((error) => {});
    };

    const handleSaveFirstStep = () => {
        const hasEmailValidation = JSON.parse(process.env.REACT_APP_ENABLE_EMAIL_VALIDATION);
        let isValid = true;

        isValid = isValid && agreeToPrivacyPolicy;

        if (isValid) {
            if (hasEmailValidation) {
                // only make the api call for creating a validation code if the user completed the register form correctly
                // api call to backend to create validation code
                createRegistrationCode();
            } else {
                handleRegister();
            }
        } else {
            if (!agreeToPrivacyPolicy) {
                setDisplayAgreeToPrivacyPolicyMessage(true);
            }
        }
        localStorage.removeItem('previousPath');
        localStorage.removeItem('tempEmail');
        localStorage.setItem('previousPath', window.location.pathname);
    };

    //terms and conditions handler
    const handleCheckbox = () => {
        setAgreeToPrivacyPolicy(!agreeToPrivacyPolicy);
        setAllValidations((prevData) => ({...prevData, agreeToPrivacyPolicy: !agreeToPrivacyPolicy}));
    };

    //photo handlers

    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        if (file !== undefined) {
            isValid = file?.size < 2 * 1024 * 1024 && isValid;
            if (!isValid) {
                setAllValidations((prevData) => ({...prevData, photo: false}));
                setPhotoData((prevData) => ({
                    ...prevData,
                    imgErrorText:
                        translation.newLoginRegistrations.fileSizeError ||
                        defaultTranslation.newLoginRegistrations.fileSizeError,
                }));
            }

            const typeValid = verifyFileType(file.type, 'image');
            isValid = typeValid && isValid;
            if (!typeValid) {
                setAllValidations((prevData) => ({...prevData, photo: false}));
                setPhotoData((prevData) => ({
                    ...prevData,
                    imgErrorText:
                        translation.newLoginRegistrations.fileTypeError ||
                        defaultTranslation.newLoginRegistrations.fileTypeError,
                }));
            }
            if (isValid) {
                reader.onloadend = () => {
                    if (isValid) {
                        setPhotoData({
                            file: file,
                            imagePreviewUrl: reader.result,
                            imgError: false,
                            imgErrorText: '',
                        });
                        setAllValidations((prevData) => ({...prevData, photo: true}));
                    } else {
                        setAllValidations((prevData) => ({...prevData, photo: false}));
                        setPhotoData({
                            file: '',
                            imagePreviewUrl: '',
                            imgError: true,
                        });
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleRemoveImage = () => {
        const fileInput = document.getElementById('file');
        fileInput.value = '';
        setPhotoData({
            file: '',
            imagePreviewUrl: '',
            imgError: false,
            imgErrorText: '',
        });
    };

    //end - photo handlers

    //password handler
    const handlePasswordChange = (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        setPasswords((prevPasswords) => ({
            ...prevPasswords,
            [name]: value,
        }));
    };

    //this is for password validation
    useEffect(() => {
        const isMatched = passwords.passwordConfirmation === passwords.password;
        const strongPassword = handleStrongPassword(passwords.password);
        const isStrongPassword = Object.values(strongPassword).every((item) => item === true);
        setStrongPassword(strongPassword);
        setAllValidations((prevValidations) => ({
            ...prevValidations,
            password: isStrongPassword && isMatched,
            passwordConfirmation: isMatched,
        }));
        if (passwords.password.length === 0) {
            setAllValidations((prevValidations) => ({
                ...prevValidations,
                password: false,
            }));
        }
        formRef.current?.isFormValid(true);
    }, [passwords, setAllValidations]);

    const handlePasswordMatchValidation = (value) => {
        if (value !== passwords.password || value !== passwords.passwordConfirmation) {
            if (passwords.passwordConfirmation.length > 0 && passwords.password.length > 0) {
                setAllValidations((prevData) => ({...prevData, password: false, passwordConfirmation: false}));
                return false;
            }
        }
        return true;
    };
    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', handlePasswordMatchValidation);

        return () => {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        };
    }, [passwords.passwordConfirmation, passwords.password]);

    //end password handler

    //email controls
    useEffect(() => {
        const tempEmail = localStorage.getItem('tempEmail');
        if (tempEmail) {
            setEmail(tempEmail);
        }
    }, []);

    //everytime when allValidations are changed, isRegistrationFormValid will produce a new value
    const isRegistrationFormValid = useMemo(() => {
        return Object.values(allValidations).every((item) => item === true);
    }, [allValidations]);

    useEffect(() => {
        const {password, passwordConfirmation} = passwords;
        const passwordElement = passwordRef.current;
        const passwordConfirmationElement = passwordConfirmationRef.current;

        const shouldRemoveErrorClass = password === passwordConfirmation;
        passwordElement?.classList?.toggle('remove-error', shouldRemoveErrorClass);
        passwordConfirmationElement?.classList?.toggle('remove-error', shouldRemoveErrorClass);
    }, [passwords]);

    useEffect(() => {
        let isKeyboardActive = false;

        const handleResize = () => {
            const {innerHeight, outerHeight} = window;

            if (outerHeight - innerHeight > 200) {
                // The difference between outerHeight and innerHeight indicates the presence of the virtual keyboard
                isKeyboardActive = true;
                setShowBottomStickyButton(false);
            } else if (isKeyboardActive) {
                isKeyboardActive = false;
                setShowBottomStickyButton(true);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (showEmailValidationSection) {
        return (
            <>
                <RegistrationProgressbar />

                <RegisterEventEmailCodeValidation
                    userEmail={email}
                    createRegistrationCode={createRegistrationCode}
                    handleRegister={handleRegister}
                    displayUserCreatedScreen={displayUserCreatedScreen}
                />
            </>
        );
    }
    //end email controls

    //check if the user come with pop or push method

    if (eventSlug === null && history.action === 'POP') {
        return <Redirect to={`/login`} />;
    }

    return (
        <>
            <RegistrationProgressbar />

            <ValidatorForm
                ref={formRef}
                className={classnames('login-form-fc gap-0', {'lang-ar': langArabic})}
                onSubmit={handleSaveFirstStep}
                onError={(errors) => console.log(errors)}
            >
                <div className={`form-100p`}>
                    <div className={`form-50p`}>
                        <div>
                            <TextValidator
                                label={
                                    translation.newLoginRegistrations.email ||
                                    defaultTranslation.newLoginRegistrations.email
                                }
                                className={`email-text-field field-container withEndAdornment disabled`}
                                name="email"
                                type="email"
                                disabled={true}
                                keyboardIcon={<Test classes={{root: 'dark-color'}} />}
                                value={email}
                                validators={['required', 'isEmail']}
                                errorMessages={[
                                    translation.errors.required || defaultTranslation.errors.required,
                                    translation.errors.emailNotValid || defaultTranslation.errors.emailNotValid,
                                ]}
                                InputProps={{
                                    endAdornment: (
                                        <div onDragStart={preventDefaultDrag} className={`show-pass email`}>
                                            <Email />
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                        <div ref={passwordRef}>
                            <TextValidator
                                label={
                                    translation.newLoginRegistrations.password ||
                                    defaultTranslation.newLoginRegistrations.password
                                }
                                onChange={handlePasswordChange}
                                onKeyDown={handleKeydown}
                                className="password-text-field withEndAdornment password-element"
                                name="password"
                                onCopy={!passwordCopyPasteAllowed && preventDefaultDrag}
                                type={`${showPassword.password ? 'text' : 'password'}`}
                                value={passwords.password}
                                validators={['required', 'isPasswordMatch']}
                                errorMessages={[
                                    'Password must comply with the requirements listed below',
                                    'Password is not matching',
                                ]}
                                InputProps={{
                                    endAdornment: (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="show-pass"
                                            onClick={handleShowPasswordToggle('password')}
                                        >
                                            {showPassword.password ? <Visibility /> : <VisibilityOff />}
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                        <div ref={passwordConfirmationRef}>
                            <TextValidator
                                label={
                                    translation.newLoginRegistrations.confirmPassword ||
                                    defaultTranslation.newLoginRegistrations.confirmPassword
                                }
                                onChange={handlePasswordChange}
                                onKeyDown={handleKeydown}
                                className="password-text-field withEndAdornment passwordConfirmation-element"
                                name="passwordConfirmation"
                                onCopy={!passwordCopyPasteAllowed && preventDefaultDrag}
                                type={`${showPassword.passwordConfirmation ? 'text' : 'password'}`}
                                value={passwords.passwordConfirmation}
                                validators={['isPasswordMatch', 'required']}
                                errorMessages={[
                                    'Password is not matching',
                                    'Password must comply with the requirements listed below',
                                ]}
                                InputProps={{
                                    endAdornment: (
                                        <div
                                            onDragStart={preventDefaultDrag}
                                            className="show-pass"
                                            onClick={handleShowPasswordToggle('passwordConfirmation')}
                                        >
                                            {showPassword.passwordConfirmation ? <Visibility /> : <VisibilityOff />}
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                        <StrongPassword strongPassword={strongPassword} />
                    </div>

                    <div className={`form-50p`}>
                        <UploadProfilePicture
                            photoData={photoData}
                            handleImageChange={handleImageChange}
                            handleRemoveImage={handleRemoveImage}
                        />

                        <div className={'single-name-field'}>
                            <TextValidator
                                label={
                                    translation.newLoginRegistrations.firstName ||
                                    defaultTranslation.newLoginRegistrations.firstName
                                }
                                className={`email-text-field field-container withEndAdornment ${
                                    userNameUserSurnameError.firstName ? 'error' : ''
                                }`}
                                onChange={handleChange}
                                onBlur={handleNameErrors}
                                onFocus={handleFocusNameSurname}
                                name="firstName"
                                type="name"
                                keyboardIcon={<Test classes={{root: 'dark-color'}} />}
                                value={values.firstName}
                                validators={['required', 'minStringLength:2']}
                                errorMessages={[
                                    translation.errors.required || defaultTranslation.errors.required,
                                    translation.newLoginRegistrations.firstNameError ||
                                        defaultTranslation.newLoginRegistrations.firstNameError,
                                ]}
                            />
                            {userNameUserSurnameError.firstName && (
                                <span onDragStart={preventDefaultDrag} className="error-text">
                                    {translation.newLoginRegistrations.invalidInput ||
                                        defaultTranslation.newLoginRegistrations.invalidInput}
                                </span>
                            )}
                        </div>
                        <div className={'single-name-field'}>
                            <TextValidator
                                label={
                                    translation.newLoginRegistrations.lastName ||
                                    defaultTranslation.newLoginRegistrations.lastName
                                }
                                className={`email-text-field field-container withEndAdornment ${
                                    userNameUserSurnameError.lastName ? 'error' : ''
                                }`}
                                onChange={handleChange}
                                onBlur={handleNameErrors}
                                onFocus={handleFocusNameSurname}
                                name="lastName"
                                type="name"
                                keyboardIcon={<Test classes={{root: 'dark-color'}} />}
                                value={values.lastName}
                                validators={['required', 'minStringLength:2']}
                                errorMessages={[
                                    translation.errors.required || defaultTranslation.errors.required,
                                    translation.newLoginRegistrations.lastNameError ||
                                        defaultTranslation.newLoginRegistrations.lastNameError,
                                ]}
                            />
                            {userNameUserSurnameError.lastName && (
                                <span onDragStart={preventDefaultDrag} className="error-text">
                                    {translation.newLoginRegistrations.invalidInput ||
                                        defaultTranslation.newLoginRegistrations.invalidInput}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className={classnames('form-100p bottom-actions-new-registration', {'mobile-helper': isMobileOnly})}
                >
                    <div className={`form-50p`}>
                        <div onDragStart={preventDefaultDrag} className="agree-terms">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={agreeToPrivacyPolicy}
                                        onChange={handleCheckbox}
                                        value="agreeToPrivacyPolicy"
                                        color="secondary"
                                    />
                                }
                                label={
                                    <span
                                        onDragStart={preventDefaultDrag}
                                        draggable="false"
                                        className="agree-terms-message"
                                    >
                                        {translation?.register.gdprTextFirst}
                                        <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname: '/terms-and-conditions/',
                                                search: `?lang=${languages.eventLanguage}`,
                                            }}
                                        >
                                            <span>{' ' + translation?.register.gdprTextTerms}</span>
                                        </Link>
                                        {' ' + translation?.register.gdprTextSecond}
                                        <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            to={{
                                                pathname: '/privacy-policy/',
                                                search: `?lang=${languages.eventLanguage}`,
                                            }}
                                        >
                                            <span>{' ' + translation?.register.gdprTextPrivacy}</span>
                                        </Link>
                                        .
                                    </span>
                                }
                            />
                            {displayAgreeToPrivacyPolicyMessage && (
                                <p onDragStart={preventDefaultDrag} className="error-text">
                                    {translation?.register.gdprTextAgree}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={`form-50p`}>
                        {isMobile ? (
                            showBottomStickyButton && (
                                <Button
                                    type="submit"
                                    disabled={!isRegistrationFormValid}
                                    className={classnames('send-form-fc', {
                                        disabled: !isRegistrationFormValid,
                                    })}
                                >
                                    {translation.newLoginRegistrations.continueButton ||
                                        defaultTranslation.newLoginRegistrations.continueButton}
                                </Button>
                            )
                        ) : (
                            <Button
                                type="submit"
                                disabled={!isRegistrationFormValid}
                                className={classnames('send-form-fc', {
                                    disabled: !isRegistrationFormValid,
                                })}
                            >
                                {translation.newLoginRegistrations.continueButton ||
                                    defaultTranslation.newLoginRegistrations.continueButton}
                            </Button>
                        )}
                    </div>
                </div>
            </ValidatorForm>
        </>
    );
};

export default RegistrationForm;
