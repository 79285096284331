import React from 'react';
import {preventDefaultDrag} from '../../../Utils/utils';

class SingleChatNoMessages extends React.PureComponent {
    render() {
        const {translation} = this.props;
        return (
            <div onDragStart={preventDefaultDrag} className="empty-chat-conversion">
                <div>
                    <p>
                        {translation?.chatsDropdown.noMessagesInChatTextFirst}
                        <br />
                        {translation?.chatsDropdown.noMessagesInChatTextSecond}
                    </p>
                </div>
            </div>
        );
    }
}

export default SingleChatNoMessages;
