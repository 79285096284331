import React from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';
import EventPublicComponent from './EventPublicComponent';

const EventPublicRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            return (
                <EventPublicComponent {...props}>
                    <Component {...props} />
                </EventPublicComponent>
            );
        }}
    />
);

const mapStateToProps = (state) => {
    return {
        eventSlug: state.event.eventSlug,
    };
};

export default connect(mapStateToProps)(EventPublicRoute);
