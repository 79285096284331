import {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {INVITATION_TYPES, PARTICIPANT_TYPES, PROFILE_STATUSES} from '../../../Utils/constants/organizer/sessions';
import {
    getProfileData,
    getSessionData,
    getSessionDataSuccess,
    autoLoginWithToken,
    getEvent,
    setActiveWall,
    setEventProtectedMenu,
    setEventSlug,
    setTimeSlotVideoWall,
    toggleSessionScreen,
    closeSessionConference,
    receiveChatMessage,
} from '../../../store/actions';

import {
    connectToSession,
    disconnectFromSession,
    sessionUserUnmuted,
    sessionUserApproved,
    sessionUserCreated,
    sessionUserKicked,
    sessionUserMuted,
    sessionUserSharingDisabled,
    sessionUserSharingEnabled,
    sessionUserVideoDisabled,
    sessionUserVideoEnabled,
    sessionChanged,
    connectToSessionChat,
    disconnectFromSessionChat,
    sessionMessageReceived,
    sessionUserPermissionUpdated
} from '../../../Api/socketApi';

import * as selectors from './selectors';

export const useSessionDashboard = () => {
    const history = useHistory();
    const routeParams = useParams();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const profile = useSelector((state) => selectors.getUserProfile(state));
    const userProfileStatus = useSelector((state) => selectors.getUserProfileStatus(state));
    const sessionData = useSelector((state) => selectors.getSessionData(state));
    const videoConference = useSelector((state) => selectors.getVideoConference(state));

    const [invitationType, setInvitationType] = useState('');

    const {eventSlug, sessionId, invitation} = routeParams;
    const isOrg = useSelector((state) => state.user.isOrganizer);
    const sessionToken = localStorage.sessionToken || localStorage.token;
    const {capture} = videoConference;

    const removeVideoTracks = useCallback(() => {
        capture.mediaStream.getVideoTracks().forEach((track) => {
            track.stop();
            capture.removeTrack(track);
        });
    }, [capture]);

    const getSessionAndProfile = useCallback(
        (data) => {
            dispatch(getSessionDataSuccess(data));
            if (profile.userId === data.userId) dispatch(getProfileData(sessionId));
        },
        [dispatch, profile.userId, sessionId]
    );

    const screenSharingUpdated = useCallback(() => {
        sessionUserSharingEnabled((data) => dispatch(getSessionDataSuccess(data)));
        sessionUserSharingDisabled((data) => dispatch(getSessionDataSuccess(data)));
    }, [dispatch]);

    const audioUpdated = useCallback(() => {
        sessionUserUnmuted((data) => dispatch(getSessionDataSuccess(data)));
        sessionUserMuted((data) => dispatch(getSessionDataSuccess(data)));
    }, [dispatch]);

    const videoUpdated = useCallback(() => {
        sessionUserVideoEnabled((data) => {
            dispatch(getSessionDataSuccess(data));
            if (capture && profile.userId === data.userId) dispatch(toggleSessionScreen(false));
        });
        sessionUserVideoDisabled((data) => {
            dispatch(getSessionDataSuccess(data));
            if (capture && profile.userId === data.userId) removeVideoTracks();
        });
    }, [capture, dispatch, profile.userId, removeVideoTracks]);

    const userStatusUpdated = useCallback(() => {
        sessionUserApproved((data) => {
            getSessionAndProfile(data);
        });
        sessionUserKicked((data) => {
            getSessionAndProfile(data);
        });
        sessionUserCreated((data) => {
            getSessionAndProfile(data);
        });
    }, [getSessionAndProfile]);

    const speakerPermissionsChanged = useCallback(() => {
        sessionUserPermissionUpdated((data) => dispatch(getSessionDataSuccess(data)));
    }, [dispatch]);

    const sessionStatusUpdated = useCallback(() => {
        sessionChanged((data) => dispatch(getSessionDataSuccess(data)));
    }, [dispatch]);

    const getNewmessages = useCallback(() => {
        sessionMessageReceived((message) => dispatch(receiveChatMessage(message)));
    }, [dispatch]);

    const checkIfInvitationIsValid = useCallback(() => {
        if (!invitation || (localStorage.token && isOrg)) {
            setInvitationType('');
            return history.push(`/event/${eventSlug}/session/${sessionId}`);
        }

        if (invitation && localStorage.sessionToken) {
            localStorage.removeItem('sessionToken');
        }

        switch (invitation) {
            case INVITATION_TYPES.speakerInvitation:
                setInvitationType(PARTICIPANT_TYPES.speaker);
                break;
            case INVITATION_TYPES.moderatorInvitation:
                setInvitationType(PARTICIPANT_TYPES.moderator);
                break;
            default:
                break;
        }
    }, [eventSlug, history, invitation, isOrg, sessionId]);

    const setWall = useCallback(
        (wallId) => {
            let highlightUsers = [];
            // we set up the current wall Id to be the video Wall Id
            const wallData = {
                wallId,
                highlightUsers,
            };
            dispatch(setTimeSlotVideoWall(wallData));
            dispatch(setActiveWall(wallData));
        },
        [dispatch]
    );

    const getInitialRequests = useCallback(
        (sessionData) => {
            dispatch(getEvent(sessionData.event));
            connectToSessionChat(sessionToken, sessionData.chat._id, profile.userId, sessionId);
            if (sessionData.videoWall) setWall(sessionData.videoWall);
        },
        [dispatch, profile.userId, sessionId, sessionToken, setWall]
    );

    useEffect(() => {
        if (sessionToken) {
            dispatch(getProfileData(sessionId));
            connectToSession(sessionId, sessionToken);
        }
        return () => {
            disconnectFromSession(sessionId, sessionToken);
            disconnectFromSessionChat(sessionToken, sessionData?.chat?._id, profile.userId, sessionId);
            dispatch(closeSessionConference());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, sessionToken]);

    useEffect(() => {
        const isApproved = userProfileStatus === PROFILE_STATUSES.approved;
        if (!isApproved) {
            return;
        } else {
            dispatch(
                getSessionData({
                    sessionId,
                    callback: (data) => getInitialRequests(data),
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, userProfileStatus]);

    useEffect(() => {
        screenSharingUpdated();
        audioUpdated();
        videoUpdated();
        userStatusUpdated();
        sessionStatusUpdated();
        getNewmessages();
        speakerPermissionsChanged()
    }, [audioUpdated, getNewmessages, screenSharingUpdated, sessionStatusUpdated, userStatusUpdated, videoUpdated, speakerPermissionsChanged]);

    useEffect(() => {
        if (!user.data) dispatch(autoLoginWithToken());
        dispatch(setEventSlug(eventSlug));
        dispatch(setEventProtectedMenu());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkIfInvitationIsValid();
    }, [checkIfInvitationIsValid]);

    return {invitationType};
};
