import React from 'react';
import cn from 'classnames';

import './IconContainer.scss';
import {preventDefaultDrag} from '../../Utils/utils';

const IconContainer = ({children, size = 'medium'}) => (
    <span
        onDragStart={preventDefaultDrag}
        draggable="false"
        className={cn('IconContainer', `IconContainer--size-${size}`)}
    >
        {children}
    </span>
);

export default IconContainer;
