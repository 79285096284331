import React, {useEffect} from 'react';
import {Videocam as VideocamIcon, VideocamOff as VideocamOffIcon} from '@material-ui/icons';

import axios from '../../../../../../store/axios-instance';

const VideoStreamButtonVideo = ({session, capture, user}) => {
    useEffect(() => {
        capture.mediaStream.getVideoTracks().forEach((track) => (track.enabled = user.video));
    }, [capture, user.video]);

    const toggleVideo = async (isVideo) => {
        try {
            const userToken = localStorage.sessionToken || localStorage.token;
            if (!userToken) return;

            const videoUrlParam = isVideo ? 'enable-video' : 'disable-video';

            await axios.post(`/sessions/${session._id}/${videoUrlParam}/${user.userId}`);
        } catch (err) {}
    };

    return (
        <>
            {user.video && <VideocamIcon className="button" onClick={() => toggleVideo(false)} />}
            {!user.video && <VideocamOffIcon className="button" onClick={() => toggleVideo(true)} />}
        </>
    );
};

export default VideoStreamButtonVideo;
