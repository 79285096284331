import React from 'react';
import {connect} from 'react-redux';
import {preventDefaultDrag} from '../../../Utils/utils';

class Poster extends React.Component {
    renderAuthors = (exhibitor) => {
        const {translation, defaultTranslation} = this.props;
        if (!exhibitor) {
            return null;
        }
        const authorsLength = exhibitor.booth.authors.length;
        if (authorsLength === 0) {
            return (
                <span>
                    {translation?.showfloor?.authorsNotAvailable || defaultTranslation?.showfloor.authorsNotAvailable}
                </span>
            );
        }
        return (
            <>
                {exhibitor.booth.authors.map((author, authorIndex) => {
                    return (
                        <span onDragStart={preventDefaultDrag} draggable="false" key={author._id}>
                            {author.name} {author?.affiliation ? `(${author?.affiliation})` : ''}
                            {authorIndex !== authorsLength - 1 ? ', ' : ''}
                        </span>
                    );
                })}
            </>
        );
    };

    render() {
        const {exhibitor, translation, defaultTranslation} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className="poster-details-mobile">
                <h1>
                    {exhibitor?.booth.studyTitle
                        ? exhibitor?.booth.studyTitle
                        : translation?.showfloor.titleNotAvailable || defaultTranslation?.showfloor.titleNotAvailable}
                </h1>
                <div onDragStart={preventDefaultDrag} className="section">
                    <p onDragStart={preventDefaultDrag} className="section-subtitle">
                        {translation?.showfloor.posterBooth?.highlights ||
                            defaultTranslation?.showfloor.posterBooth?.highlights}
                    </p>
                    <p onDragStart={preventDefaultDrag} className="poster-highlights">
                        {exhibitor?.booth.highlights
                            ? exhibitor?.booth.highlights
                            : translation?.showfloor.abstractNotAvailable ||
                              defaultTranslation?.showfloor.abstractNotAvailable}
                    </p>
                </div>
                <div onDragStart={preventDefaultDrag} className="section">
                    <p onDragStart={preventDefaultDrag} className="section-subtitle">
                        {translation?.showfloor.posterBooth?.authors ||
                            defaultTranslation?.showfloor.posterBooth?.authors}
                    </p>
                    <p onDragStart={preventDefaultDrag} className="poster-authors">
                        {this.renderAuthors(exhibitor)}
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

export default connect(mapStateToProps)(Poster);
