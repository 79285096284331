import React from 'react';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import axios from '../../store/axios-instance';
import ConfirmDelete from '../../Dialogs/ConfirmDelete';
import { preventDefaultDrag } from '../../Utils/utils';

class NotApprovedAccount extends React.Component {
	state = {
		openDeleteEventDialog: false,
	};

	componentDidMount() {
		this.props.onSetEventProtectedMenu();
	}

	handleOpenDeleteEventDialog = () =>
		this.setState({ openDeleteEventDialog: true });

	handleCloseDialog = () =>
		this.setState({
			openDeleteEventDialog: false,
		});

	handleDeleteEvent = () => {
		const { eventId } = this.props;
		axios({ method: 'delete', url: `/event/v2/${eventId}` })
			.then(() => {
				this.handleCloseDialog();
				this.props.refreshUserData().then(() => this.props.history.push(`/`));
			})
			.catch(() => {});
	};

	render() {
		const { coOrganizerNotAllowed } = this.props;
		return (
			<div
				onDragStart={preventDefaultDrag}
				className="organizer-dashboard-page"
			>
				<div onDragStart={preventDefaultDrag} className="smallContainer">
					<div
						onDragStart={preventDefaultDrag}
						className="dashboard-wrapper d-flex justify-content-center align-items-center account-not-approved-container"
					>
						{coOrganizerNotAllowed ? (
							<center>
								<p>
									Your access as Co-Organizer to the current route is not
									enabled.
									<br />
									Please choose any other available tabs.
								</p>
							</center>
						) : (
							<p>
								Your organizer dashboard is pending approval. You will receive
								an email notification once it gets approved.
							</p>
						)}
						{!coOrganizerNotAllowed && (
							<div
								onDragStart={preventDefaultDrag}
								className="button-container"
							>
								<Button
									onClick={this.handleOpenDeleteEventDialog}
									variant={'outlined'}
									disableElevation
									size={'large'}
								>
									DELETE EVENT
								</Button>
							</div>
						)}
					</div>
				</div>
				{this.state.openDeleteEventDialog && (
					<ConfirmDelete
						open={this.state.openDeleteEventDialog}
						closeConfirmDeleteDialog={this.handleCloseDialog}
						dialogTitle={'Delete Event'}
						dialogDescription={
							'Are you sure you want to delete this event? By deleting the event, all the data will be removed and cannot be restored'
						}
						dialogConfirmButtonLabel={'Delete'}
						handleConfirmDelete={this.handleDeleteEvent}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		eventId: state.event.eventId,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
		refreshUserData: () => dispatch(actions.autoLoginWithToken()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotApprovedAccount);
