import {
    GET_PUBLISHED_EVENT_REQUEST,
    GET_PUBLISHED_EVENT_SUCCESS,
    GET_PUBLISHED_EVENT_FAIL,
    GET_DATA_FOR_DOWNLOAD_EXCEL_FAIL,
    GET_DATA_FOR_DOWNLOAD_EXCEL_SUCCESS,
    GET_DATA_FOR_DOWNLOAD_EXCEL_REQUEST,
} from '../actions/actionTypes';

export const eventListReducer = (state = {publishedEvents: []}, action) => {
    switch (action.type) {
        case GET_PUBLISHED_EVENT_REQUEST:
            return {loading: true, publishedEvents: []};
        case GET_PUBLISHED_EVENT_SUCCESS:
            return {
                loading: false,
                publishedEvents: action.payload,
            };
        case GET_PUBLISHED_EVENT_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
};
export const downloadExcelReducer = (state = {downloadData: {}}, action) => {
    switch (action.type) {
        case GET_DATA_FOR_DOWNLOAD_EXCEL_REQUEST:
            return {loading: true, downloadData: {}};
        case GET_DATA_FOR_DOWNLOAD_EXCEL_SUCCESS:
            return {
                loading: false,
                downloadData: action.payload,
                success: true,
            };
        case GET_DATA_FOR_DOWNLOAD_EXCEL_FAIL:
            return {loading: false, error: action.payload};
        default:
            return state;
    }
};
