import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';
import {languagesArray} from '../../Utils/Translations/availableLanguages';

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    organizers: [],
    brandingTranslation: '',
    availableLanguages: languagesArray,
    brandingLanguage: 'en',
    allEvents: [],
    downloadData: [],
    eventsToday: [],
    audiences: [],
    campaigns: [],
    users: [],
    deleteUserState: 'idle',
    getUserDetailedState: 'idle',
    detailedUser: {},
};

const reducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actionTypes.SET_ADMIN_BRANDING_LANGUAGE:
                draft.brandingLanguage = action.payload;
                break;

            case actionTypes.GET_ORGANIZERS_START:
            case actionTypes.APPROVE_ORGANIZER_START:
            case actionTypes.DISABLE_ORGANIZER_START:
            case actionTypes.GET_BRANDING_TRANSLATION_START:
            case actionTypes.UPDATE_BRANDING_TRANSLATION_START:
            case actionTypes.GET_ADMIN_ALL_EVENTS_START:
            case actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_START:
            case actionTypes.GET_EVENTS_TODAY_START:
            case actionTypes.GET_ADMIN_CAMPAIGNS_START:
            case actionTypes.GET_ADMIN_AUDIENCES_START:
            case actionTypes.DELETE_ADMIN_CAMPAIGN_START:
            case actionTypes.UPDATE_ADMIN_CAMPAIGN_START:
            case actionTypes.ADD_ADMIN_CAMPAIGN_START:
                draft.loading = true;
                break;

            case actionTypes.GET_ORGANIZERS_SUCCESS:
                draft.loading = false;
                draft.organizers = action.payload;
                break;

            case actionTypes.GET_ORGANIZERS_FAIL:
            case actionTypes.APPROVE_ORGANIZER_FAIL:
            case actionTypes.DISABLE_ORGANIZER_FAIL:
            case actionTypes.GET_BRANDING_TRANSLATION_FAIL:
            case actionTypes.UPDATE_BRANDING_TRANSLATION_FAIL:
            case actionTypes.GET_ADMIN_ALL_EVENTS_FAIL:
            case actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_FAIL:
            case actionTypes.GET_EVENTS_TODAY_FAIL:
            case actionTypes.GET_ADMIN_CAMPAIGNS_FAIL:
            case actionTypes.GET_ADMIN_AUDIENCES_FAIL:
            case actionTypes.DELETE_ADMIN_CAMPAIGN_FAIL:
            case actionTypes.UPDATE_ADMIN_CAMPAIGN_FAIL:
            case actionTypes.ADD_ADMIN_CAMPAIGN_FAIL:
                draft.loading = false;
                draft.error = true;
                draft.errorMessage = action.payload;
                break;

            case actionTypes.APPROVE_ORGANIZER_SUCCESS:
            case actionTypes.DISABLE_ORGANIZER_SUCCESS:
            case actionTypes.UPDATE_BRANDING_TRANSLATION_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                break;

            case actionTypes.GET_BRANDING_TRANSLATION_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.brandingTranslation = action.payload;
                break;

            case actionTypes.GET_ADMIN_ALL_EVENTS_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.allEvents = action.payload;
                break;

            case actionTypes.SET_ADMIN_ALL_EVENTS_EXCEL_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.downloadData = action.payload;
                break;

            case actionTypes.GET_EVENTS_TODAY_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.eventsToday = action.payload;
                break;

            case actionTypes.GET_ADMIN_CAMPAIGNS_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.campaigns = action.payload;
                break;

            case actionTypes.GET_ADMIN_AUDIENCES_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.audiences = action.payload;
                break;

            case actionTypes.ADD_ADMIN_CAMPAIGN_SUCCESS:
                draft.loading = false;
                draft.campaigns.push(action.payload);
                break;

            case actionTypes.DELETE_ADMIN_CAMPAIGN_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.errorMessage = '';
                draft.campaigns = draft.campaigns.filter((campaign) => campaign._id !== action.payload);
                break;

            case actionTypes.UPDATE_ADMIN_CAMPAIGN_SUCCESS:
                draft.loading = false;
                let updatedCampaignIndex = draft.campaigns.findIndex((campaign) => campaign._id === action.payload._id);
                if (updatedCampaignIndex !== -1) {
                    draft.campaigns[updatedCampaignIndex] = action.payload;
                }
                break;

            case actionTypes.ADD_ADMIN_CAMPAIGN_TO_QUEUE_START:
                let updatedQueuedCampaignIndex = draft.campaigns.findIndex(
                    (campaign) => campaign._id === action.payload
                );
                if (updatedQueuedCampaignIndex !== -1) {
                    draft.campaigns[updatedQueuedCampaignIndex].alreadySent = false;
                    draft.campaigns[updatedQueuedCampaignIndex].sentOn = new Date().toISOString();
                }
                break;
            case actionTypes.GET_USERS_BY_NAME_SUCCESS: {
                draft.users = action.payload.data.users;
                break;
            }
            case actionTypes.DELETE_USER_V2_IDLE: {
                draft.deleteUserState = 'idle';
                break;
            }
            case actionTypes.DELETE_USER_V2_START: {
                draft.deleteUserState = 'pending';
                break;
            }
            case actionTypes.DELETE_USER_V2_FAIL: {
                draft.deleteUserState = 'error';
                break;
            }
            case actionTypes.DELETE_USER_V2_SUCCESS: {
                draft.deleteUserState = 'success';
                break;
            }
            case actionTypes.GET_USER_DETAILED_IDLE: {
                draft.getUserDetailedState = 'idle';
                break;
            }
            case actionTypes.GET_USER_DETAILED_START: {
                draft.getUserDetailedState = 'pending';
                draft.detailedUser = {};
                break;
            }
            case actionTypes.GET_USER_DETAILED_FAIL: {
                draft.getUserDetailedState = 'error';
                break;
            }
            case actionTypes.GET_USER_DETAILED_SUCCESS: {
                draft.getUserDetailedState = 'success';
                draft.detailedUser = action.payload;
                break;
            }
            default:
                break;
        }
    });

export default reducer;
