import React from 'react';
import {connect} from 'react-redux';
import axios from '../../../store/axios-instance';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {checkBoothOwnerOrRepresentative, preventDefaultDrag} from '../../../Utils/utils';
import moment from "moment";

class Files extends React.Component {
    // sendDocumentClickStatistic = () => {
    //     if (this.props.eventRoles.isParticipant) {
    //         const dataToSend = {participantId: this.props.eventRoles.participantId, actionType: 'documentClicks'};
    //         axios({
    //             method: 'post',
    //             url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
    //             data: dataToSend,
    //         })
    //             .then((response) => {})
    //             .catch((error) => {});
    //     }
    // };

    handleClickOnDocument = (documentId, documentName) => (e) => {
        const actionType = 'boothDocuments';
        const isRepresentative = checkBoothOwnerOrRepresentative(this.props.exhibitor, this.props.user._id);
        let trackBoothStatistics = !isRepresentative;

        const {event, user} = this.props;
        const {owner} = event;
        if (owner._id === user._id) {
            trackBoothStatistics = false;
            let findEventRoles = user.eventRoles.find((el) => el?.event?._id === event?._id);
            if (findEventRoles) {
                if (findEventRoles?.roles?.includes('participant')) {
                    trackBoothStatistics = true;
                }
            }
        }

        if (documentId && documentName && trackBoothStatistics) {
            const data = {
                userId: this.props.user._id,
                documentId: documentId,
                documentName: documentName,
                actionType: actionType,
                timestamp: moment.utc(),
            };
            axios({
                method: 'post',
                url: '/exhibitor/' + this.props.exhibitor._id + '/booth-statistics',
                data: data,
            })
                .then((res) => {})
                .catch((err) => {});
        }
    };

    render() {
        const {exhibitor} = this.props;

        return (
            <ul className="exhibitor-assets">
                {exhibitor.booth.documents.map((document) => {
                    return (
                        <li key={document._id} onClick={this.handleClickOnDocument(document._id, document.displayName)}>
                            <a href={exhibitor.filesUrl + document.file} target="_blank" rel="noopener noreferrer">
                                <span onDragStart={preventDefaultDrag} draggable="false" className="label">
                                    {document.displayName}
                                </span>
                                <CloudDownloadIcon />
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        exhibitor: state.exhibitor.data,
        user: state.user.data,
    };
};

export default connect(mapStateToProps)(Files);
