import React, {Component} from 'react';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import '../../CSS/liveSession.scss'
import {withRouter} from 'react-router-dom';
import Wall from "../../HOC/Wall/Wall";
import SessionWall from "../../HOC/SessionWall/SessionWall";
import AuditoriumNetworking from "../Auditorium/AuditoriumNetworking";

class LiveSessionSidebar extends Component {

    state = {
        currentTab: 'wall',
    }

    handleChangeTab = (tab) => e => {
        this.setState({
            currentTab: tab
        })
    }

    render() {
        const {currentTab} = this.state;
        const {userIsLoggedIn, liveSession, translation, defaultTranslation} = this.props;

        let liveSessionGuestUsers = liveSession?.users.filter(sessionUser =>  sessionUser.isGuest && sessionUser.status === "new");

        return (
            <div className="booth-wall-container">
                <div className="booth-wall-header">
                    <div
                        onClick={this.handleChangeTab('wall')}
                        className={`tab wall-tab ${currentTab === 'wall' ? 'active' : ''}`}
                    >
                        <span>{translation?.sideMenu.videoWall || defaultTranslation?.sideMenu.videoWall}</span>
                    </div>
                    {userIsLoggedIn && liveSession?.programId && liveSession?.auditoriumId &&
                        <div
                            onClick={this.handleChangeTab('networking')}
                            className={`tab networking-tab ${
                                currentTab === 'networking' ? 'active' : ''
                            }`}
                        >
                            <span>
                                {translation?.sideMenu.attendees || defaultTranslation?.sideMenu.attendees}
                                {liveSessionGuestUsers?.length > 0 && (
                                    <span className="users-number">{liveSessionGuestUsers?.length}</span>
                                )}
                            </span>
                        </div>
                    }
                </div>
                {currentTab === 'wall' &&
                    <>
                        {this.props.userIsLoggedIn
                            ?<Wall isVideoWall />
                            :<SessionWall />
                        }

                    </>

                }
                {currentTab === 'networking' && (
                    <AuditoriumNetworking
                        timeSlotId={liveSession?.programId}
                        auditoriumId={liveSession?.auditoriumId}
                        showInviteToStageButton={liveSession?.isActive}
                        liveSessionGuestUsers={liveSessionGuestUsers}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liveSession: state.liveSession.liveSession,
        liveSessionUser: state.liveSession.liveSessionUser,
        liveSessionActiveUsers: state.liveSession.liveSessionActiveUsers,
        userData: state.user.data,
        eventRoles: state.user.eventRoles,
        userIsLoggedIn: state.user.loggedIn,
        eventData: state.event.data,
        translation: state.languages.translations[state.languages.platformLanguage],
        defaultTranslation: state.languages.translations['en'],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveSession: (sessionId) => dispatch(actions.getLiveSession(sessionId)),
        getLiveSessionSuccess: (session) => dispatch(actions.getLiveSessionSuccess(session)),
        getLiveSessionUser: (sessionId) => dispatch(actions.getLiveSessionUser(sessionId)),
        setActiveWall: (wallData) => dispatch(actions.setActiveWall(wallData)),
        startLiveSessionStream: (sessionId) => dispatch(actions.startLiveSessionStream(sessionId)),
        stopLiveSessionStream: (sessionId) => dispatch(actions.stopLiveSessionStream(sessionId)),
        setLiveSessionIsActive: (isActive) => dispatch(actions.setLiveSessionIsActive(isActive)),
        setLiveSessionRecords: (records) => dispatch(actions.setLiveSessionRecords(records)),
        setSessionGuestMenu: () => dispatch(actions.setSessionGuestMenu()),
        setEventProtectedMenu: () => dispatch(actions.setEventProtectedMenu()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveSessionSidebar));