import axios from '../axios-instance';
import {
    GET_PUBLISHED_EVENT_REQUEST,
    GET_PUBLISHED_EVENT_SUCCESS,
    GET_PUBLISHED_EVENT_FAIL,
    GET_DATA_FOR_DOWNLOAD_EXCEL_FAIL,
    GET_DATA_FOR_DOWNLOAD_EXCEL_SUCCESS,
    GET_DATA_FOR_DOWNLOAD_EXCEL_REQUEST,
} from './actionTypes';

export const listEvents = (page, perPage, sortBy, sortOrder, search, eventFilter, organizerStatus) => async (
    dispatch
) => {
    try {
        dispatch({type: GET_PUBLISHED_EVENT_REQUEST});
        const {data} = await axios.get(
            `/master-admin/published-events${page ? `?page=${page}` : ''}${perPage ? `&perPage=${perPage}` : ''}${
                sortBy ? `&sortBy=${sortBy}` : ''
            }${sortOrder ? `&sortOrder=${sortOrder}` : ''}${search ? `&search=${search}` : ''}${
                eventFilter ? `&eventFilter=${eventFilter}` : ''
            }${organizerStatus ? `&organizerStatus=${organizerStatus}` : ''}`
        );
        dispatch({
            type: GET_PUBLISHED_EVENT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_PUBLISHED_EVENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const getDataForDownloadExcel = (sortBy, sortOrder, eventFilter, search, organizerStatus) => async (
    dispatch
) => {
    try {
        dispatch({type: GET_DATA_FOR_DOWNLOAD_EXCEL_REQUEST});
        const {data} = await axios.get(
            `/master-admin/published-events-excel${sortBy ? `?sortBy=${sortBy}` : ''}${
                sortOrder ? `&sortOrder=${sortOrder}` : ''
            }${eventFilter ? `&eventFilter=${eventFilter}` : ''}${search ? `&search=${search}` : ''}${
                organizerStatus ? `&organizerStatus=${organizerStatus}` : ''
            }`,
            {responseType: 'arraybuffer'}
        );
        const newData = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
        dispatch({
            type: GET_DATA_FOR_DOWNLOAD_EXCEL_SUCCESS,
            payload: newData,
        });
    } catch (error) {
        dispatch({
            type: GET_DATA_FOR_DOWNLOAD_EXCEL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};
