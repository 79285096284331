import React, {PureComponent} from 'react';
import InfoIcon from '@material-ui/icons/Info';
import colors from '../../../CSS/_variables.module.scss';
import {preventDefaultDrag} from '../../../Utils/utils';

class VideoMeetingBanner extends PureComponent {
    render() {
        const {videoBannerShow, defaultTranslation, translation, closeBanner} = this.props;

        return (
            <div onDragStart={preventDefaultDrag} className={`video-started-wrapper ${videoBannerShow ? 'show' : ''}`}>
                <div>
                    <div onDragStart={preventDefaultDrag} className="info-video-text">
                        <InfoIcon htmlColor={colors.white} />
                        <p>
                            {translation?.meetings.banner.videoCallStartedInfo ||
                                defaultTranslation?.meetings.banner.videoCallStartedInfo}
                        </p>
                    </div>
                    <div onDragStart={preventDefaultDrag} className="close-info" onClick={closeBanner}>
                        <p>{translation?.generalText.close || defaultTranslation?.generalText.close}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoMeetingBanner;
