export const createAction = (type, payload) => ({type, payload});

export const bindActionsToDispatch = (actions, dispatch) => {
    const bindedActions = {};

    for (let key in actions) {
        const _action = actions[key];
        bindedActions[key] = (...args) => dispatch(_action(...args));
    }

    return bindedActions;
};
