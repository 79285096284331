import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Utils} from 'avcore/client';
import useForceUpdate from 'use-force-update';
import * as actions from '../../../../store/actions/index';
import VideoChatPlayerVideoPlates from './VideoChatPlayerVideoPlates';
import VideoChatPlayerButtons from './VideoChatPlayerButtons';
import VideoChatPlayerSelfVideo from './VideoChatPlayerSelfVideo';
import {preventDefaultDrag} from '../../../../Utils/utils';
import {disconnectFromPrivateChatStream, disconnectFromGroupChatStream} from '../../../../Api/socketApi';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        background: '#000',
        height: '100%',
    },
});

const VideoChatPlayer = ({videoConference, closeVideoConference, toggleScreen, members, userId, chatId}) => {
    const {capture, playbacks, selfPlayback} = videoConference;
    const classes = useStyles();
    const forceUpdate = useForceUpdate();

    const [soundEnabled, setSoundEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);

    const vidRefs = useRef([]);
    const selfVidRef = useRef(null);

    const toggleSound = () => {
        capture?.mediaStream.getAudioTracks().forEach((track) => (track.enabled = !soundEnabled));
        setSoundEnabled(!soundEnabled);
    };

    const removeVideoTracks = () => {
        capture?.mediaStream.getVideoTracks().forEach((track) => {
            track.stop();
            capture.removeTrack(track);
        });
    };

    const toggleVideo = () => {
        if (videoEnabled) removeVideoTracks();
        else toggleScreen(videoConference, false);

        setVideoEnabled(!videoEnabled);
    };

    const close = () => {
        closeVideoConference(videoConference);
        disconnectFromPrivateChatStream(userId, chatId);
        disconnectFromGroupChatStream(userId, chatId);
    };

    const startVideo = async (videoRef, playback) => {
        try {
            const mediaStream = await playback.subscribe();
            videoRef.current.srcObject = mediaStream;
            if (Utils.isSafari) {
                const onStreamChange = () => {
                    try {
                        if (!videoRef || !videoRef.current) return;
                        const _mediaStream = new MediaStream(mediaStream.getTracks());
                        videoRef.current.srcObject = _mediaStream;
                        videoRef.current.play();
                        forceUpdate();
                    } catch (err) {}
                };
                playback.on('addtrack', onStreamChange).on('removetrack', onStreamChange);
            } else if (Utils.isFirefox) {
                const onPause = () => {
                    if (videoRef && videoRef.current) videoRef.current.play();
                };
                videoRef.current.addEventListener('pause', onPause);
            }
            await videoRef.current.play();
        } catch (err) {}
    };

    useEffect(() => {
        if (!capture) return;
        if (!soundEnabled) capture.mediaStream.getAudioTracks().forEach((track) => (track.enabled = false));
    }, [capture]); // eslint-disable-line react-hooks/exhaustive-deps

    const activeStreamsCount = playbacks.reduce(
        (count, pb) => (pb.mediaStream && pb.mediaStream.active ? ++count : count),
        0
    );
    return (
        <div onDragStart={preventDefaultDrag} className={classes.root}>
            <VideoChatPlayerSelfVideo
                startVideo={startVideo}
                activeStreamsCount={activeStreamsCount}
                selfVidRef={selfVidRef}
                selfPlayback={selfPlayback}
            />
            <VideoChatPlayerVideoPlates
                members={members}
                playbacks={playbacks}
                vidRefs={vidRefs}
                activeStreamsCount={activeStreamsCount}
                startVideo={startVideo}
                forceUpdate={forceUpdate}
            />
            <VideoChatPlayerButtons
                soundEnabled={soundEnabled}
                videoEnabled={videoEnabled}
                toggleSound={toggleSound}
                toggleVideo={toggleVideo}
                removeVideoTracks={removeVideoTracks}
                close={close}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.data,
    activeChatRoomId: state.user.topNavigation.activeChatRoomId,
    videoConference: state.videoConference,
});

const mapDispatchToProps = (dispatch) => ({
    closeVideoConference: (conference) => dispatch(actions.closeVideoConference(conference)),
    toggleScreen: (videoConference, isShare) => dispatch(actions.toggleScreen(videoConference, isShare)),
    openVideoConference: (conference, user, members, chatId) =>
        dispatch(actions.openVideoConference(conference, user, members, chatId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoChatPlayer);
